/* eslint-disable react/no-array-index-key */
import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {theme} from 'src/themes'

import {P} from 'src/components/typography'
import {useProductTabContext} from './ProductTabContext'

const Wrapper = styled.div``

const Tab = styled.button`
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  padding: 8px;
  color: ${palette('black')};
  background: transparent;
  border: 0;
  border-radius: 0;
  transition: color 0.15s;
  cursor: pointer;
  @media (max-width: ${theme.screenSize.sizeSM}) {
    padding: 8px 4px;
    margin-right: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    display: block;
    width: 100%;
    height: 5px;
    opacity: 0;
    transition: opacity 0.2s;
    background-color: ${palette('primary')};
  }

  &.isActive {
    font-weight: bold;

    &:after {
      opacity: 1;
    }
  }

  &:hover {
    &:after {
      opacity: 1 !important;
    }
  }

  @media (min-width: ${theme.screenSize.sizeSM}) {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
`

const TabsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid ${palette('black')};

  &:hover {
    & ${Tab}.isActive:after {
      opacity: 0;
    }
  }
`

const TabContent = styled(P)`
  display: none;
  color: ${palette('black')};

  &.isActive {
    display: block;
    heigth: 'fit-content';
  }
`

export default function ProductTabs(): JSX.Element {
  const {activeTab, setActiveTab, tabs} = useProductTabContext()
  return (
    <Wrapper>
      <TabsWrapper>
        {tabs
          .filter((value) => value.visible)
          .map(({label}) => (
            <Tab
              key={label}
              className={cn({isActive: activeTab === label})}
              onClick={() => setActiveTab(label)}
            >
              {label}
            </Tab>
          ))}
      </TabsWrapper>
      {tabs.map(({label, Component}) => (
        <TabContent key={label} className={cn({isActive: activeTab === label})}>
          <Component />
        </TabContent>
      ))}
    </Wrapper>
  )
}
