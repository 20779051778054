import type {RefObject} from 'react'
import {useEffect} from 'react'

export const useTrap = ({
  enabled,
  ref,
}: {
  enabled: boolean
  ref: RefObject<HTMLElement>
}): void => {
  useEffect(() => {
    if (!enabled) {
      return () => {
        // Empty on purpose
      }
    }
    const el = ref.current
    if (!el) {
      return () => {
        // Empty on purpose
      }
    }
    const getIsInstanceOfEl = (node: HTMLElement): boolean => {
      if (node === document.body) {
        return false
      }
      if (node === el) {
        return true
      }
      return getIsInstanceOfEl(node.parentElement as HTMLElement)
    }

    const fn = (event: FocusEvent): void => {
      const isFocusable = getIsInstanceOfEl(event.target as HTMLElement)
      if (!isFocusable) {
        const firstFocused = el.querySelector(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        ) as HTMLElement | null
        if (!firstFocused) {
          // Nothing to focus on. This should never happens since open dialog always has a button.
          ;(event.target as HTMLDivElement).blur()
          throw new Error('No element to focus on. This should never happen')
        }
        firstFocused.focus()
      }
    }
    document.body.addEventListener('focus', fn, true)
    return () => {
      document.body.removeEventListener('focus', fn, true)
    }
  }, [enabled, ref])
}
