import {useEffect, useMemo, useState} from 'react'
import type {RvProduct} from 'src/api/local'
import type {ProductCartPickerContextProps} from '../context'
import {ProductTypePiece} from '../interfaces'
import {useQtyInCart} from './useQtyInCart'

interface UseCartPickerValueProps {
  product: RvProduct
}
export const useCartPickerValue = ({
  product,
}: UseCartPickerValueProps): ProductCartPickerContextProps => {
  const qtyInCart = useQtyInCart({product})
  const [type, setType] = useState<string>(
    product.defaultUnitType?.name ?? ProductTypePiece,
  )
  const [currentQuantity, setCurrentQuantity] = useState(1)

  useEffect(() => {
    const typeVal = product.defaultUnitType?.name ?? ProductTypePiece
    setType(typeVal)

    const currentItem = product.unitTypes.find(
      (unitType) => unitType.name === typeVal,
    )
    if (!currentItem) {
      throw new Error('Invalid type')
    }
    setCurrentQuantity(1)
  }, [product.defaultUnitType, product.unitTypes])

  return useMemo(
    () => ({
      currentQuantity,
      setCurrentQuantity,
      type,
      setType,
      qtyInCart,
      product,
    }),
    [currentQuantity, product, qtyInCart, type],
  )
}
