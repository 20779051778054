import Image from 'next/image'
import {ProductMediaYoutubeImage} from './ProductMediaYoutubeImage'
import {MiniSlide, Wrapper} from './styles'
import type {RVMedia} from './interfaces'
import {typeIsVideo} from './interfaces'

export const ProductMediaMiniSlide = ({
  value,
  isSelected,
}: {
  value: RVMedia
  isSelected: boolean
}): JSX.Element => {
  if (typeIsVideo(value)) {
    return <ProductMediaYoutubeImage value={value} isSelected={isSelected} />
  }
  const {url} = value
  return (
    <Wrapper>
      <MiniSlide selected={isSelected}>
        <Image
          alt=""
          src={url}
          layout="fill"
          objectFit="contain"
          objectPosition="center"
        />
      </MiniSlide>
    </Wrapper>
  )
}
