import type {ShoppingListItem} from 'src/api/rest'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useRouter} from 'next/router'
import {Col, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'
import PencilLineIcon from 'remixicon-react/PencilLineIcon'

import {useShoppingList} from 'src/context/ShoppingListContext'

import {paths} from 'src/utils/paths'
import {theme} from 'src/themes'
import dynamic from 'next/dynamic'
import {SpacingWrapper, BlockTitle} from '../layout'
import {Breadcrumbs} from '../products'
import {H5, PSmall} from '../typography'
import {AnchorButton, UnderlineButton} from '../buttons'

import {Modal} from '../common'
import {CreateNewShoppingList} from '.'

const SubTitleWrapper = styled.div`
  margin-top: -10px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
`

const EmptyListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${palette('black60')};
  text-align: center;
  margin: 50px 24px;
`

/** Only load this on client. */
const ProductCard = dynamic(
  async () => (await import('../products/ProductCard/ProductCard')).ProductCard,
  {
    ssr: false,
  },
)

const GetItem = ({
  product,
  shoppingListProductId,
}: {
  product: ShoppingListItem
  shoppingListProductId: string
}): JSX.Element | null => {
  if (!product.id) {
    return null
  }
  return (
    <ProductCard
      key={product.id}
      render={(children) => (
        <Col key={product.id} sm={6} md={4} lg={3}>
          {children}
        </Col>
      )}
      slug={product.productVariant.product.slug}
      shoppingList
      shoppingListProductId={shoppingListProductId}
    />
  )
}

export default function ShoppingListItems(): JSX.Element {
  const router = useRouter()
  const id = router.query.listId as string
  const {
    shoppingLists,
    useSingleShoppingList,
    setCreateModalIsOpen,
    createModalIsOpen,
  } = useShoppingList()
  const {
    singleShoppingList,
    revalidateSingleShoppingList,
  } = useSingleShoppingList(id)

  const isCurrCustomerList = shoppingLists?.some((list) => list.id === id)

  useEffect(() => {
    if (shoppingLists && !isCurrCustomerList) {
      router.push(paths.shoppingList)
    }
  }, [isCurrCustomerList, shoppingLists, router])

  const handleShoppingLitstItems = useMemo(
    () => (
      <Row>
        {singleShoppingList?.items.map((product) => (
          <GetItem product={product} shoppingListProductId={product.id} />
        ))}
      </Row>
    ),
    [singleShoppingList],
  )

  const handleClick = useCallback(() => {
    if (!setCreateModalIsOpen) {
      return
    }
    setCreateModalIsOpen(true)
  }, [setCreateModalIsOpen])

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {title: 'Forsíða', href: paths.home},
          {title: 'Óskalistar', href: paths.shoppingList},
          {title: singleShoppingList?.name, href: router.asPath},
        ]}
      />
      <SpacingWrapper spacing={32}>
        <BlockTitle
          title={
            !singleShoppingList
              ? 'Óskalisti'
              : `Óskalisti - ${singleShoppingList?.name}`
          }
        />
        {!!singleShoppingList && (
          <SubTitleWrapper>
            <PSmall>
              {singleShoppingList?.items?.length}{' '}
              {singleShoppingList?.items?.length === 1 ? 'vara' : 'vörur'}
            </PSmall>
            <UnderlineButton
              icon={<PencilLineIcon color={theme.palette.primary} size={16} />}
              text="Breyta óskalista"
              onClick={handleClick}
            />
          </SubTitleWrapper>
        )}
        {singleShoppingList?.items?.length ? (
          <div>{handleShoppingLitstItems}</div>
        ) : (
          <EmptyListWrapper>
            <H5 withGutter>Þessi óskalisti er tómur</H5>
            <PSmall withGutter>
              Bættu úr því með því að skoða vörur í vefversluninni okkar!
            </PSmall>
            <AnchorButton
              icon={<ArrowRightLineIcon color={theme.palette.primary} />}
              href={paths.categories}
              withGutter
            >
              Fara í vefverslun
            </AnchorButton>
          </EmptyListWrapper>
        )}
      </SpacingWrapper>
      <Modal setShow={setCreateModalIsOpen} show={!!createModalIsOpen}>
        <CreateNewShoppingList
          revalidateSingleShoppingList={revalidateSingleShoppingList}
          list={singleShoppingList}
        />
      </Modal>
    </>
  )
}
