import React, {useCallback, useMemo, useRef} from 'react'
import styled from 'styled-components'
import {Row, Col, media} from 'styled-bootstrap-grid'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import {theme} from 'src/themes'
import {ifProp, palette} from 'styled-tools'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import Image from 'next/image'
import {CreateNewShoppingList} from 'src/components/shoppingLists'
import {intFromGlobalId} from 'src/utils/graphql'
import type {Maybe} from 'src/utils/Maybe'
import {PSmall, H4} from '../../typography'
import {UnderlineButton, CornerButton} from '../../buttons'
import {BackgroundImageContain} from '../../../styles/imageStyles'
import {useShoppingList} from '../../../context/ShoppingListContext'
import type {
  ShoppingListItemWriteRequest,
  ShoppingListItem,
} from '../../../api/rest'
import Checkbox from '../../form/Checkbox'
import {NoImage} from '../../common'

const ProductInfoContainer = styled.div`
  padding: 25px 0;
  width: 100%;
  border-top: 1px solid ${palette('black10')};
  border-bottom: 1px solid ${palette('black10')};
`

const CheckboxContainer = styled.div`
  padding-top: 25px;
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`

const ItemCount = styled.div`
  padding-left: 4px;
  font-size: 0.75rem;
  color: ${palette('black40')};
`

const AddListContainer = styled.div`
  padding-top: 18px;
  padding-left: 20px;

  ${media.md`
    padding-top: 25px;
`}
`

const TitleContainer = styled.div`
  padding-top: 25px;
  text-align: center;
  padding-bottom: 25px;
`
interface MirroredProps {
  mirrored: boolean
}

const TextWrapper = styled.div<MirroredProps>`
  max-width: 600px;
  text-align: left;

  ${media.sm`
    padding-right: ${ifProp({mirrored: true}, '0', '20px')};
    padding-left: ${ifProp({mirrored: true}, '20px', '0')};
`}
`
interface PopoverProps {
  open: boolean
}
const AddNewListPopover = styled.div<PopoverProps>`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  opacity: ${ifProp({open: true}, 1, 0)};
  z-index: ${ifProp({open: true}, 1, -1)};
  transition: opacity 0.2s ease-in
    ${ifProp({open: true}, '', ', z-index 0.2s step-end')};
`

const PopoverContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`

const PopoverBackgroundBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.25);
`
const CreateListWrapper = styled.div`
  padding: 0 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.sm`
    padding: 0 50px;
  `}
`

const NewListInputContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  padding: 20px;
  background-color: white;
  border: 1px solid black;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: ${palette('red')};
  }
`
interface Props {
  title: string
  image: Maybe<string>
  id: string
  itemInShoppingList: ShoppingListItem[]
}

export default function AddItemToShoppingList({
  title,
  image,
  id,
  itemInShoppingList,
}: Props): JSX.Element {
  const {
    shoppingLists,
    addItemToShoppingList,
    setCreateModalIsOpen,
    createModalIsOpen,
    deleteItemFromShoppingList,
  } = useShoppingList()
  const popoverRef = useRef<HTMLDivElement>(null)

  const initialListsIds: string[] = itemInShoppingList?.map((item) => item.list)

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
      const selectedId = (e.target as HTMLInputElement).value
      const isChecked = (e.target as HTMLInputElement).checked

      if (!isChecked) {
        const listItemId = itemInShoppingList?.find(
          (i) => i.list === selectedId,
        )?.id
        if (listItemId) {
          deleteItemFromShoppingList(listItemId)
        }
      } else {
        const listItem: ShoppingListItemWriteRequest = {
          productVariant: intFromGlobalId(id)?.id,
          quantity: 1,
          list: selectedId,
        }
        addItemToShoppingList(listItem)
      }
    },
    [addItemToShoppingList, deleteItemFromShoppingList, id, itemInShoppingList],
  )

  const handleShoppingLists = useMemo(
    () => (
      <>
        {shoppingLists ? (
          shoppingLists.map((list) => (
            <CheckboxWrapper key={list.id}>
              <Checkbox
                label={list.name}
                type="checkbox"
                name="oskalistar"
                defaultValue={list.id}
                fontWeight="400"
                checked={initialListsIds?.includes(list.id)}
                onClick={handleClick}
              />{' '}
              <ItemCount>({list.items?.length})</ItemCount>
            </CheckboxWrapper>
          ))
        ) : (
          <div>Enginn listi til</div>
        )}
      </>
    ),
    [shoppingLists, initialListsIds, handleClick],
  )

  return (
    <>
      <TitleContainer>
        <H4>
          {itemInShoppingList?.length
            ? 'Uppfæra vöru á óskalistum'
            : 'Bæta vöru við óskalista'}
        </H4>
      </TitleContainer>

      <Row>
        <Col>
          <ProductInfoContainer>
            <Row>
              <Col xs={4} sm={4}>
                {image ? (
                  <BackgroundImageContain>
                    <Image
                      layout="fill"
                      src={image}
                      objectFit="contain"
                      objectPosition="center"
                    />
                  </BackgroundImageContain>
                ) : (
                  <NoImage size={32} textSize="0.875rem" />
                )}
              </Col>
              <Col xs={6} sm={4}>
                <TextWrapper mirrored={false}>
                  <PSmall bold>{title}</PSmall>
                </TextWrapper>
              </Col>
            </Row>
          </ProductInfoContainer>
        </Col>

        <Col>
          <CheckboxContainer>{handleShoppingLists}</CheckboxContainer>
        </Col>

        <Col>
          <AddListContainer>
            <UnderlineButton
              icon={<AddLineIcon color={theme.palette.primary} />}
              text="Búa til nýjan óskalista"
              onClick={() => setCreateModalIsOpen(true)}
            />
          </AddListContainer>
        </Col>
      </Row>
      <AddNewListPopover open={createModalIsOpen} ref={popoverRef}>
        <PopoverContainer>
          <PopoverBackgroundBlur />
          <NewListInputContainer>
            <CornerButton
              icon={<CloseLineIcon size={20} />}
              onClick={() => setCreateModalIsOpen(false)}
            />
            <CreateListWrapper>
              <CreateNewShoppingList />
            </CreateListWrapper>
          </NewListInputContainer>
        </PopoverContainer>
      </AddNewListPopover>
    </>
  )
}
