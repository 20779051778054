import React, {useState, useCallback} from 'react'
import HomeLineIcon from 'remixicon-react/Home4LineIcon'
import InfoCard from 'src/components/account/InfoCard'
import {P, Title7} from 'src/components/typography'
import {theme} from 'src/themes'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import {formatPhoneNumberNoSpaces} from 'src/utils/strings'
import {isNotNull} from 'src/utils/isNotNull'
import type {Maybe} from 'src/utils/Maybe'
import {UnderlineButton} from '../buttons'
import {useUser} from '../../context/UserContext'
import Modal from '../common/Modal'
import AddressForm from './AddressForm'

export default function AddressInfo(): JSX.Element {
  const {user, addressData} = useUser()
  const [show, setShow] = useState<boolean>(false)
  const [ID, setID] = useState<string>('')
  const [firstNameValue, setFirstNameValue] = useState<string>('')
  const [lastNameValue, setLastNameValue] = useState<string>('')
  const [phoneValue, setPhoneValue] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [cityValue, setCityValue] = useState<string>('')
  const [postalCodeValue, setPostalCodeValue] = useState<string>('')

  const handleAddress = useCallback(
    ({
      id,
      firstName,
      lastName,
      phone,
      streetAddress1,
      city,
      postalCode,
    }: {
      id?: Maybe<string>
      firstName?: Maybe<string>
      lastName?: Maybe<string>
      phone?: Maybe<string>
      streetAddress1?: Maybe<string>
      city?: Maybe<string>
      postalCode?: Maybe<string>
    } = {}) => {
      if (!id) {
        setID('')
        setFirstNameValue('')
        setLastNameValue('')
        setPhoneValue('')
        setAddress('')
        setCityValue('')
        setPostalCodeValue('')

        setShow(true)
      }

      setID(id ?? '')
      setFirstNameValue(firstName ?? '')
      setLastNameValue(lastName ?? '')
      setPhoneValue(formatPhoneNumberNoSpaces(phone ?? ''))
      setAddress(streetAddress1 ?? '')
      setCityValue(city ?? '')
      setPostalCodeValue(postalCode ?? '')

      setShow(true)
    },
    [setShow, setID],
  )

  const handleAddresses = (): JSX.Element => (
    <>
      {addressData?.me?.addresses?.filter(isNotNull).map((value) => (
        <InfoCard
          key={value.id}
          icon={<HomeLineIcon size={30} color={theme.palette.primary} />}
          title=""
          description={
            <>
              <P>{value.streetAddress1},</P>
              <P>
                {value.postalCode} {value.city}
              </P>
            </>
          }
          cornerButton
          handleEdit={() => handleAddress(value)}
        />
      ))}
    </>
  )
  return (
    <>
      <div>
        <Title7 withGutter>Heimilisföng</Title7>
        {!!addressData?.me?.addresses?.length && handleAddresses()}
        <UnderlineButton
          icon={<AddLineIcon color={theme.palette.primary} />}
          text="Nýtt heimilisfang"
          onClick={() => handleAddress()}
        />
      </div>
      <Modal width="800px" setShow={setShow} show={show}>
        <AddressForm
          id={ID}
          formTitle="Heimilisfang"
          firstName={firstNameValue || user?.name}
          lastName={lastNameValue || user?.verified?.ssn}
          phone={phoneValue || user?.verified?.phoneNumber}
          streetAddress1={address}
          city={cityValue}
          postalCode={postalCodeValue}
          setShow={setShow}
        />
      </Modal>
    </>
  )
}
