import {useField} from 'formik'
import {forwardRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {ifProp, palette} from 'styled-tools'
import InformationLineIcon from 'remixicon-react/InformationLineIcon'
import InformationFillIcon from 'remixicon-react/InformationFillIcon'
import {media} from 'styled-bootstrap-grid'
import theme from '../../themes/theme'
import {PSmall} from '../typography'

interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string
  name: string
  type?: string
  characterLimit?: number
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  valid?: boolean
  errorMsg?: string
  showError?: boolean
  label?: string
  withGutter?: boolean
  info?: string
  icon?: React.ReactElement
  characterLimit?: number
  charCount?: number
  showCharCount?: boolean
}

interface InputSleeveProps {
  errorMsg?: string
  showError: boolean
  children: React.ReactNode
  info?: string
  icon?: React.ReactElement
}

interface InputWrapperProps {
  withGutter?: boolean
}

interface InputInfoProps {
  info: string
}

interface InputLabelProps {
  disabled?: boolean
}

export const inputCommon = css`
  border: 1px solid ${palette('secondary')};
  background-color: ${palette('white')};
  height: 50px;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  padding: 16px;
  fo &:hover&:not(:disabled) {
    outline: 1px solid ${palette('secondary')};
  }

  &:active,
  &:focus {
    border-width: 1px;
    border-color: ${palette('red')};
    outline: none !important;
  }

  &:disabled {
    background-color: ${palette('black5')};
    border-color: ${palette('black40')};
    cursor: not-allowed;
  }
`

export const InputField = styled.input<InputProps>`
  ${inputCommon}
`

const TextAreaField = styled.textarea<InputProps>`
  ${inputCommon}
  min-height: 150px;
  resize: none;
`

const Error = styled.div`
  position: absolute;
  margin-top: 10px;
  margin-left: 14px;
  font-size: 0.875rem;
  color: ${palette('error')};
  text-align: left;
`

export const InputLabel = styled.label<InputLabelProps>`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.125;
  color: ${ifProp('disabled', palette('black40'), palette('secondary'))};
`

const CharCounter = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

const InputContainer = styled.div`
  position: relative;
  margin-top: 10px;
  max-width: 700px;
`

export const InputWrapper = styled.div<InputWrapperProps>`
  margin-bottom: ${ifProp('withGutter', '32px', '0')};
  max-width: 700px;
  position: relative;
`

const InputInfoWrapper = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
`

const InfoBox = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  width: 250px;
  visibility: hidden;
  background-color: ${palette('white')};
  border: 1px solid ${palette('secondary')};
  padding: 8px;
  transform-style: preserve-3d;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    transform: translateY(8px) translateX(-8px) translateZ(-1em);
    background-color: ${palette('secondary')};
  }

  ${media.sm`
    width: 350px;
  `}
`

const InfoBoxInner = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoIconWrapper = styled.div`
  text-align: right;
`

function InputInfo({info}: InputInfoProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <InputInfoWrapper style={{left: open ? '14px' : 'inherit'}}>
      <InfoIconWrapper>
        <InformationLineIcon
          onClick={() => setOpen(true)}
          style={{cursor: 'pointer'}}
        />
      </InfoIconWrapper>
      <InfoBox style={{visibility: open ? 'visible' : 'hidden'}}>
        <InfoBoxInner>
          <InfoIconWrapper>
            <InformationFillIcon
              onClick={() => setOpen(false)}
              style={{cursor: 'pointer'}}
            />
          </InfoIconWrapper>
          <PSmall style={{padding: '8px'}}>{info}</PSmall>
        </InfoBoxInner>
      </InfoBox>
    </InputInfoWrapper>
  )
}

export function InputSleeve({
  errorMsg,
  showError,
  children,
  info,
  icon,
}: InputSleeveProps): JSX.Element {
  return (
    <InputContainer>
      {children}
      {!!info && <InputInfo info={info} />}
      {!!icon && <InputInfoWrapper>{icon}</InputInfoWrapper>}
      {showError && !!errorMsg && <Error>{errorMsg}</Error>}
    </InputContainer>
  )
}

export const Input = forwardRef<
  HTMLInputElement & HTMLTextAreaElement,
  InputProps
>(
  (
    {
      errorMsg,
      showError,
      type,
      characterLimit,
      charCount,
      showCharCount,
      ...props
    },
    ref,
  ) => (
    <InputWrapper withGutter={props.withGutter}>
      <InputLabel htmlFor={props.id} disabled={props.disabled}>
        {props.label}
      </InputLabel>
      {showCharCount && <CharCounter>{charCount}/50</CharCounter>}
      <InputSleeve
        errorMsg={errorMsg}
        showError={!!showError}
        info={props.info}
        icon={props.icon}
      >
        {type === 'textarea' ? (
          <TextAreaField {...props} ref={ref} />
        ) : (
          <InputField
            {...props}
            ref={ref}
            style={{
              borderBottom: showError
                ? `4px solid ${theme.palette.error}`
                : undefined,
            }}
            maxLength={characterLimit}
            type={type}
          />
        )}
      </InputSleeve>
    </InputWrapper>
  ),
)

const FormikInput = forwardRef<
  HTMLInputElement & HTMLTextAreaElement,
  InputFieldProps
>((props, ref) => {
  const [field, meta] = useField(props)
  const showError = !!(meta.error && meta.touched)
  return (
    <Input
      {...field}
      {...props}
      showError={showError}
      errorMsg={meta.error}
      ref={ref}
      withGutter
    />
  )
})

export default FormikInput
