import React from 'react'

import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {palette} from 'styled-tools'

import {Title7} from '../typography'

interface Props {
  title: string
  description?: React.ReactNode
  onSelect: () => void
  icon?: React.ReactElement
  className?: string
}

const Card = styled.div`
  position: relative;
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  text-align: left;
  margin: 16px 0;
  width: 100%;
  border: 1px solid ${palette('black')};
  background-color: ${palette('white')};
  cursor: pointer;

  &.selected {
    border: 1px solid ${palette('primary')};
    background: ${palette('red10')};
  }

  &:hover {
    border: 1px solid ${palette('primary')};
    background: ${palette('red10')};
  }

  ${media.md`
    padding: 20px;
  `}
`

const IconContainer = styled.div`
  padding-right: 20px;
`

const TextContainer = styled.div``

const Description = styled.div``

export default function ChooseCustomerCard({
  title,
  description,
  onSelect,
  icon,
  className,
}: Props): JSX.Element {
  return (
    <Card onClick={onSelect} className={className}>
      <IconContainer>{icon}</IconContainer>

      <TextContainer>
        <Title7 withGutter>{title}</Title7>

        <Description>{description}</Description>
      </TextContainer>
    </Card>
  )
}
