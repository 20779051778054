import {useCallback, useEffect, useMemo, useState} from 'react'
import {useDebouncedCallback} from 'use-debounce'

export function useSize<T extends string>({
  viewports,
  defaultSize = 0,
}: {
  viewports: Record<T, number>
  defaultSize?: number
}): T {
  const [windowWidth, setWindowWidth] = useState(
    typeof window === 'undefined' ? defaultSize : window.innerWidth,
  )
  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth)
  }, [])
  const handleResizeDebounce = useDebouncedCallback(handleResize)
  useEffect(() => {
    window.addEventListener('resize', handleResizeDebounce)
    return () => {
      window.removeEventListener('resize', handleResizeDebounce)
    }
  }, [handleResizeDebounce])
  return useMemo((): T => {
    const keys = Object.keys(viewports) as T[]
    const key = keys.find((currentKey) => viewports[currentKey] <= windowWidth)
    return key ?? keys[keys.length - 1]
  }, [viewports, windowWidth])
}
