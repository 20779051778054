import React from 'react'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {palette} from 'styled-tools'
import {H1, H2} from '../typography'

const BlockTitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  ${media.lg`
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-left: -70px;
  `}
`

const BlockLine = styled.div`
  height: 2px;
  margin: 0 24px;
  background-color: ${palette('red')};
  width: 0;
  flex-grow: 1;

  ${media.lg`
    max-width: 54px;
    margin: 0 16px 4px 0;
  `}
`

interface Props {
  title: string
  h1?: boolean
}

export default function BlockTitle({h1, title}: Props): JSX.Element {
  const Typography = h1 ? H1 : H2

  return (
    <BlockTitleWrapper>
      <Typography>{title}</Typography>
      <BlockLine />
    </BlockTitleWrapper>
  )
}
