import styled from 'styled-components'
import {palette} from 'styled-tools'
import {FavoriteButton} from './FavoriteButton'

interface WrapperProps {
  recommended: boolean
}

export const ProductCardWrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 500px;
  padding: 16px;
  padding-top: 30px;
  transition: border 0.15s;
  background-color: ${palette('white')};
  border: 1px solid
    ${(p) => (p.recommended ? palette('red') : palette('black20'))};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    opacity: 0;
    transition: opacity 0.15s;
    background-color: ${palette('red')};
  }

  &:hover {
    &:after {
      opacity: 1;
    }

    ${FavoriteButton} {
      opacity: 1;
      cursor: pointer;
    }
  }
`
