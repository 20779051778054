import {useRef} from 'react'
import {useClickAway} from 'react-use'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'
import {theme} from 'src/themes'
import {CornerButton} from 'src/components/buttons'
import {useProductCardContext} from './context'
import {ProductCartPicker} from '..'

interface PopoverProps {
  open: boolean
}

const CartPopover = styled.div<PopoverProps>`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  opacity: ${ifProp({open: true}, 1, 0)};
  z-index: ${ifProp({open: true}, 3, -1)};
  transition: opacity 0.2s ease-in
    ${ifProp({open: true}, '', ', z-index 0.2s step-end')};
`

const CartPopoverContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`

const PopoverBackgroundBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.25);
  @media (max-width: ${theme.screenSize.sizeMD}) {
  }
`

const PickerContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  margin-top: auto;
  padding: 20px 0px 20px;
  background-color: ${palette('white')};
  border: 1px solid ${palette('black')};
  height: 100%;
  @media (max-width: ${theme.screenSize.sizeMD}) {
    box-shadow: rgba(59, 64, 69, 0.721) 0px 20px 30px -10px;
    padding: 20px 10px;
    height: auto;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 9px;
    border-top: 1px solid ${palette('black')};
    background-color: ${palette('red')};
  }
`

const ProductCartContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  @media (max-width: ${theme.screenSize.sizeMD}) {
    padding: 10px 15px;
  }
`

export const CardPopOver = (): JSX.Element => {
  const {setShowPopup, showPopup, product} = useProductCardContext()
  const drawerRef = useRef<HTMLDivElement | null>(null)

  useClickAway(drawerRef, () => {
    setShowPopup(false)
  })

  if (!showPopup) {
    return <></>
  }

  return (
    <CartPopover open={showPopup} ref={drawerRef}>
      <CartPopoverContainer>
        <PopoverBackgroundBlur />
        <PickerContainer>
          <CornerButton
            icon={<CloseLineIcon size={15} />}
            onClick={() => setShowPopup(false)}
          />
          <ProductCartContainer>
            <ProductCartPicker product={product} drawer />
          </ProductCartContainer>
        </PickerContainer>
      </CartPopoverContainer>
    </CartPopover>
  )
}
