import React from 'react'
import {Col, Row} from 'styled-bootstrap-grid'
import {H5} from 'src/components/typography'
import {ShoppingListsPage} from 'src/components/shoppingLists'

export default function MyShoppingListPage(): JSX.Element {
  return (
    <Row>
      <Col>
        <H5 withGutter>Mínir Óskalistar</H5>
        <ShoppingListsPage />
      </Col>
    </Row>
  )
}
