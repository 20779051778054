import React from 'react'
import styled from 'styled-components'
import {ifProp, palette} from 'styled-tools'
import PencilLineIcon from 'remixicon-react/PencilLineIcon'
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon'
import {media} from 'styled-bootstrap-grid'
import cn from 'classnames'

import {CornerButton} from '../buttons'
import {Title8} from '../typography'

interface CardProps {
  pointerCursor: boolean
}

const CardWrapper = styled.div`
  position: relative;

  margin: 16px 0;
  width: 100%;
  border: 1px solid ${palette('black')};
  background-color: ${palette('white')};

  &.selected {
    border: 1px solid ${palette('red')};
  }
`

const Card = styled.div<CardProps>`
  width: 100%;
  padding: 34px 34px 34px 24px;
  cursor: ${ifProp({pointerCursor: true}, 'pointer', 'default')};

  ${media.md`
    padding: 34px;
  `}
`

const Inner = styled.div`
  display: flex;
`

const IconContainer = styled.div`
  padding-right: 24px;

  ${media.md`
    padding-right: 50px;
  `}
`

const TextContainer = styled.div``

const Description = styled.div``

interface Props {
  icon: React.ReactElement
  deleteIcon?: boolean
  title: string
  description: React.ReactNode
  onSelect?: () => void
  handleEdit?: () => void
  handleDelete?: () => void
  selected?: boolean
  cornerButton?: boolean
  pointerCursor?: boolean
}

export default function InfoCard({
  icon,
  deleteIcon,
  title,
  description,
  onSelect,
  handleEdit,
  handleDelete,
  cornerButton,
  selected = false,
  pointerCursor = false,
}: Props): JSX.Element {
  return (
    <CardWrapper className={cn({selected})}>
      {cornerButton && (
        <CornerButton
          icon={
            deleteIcon ? (
              <DeleteBinLineIcon size={16} />
            ) : (
              <PencilLineIcon size={16} />
            )
          }
          onClick={handleEdit || handleDelete}
        />
      )}
      <Card pointerCursor={pointerCursor} onClick={onSelect && onSelect}>
        <Inner>
          <IconContainer>{icon}</IconContainer>

          <TextContainer>
            <Title8 withGutter>{title}</Title8>

            <Description>{description}</Description>
          </TextContainer>
        </Inner>
      </Card>
    </CardWrapper>
  )
}
