import React from 'react'
import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'
import type {FieldConfig} from 'formik'
import {useField} from 'formik'
import {theme} from 'src/themes'
import shadowStyles from '../../styles/shadowStyles'
import {H6, PSmall, Title6} from '../typography'
import {ProductTag, ProductBadgeVariant} from '../products'

interface LabelProps {
  withGutter: boolean
  disabled: ConstrainBoolean
}

interface CircleProps {
  color: 'primary' | 'secondary'
}

const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: ${ifProp({disabled: true}, 'not-allowed', 'pointer')};
  margin-bottom: ${ifProp('withGutter', '24px', '0')};
  padding: 10px;
  padding-bottom: 14px;
`

const Circle = styled.div<CircleProps>`
  position: relative;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border: 1px solid ${(p) => palette(p.color)};
  border-radius: 10px;
  box-sizing: border-box;
  background-color: ${palette('white')};
  transition: transform 0.5s;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background-color: ${(p) => palette(p.color)};
    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:before {
    border-radius: 10px;
  }
`

const Background = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Content = styled.div`
  display: flex;

  flex-direction: column;
  gap: 6px;
  width: 100%;
  z-index: 1;
  ${H6} {
    width: inherit;
    margin-right: 8px;
    margin-left: 16px;
  }
  ${PSmall} {
    line-height: 1.4;
  }
  ${Title6} {
    width: inherit;
  }
  @media (max-width: ${theme.screenSize.sizeSM}) {
    ${Title6} {
      font-size: 14px;
      line-height: 1.4;
    }
  }
`
const ShadowContainer = styled.div<CircleProps>`
  width: fit-content;
  height: fit-content;
  border: 1px solid transparent;
  border-radius: 10px;
  ${(p) =>
    shadowStyles({
      color: p.color,
      outlined: false,
      length: 4,
      showOnHover: true,
      borderRadius: 10,
    })}
`

const RadioInput = styled.input`
  visibility: hidden;
  position: absolute;
  border-radius: 0;

  ~ ${Background} {
    background-color: ${palette('white')};
    border: 1px solid ${palette('black')};
  }

  &:checked {
    color: ${palette('white')};

    ~ ${ShadowContainer} {
      > ${Circle} {
        &:after {
          transform: scale(1);
        }
      }
    }
    ~ ${Background} {
      background-color: ${palette('secondary')};
      border-bottom: 4px solid ${palette('red')};
    }
    ~ ${Content} {
      color: ${palette('white')};
    }
  }

  &:disabled {
    ~ ${Content} {
      color: ${palette('black40')};
    }
    ~ ${ShadowContainer} {
      > ${Circle} {
        border-color: ${palette('secondary')};
        background-color: ${palette('background')};
      }
    }
    ~ ${Background} {
      background-color: ${palette('background')};
      border: 1px solid ${palette('secondary')};
    }
    &:checked {
      ~ ${ShadowContainer} {
        > ${Circle} {
          background-color: ${palette('background')};
        }
        &:after {
          background-color: ${palette('background')};
        }
      }
    }
  }
`

const LabelHead = styled.div`
  display: flex;
  align-items: flex-end;
`

const PriceSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
`

const Tag = styled.div`
  position: absolute;
  top: -20px;
  right: 16px;
  z-index: 1;
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  withGutter?: boolean
  color?: 'primary' | 'secondary'
  unitPrice: string
  price: string
  numberOfUnits?: number
  discount?: string
  originalPrice?: string | null
}
const FormerPrice = styled(Title6)`
  color: ${palette('red')};
  text-decoration: line-through;
  margin-left: auto;
  margin-right: 12px;
`

export default function RadioField({
  label,
  disabled,
  withGutter = false,
  color = 'primary',
  unitPrice,
  originalPrice,
  price,
  numberOfUnits,
  discount,
  ...props
}: Props): JSX.Element {
  return (
    <Label withGutter={withGutter} disabled={!!disabled}>
      <Tag>
        {discount && (
          <ProductTag
            badgeVariant={ProductBadgeVariant.Discount}
            title={discount}
          />
        )}
      </Tag>
      <RadioInput type="radio" disabled={disabled} {...props} />
      <Background />
      <Content>
        <LabelHead>
          <H6>{label}</H6>
          {numberOfUnits && <PSmall>{`(${numberOfUnits} stk.)`}</PSmall>}
        </LabelHead>
        <PriceSection>
          <PSmall>{unitPrice}</PSmall>
          {originalPrice ? <FormerPrice>{originalPrice}</FormerPrice> : null}
          <Title6>{price}</Title6>
        </PriceSection>
      </Content>
      <ShadowContainer color={color}>
        <Circle color={color} />
      </ShadowContainer>
    </Label>
  )
}

export function FormikRadioButton<T>(
  props: Props & React.InputHTMLAttributes<HTMLInputElement> & FieldConfig<T>,
): JSX.Element {
  const [field] = useField(props)

  return <RadioField {...field} {...props} />
}
