import type {Dispatch, SetStateAction} from 'react'
import {createContext, useContext, useState} from 'react'
import type {RvProduct} from 'src/api/local'
import {Certifications} from './Certifications'
import {Docs} from './Docs'
import {ProductDescription} from './ProductDescription'
import {SecurityDocs} from './SecurityDocs'

export const ProductDescriptionLabel = 'Lýsing'
export type ProductDescriptionLabel = typeof ProductDescriptionLabel
export const SecurityDocsLabel = 'Öryggisskjöl'
export type SecurityDocsLabel = typeof SecurityDocsLabel
export const CertificationsLabel = 'Vottanir'
export type CertificationsLabel = typeof CertificationsLabel
export const DocsLabel = 'Upplýsingar'
export type DocsLabel = typeof DocsLabel
export const ProductTabLabels = [
  ProductDescriptionLabel,
  SecurityDocsLabel,
  CertificationsLabel,
  DocsLabel,
] as const
export type ProductTabLabel =
  | ProductDescriptionLabel
  | SecurityDocsLabel
  | CertificationsLabel
  | DocsLabel

interface ProviderTab {
  visible: boolean
  label: ProductTabLabel
  Component: () => JSX.Element
}

const ProductTabComponents: Record<ProductTabLabel, () => JSX.Element> = {
  [ProductDescriptionLabel]: ProductDescription,
  [SecurityDocsLabel]: SecurityDocs,
  [CertificationsLabel]: Certifications,
  [DocsLabel]: Docs,
}

const ProductTabComponentsIsVisible: Record<
  ProductTabLabel,
  (product: RvProduct) => boolean
> = {
  [ProductDescriptionLabel]: () => true,
  [SecurityDocsLabel]: (product) => !!product.securityDocs.length,
  [CertificationsLabel]: (product) => !!product.ecoCerts.length,
  [DocsLabel]: (product) => !!product.infoDocs.length,
}

export interface ProviderTabs {
  product: RvProduct
  activeTab: ProductTabLabel
  setActiveTab: Dispatch<SetStateAction<ProductTabLabel>>
  tabs: ProviderTab[]
}

const ProviderContext = createContext<ProviderTabs | null>(null)

export const useProductTabContext = (): ProviderTabs => {
  const value = useContext(ProviderContext)
  if (value == null) {
    throw new Error('Invalid usage')
  }
  return value
}

export const ProductTabContextProvider = ({
  children,
  product,
}: {
  children: JSX.Element | JSX.Element[]
  product: RvProduct
}): JSX.Element => {
  const [activeTab, setActiveTab] = useState<ProductTabLabel>(
    ProductTabLabels[0],
  )
  // This never changes, since the array is a const that should never change.
  const tabs = ProductTabLabels.map((key) => ({
    label: key,
    Component: ProductTabComponents[key],
    visible: ProductTabComponentsIsVisible[key](product),
  }))
  const value = {
    tabs,
    product,
    setActiveTab,
    activeTab,
  }
  return (
    <ProviderContext.Provider value={value}>
      {children}
    </ProviderContext.Provider>
  )
}
