import React from 'react'
import UserLineIcon from 'remixicon-react/User3LineIcon'
import BuildingLineIcon from 'remixicon-react/Building4LineIcon'

import {useUser} from 'src/context/UserContext'
import {theme} from 'src/themes'
import {P} from 'src/components/typography'
import {useOverlay} from 'src/context/OverlayContext'
import {ChooseCustomerCard} from '.'

export default function ChooseCustomer(): JSX.Element {
  const {
    user,
    userCompanies,
    setCompanyId,
    removeCompanyId,
    resetSelectAccount,
  } = useUser()

  const {setSideDrawerIsOpen} = useOverlay()

  const handleClick = (
    selectedUser: boolean,
    selectedCompany: string | null,
  ): void => {
    requestAnimationFrame(() => setSideDrawerIsOpen(false))
    if (selectedUser) {
      removeCompanyId()
    } else if (selectedCompany) {
      setCompanyId(selectedCompany)
    }
    resetSelectAccount()
  }
  return (
    <>
      <P bold>Halda áfram að versla sem:</P>
      <ChooseCustomerCard
        icon={<UserLineIcon size={30} color={theme.palette.primary} />}
        title={user?.name ?? 'Notandi'}
        onSelect={() => handleClick(true, null)}
      />
      {userCompanies?.map((company) => (
        <ChooseCustomerCard
          key={company.id}
          icon={<BuildingLineIcon size={30} color={theme.palette.primary} />}
          title={company.name}
          onSelect={() => handleClick(false, `${company.id}`)}
        />
      ))}
    </>
  )
}
