import Link from 'next/link'
import React from 'react'
import {usePageContext} from 'src/context/PageContext'
import type {Webpage_header_links_PageCategoryRecord as PageCategory} from 'src/api/datocms/types/Webpage'
import {NavBarLink} from './Header'

interface PageCategoryProps {
  category: PageCategory
}

export default function PageCategoryLinks({
  category,
}: PageCategoryProps): JSX.Element {
  const {getPagesByCategory, getLinksBySlug} = usePageContext()
  const pages = getPagesByCategory(category?.id) || []
  const links = category?.slug ? getLinksBySlug(category?.slug) : []

  return (
    <>
      {(pages.length ? pages : links).map((link) =>
        link.path ? (
          <Link href={link.path} key={link.heading} passHref>
            <NavBarLink>{link.heading}</NavBarLink>
          </Link>
        ) : null,
      )}
    </>
  )
}
