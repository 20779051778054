import React, {useCallback} from 'react'
import {useFormik} from 'formik'
import {Col, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import FileListLineIcon from 'remixicon-react/FileListLineIcon'
import type {KeyedMutator} from 'swr/dist/types'

import {theme} from 'src/themes'
import type {Maybe} from 'src/utils/Maybe'
import {Input} from '../form/Input'
import {Button} from '../buttons'
import {Title6, H4} from '../typography'
import {useShoppingList} from '../../context/ShoppingListContext'
import type {ShoppingList, ShoppingListRequest} from '../../api/rest'
import {Loader} from '../common'

interface Props {
  list?: Maybe<ShoppingList>
  revalidateSingleShoppingList?: KeyedMutator<ShoppingList>
}

export interface ShoppingListFormErrorProps {
  name?: string
}

const InputLabelWrapper = styled.div`
  padding-left: 2px;
`
const InputWrapper = styled.div`
  padding-top: 12px;
`
const ButtonWrapper = styled.div`
  padding-top: 12px;
`
const TitleWrapper = styled.div`
  text-align: center;
  padding-top: 25px;
`
export default function CreateNewShoppingList({
  list,
  revalidateSingleShoppingList,
}: Props): JSX.Element {
  const {
    createShoppingList,
    setCreateModalIsOpen,
    updateShoppingList,
    shoppingLists,
  } = useShoppingList()

  const validateName = (
    values: ShoppingListRequest,
  ): ShoppingListFormErrorProps => {
    const errors: ShoppingListFormErrorProps = {}
    const isDuplicate = shoppingLists?.some(
      (l) => l.name.toLowerCase() === values?.name.toLowerCase(),
    )
    if (!values.name) {
      errors.name = 'Hér vantar nafn'
    } else if (isDuplicate) {
      errors.name =
        'Þessi óskalisti er nú þegar til. Vinsamlegast veldu nýtt nafn.'
    }
    return errors
  }

  const initialValues: ShoppingListRequest = {
    name: list?.name ?? '',
  }
  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validate: (values) => validateName(values),
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      if (list) {
        updateShoppingList(list.id, values)
        if (revalidateSingleShoppingList) {
          revalidateSingleShoppingList()
        }
      } else {
        createShoppingList(values)
      }
      if (setCreateModalIsOpen) {
        setCreateModalIsOpen(false)
      }
      setSubmitting(false)
      resetForm({
        values: {
          name: '',
        },
      })
    },
  })

  const handleClick = useCallback(() => {
    if (!setCreateModalIsOpen) {
      return
    }
    setCreateModalIsOpen(false)
  }, [setCreateModalIsOpen])

  return (
    <>
      <TitleWrapper>
        <H4 withGutter>{list ? 'Breyta' : 'Búa til nýjan'} óskalista</H4>
      </TitleWrapper>

      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={12}>
            <InputWrapper>
              <Title6 textAlign="left">
                <InputLabelWrapper>
                  <FileListLineIcon color={theme.palette.primary} size={12} />{' '}
                  Heiti óskalista
                </InputLabelWrapper>
              </Title6>
              <Input
                name="name"
                label=""
                type="text"
                placeholder="T.d. Mötuneyti"
                autoComplete="off"
                onChange={formik.handleChange}
                value={formik.values.name}
                showError={!!formik.errors.name}
                errorMsg={formik.errors.name}
                withGutter
              />
            </InputWrapper>
          </Col>
          <Col>
            <ButtonWrapper>
              <Row justifyContent="center">
                <Col order={2} sm={6} xs={6}>
                  <Button
                    fill
                    disabled={
                      !formik.values.name ||
                      formik.values.name === initialValues.name
                    }
                    withGutter
                    type="submit"
                  >
                    {list ? 'Staðfesta' : 'Búa til lista'}
                  </Button>
                </Col>
                <Col order={1} sm={6} xs={6}>
                  <Button
                    fill
                    withGutter
                    color="secondary"
                    type="button"
                    outlined
                    onClick={handleClick}
                  >
                    Hætta við
                  </Button>
                </Col>
              </Row>
            </ButtonWrapper>
          </Col>
        </Row>
        {formik.isSubmitting && <Loader />}
      </form>
    </>
  )
}
