import {useWindowSize} from 'react-use'
import {P} from 'src/components/typography'
import {gridTheme} from 'src/themes'
import styled from 'styled-components'
import {useProductCartPickerContext} from './context'

const Sentance = styled(P)`
  &::first-letter {
    text-transform: capitalize;
  }
`

const Spacer = styled.div`
  margin-top: 48px;
`
export const ProductCartOfferingPieceOrBox = (): JSX.Element | null => {
  const {product} = useProductCartPickerContext()
  const {width} = useWindowSize()

  if (!product.offerString) {
    return <Spacer />
  }
  return (
    <Sentance withGutter color={!product.isAvailable ? 'black40' : undefined}>
      {width > gridTheme.breakpoints.sm ? 'Má bjóða þér ' : null}
      {product.offerString}?
    </Sentance>
  )
}
