import {useCallback} from 'react'
import {CartButton} from 'src/components/buttons'
import {Loader} from 'src/components/common'
import {H4, H6} from 'src/components/typography'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {ProductTypeBox, ProductTypePiece} from '../ProductCartPicker/interfaces'
import {FormerPrice} from './components'
import {useProductCardContext} from './context'

const LoaderWrapper = styled.div`
  height: 105px;
  display: flex;
  align-items: flex-end;

  ${media.md`
    heigth: 115px;
  `}
`

export const CardShop = (): JSX.Element | null => {
  const {product, setShowPopup} = useProductCardContext()
  const handleClick = useCallback(() => {
    setShowPopup((value) => !value)
  }, [setShowPopup])
  if (product.pricing === null) {
    return null
  }
  const price = product?.pricing
    ? product.pricing.find(({unitType}) => unitType === ProductTypeBox) ??
      product.pricing[0]
    : undefined

  const piecePrice = product?.pricing
    ? product.pricing.find(({unitType}) => unitType === ProductTypePiece) ??
      product.pricing[0]
    : undefined

  if (piecePrice === undefined) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  const specialOrder = product.labels.some((s) => s.name === 'Sérpöntun')
  const cartButtonText =
    (specialOrder && 'Setja vöru í körfu') ||
    (!product.isAvailable && 'Vara væntanleg') ||
    (product.hidePrice && 'Senda fyrirspurn') ||
    'Setja vöru í körfu'

  return (
    <>
      <div>
        {!product?.hidePrice && (
          <>
            <FormerPrice>
              <H6>{piecePrice.grossOriginalPriceFormatted || null} </H6>
            </FormerPrice>
            {price ? (
              <H4 withGutter>{price.grossUnitPriceFormatted}</H4>
            ) : (
              <H4 withGutter>{piecePrice.grossUnitPriceFormatted}</H4>
            )}
          </>
        )}
        <CartButton
          color={!product.isAvailable ? 'black40' : 'secondary'}
          fill
          disabled={!product.isAvailable}
          showIcon={!product.hidePrice}
          onClick={handleClick}
        >
          {cartButtonText}
        </CartButton>
      </div>
    </>
  )
}
