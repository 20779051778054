import React from 'react'
import type {Maybe} from 'src/utils/Maybe'

import styled from 'styled-components'
import {palette} from 'styled-tools'
import shadowStyles from '../../styles/shadowStyles'
import {H6} from '../typography'
import {EcoCertImage} from './ProductTabs/Certifications/EcoCertImage'

export enum ProductBadgeVariant {
  OutOfStock = 'outOfStock',
  ComingSoon = 'væntanlegt',
  NewProduct = 'ný-vara',
  Recommended = 'rv-mælir-með',
  Discount = 'discount',
  OnSaleStock = 'lagerhreinsun',
  SpecialOffer = 'tilboð',
}

export enum ProductLabelVariant {
  EcoLabel = 'umhverfisvottuð-vara',
  NordicSwan = 'svansmerkt',
  // Vegan = 'vegan',
}

interface Props {
  badgeVariant?: ProductBadgeVariant
  labelVariant?: ProductLabelVariant
  title?: string
  labelImage?: string | undefined | null
  slug?: string
}
interface LabelsProps {
  labelVariant: Maybe<ProductLabelVariant>
  labelImage: string | undefined | null
  title: string
  slug?: string
}
interface BadgesProps {
  badgeVariant: Maybe<ProductBadgeVariant>
  title: string
}

interface BoxProps {
  color?: string
}

const ProductTagBox = styled.div<BoxProps>`
  border: 1px solid ${(p) => (p.color ? palette(p.color) : 'inherit')};
  background-color: ${palette('white')};
  color: ${palette('secondary')};
  padding: 8px;
  margin-left: 8px;
  ${(p) =>
    shadowStyles({
      color: p.color ?? 'inherit',
      outlined: false,
      length: 4,
      showOnHover: false,
    })}
`

const badgeVariantMap: Record<string, [string, string]> = {
  outOfStock: ['Vara uppseld', 'black20'],
  væntanlegt: ['Vara væntanleg', 'black20'],
  'ný-vara': ['Ný vara', 'green'],
  'rv-mælir-með': ['RV mælir með', 'primary'],
  discount: ['', 'green'],
  lagerhreinsun: ['Lagerhreinsun', 'yellow'],
  tilboð: ['Tilboð', 'green'],
}

const labelVariantMap: Record<string, [string, string]> = {
  sérpöntun: ['Sérpöntun', 'primary'],
  lagerhreinsun: ['Lagerhreinsun', 'yellow'],
  tilboð: ['Tilboð', 'green'],
  sjúkratryggingar: ['Sjúkratryggingar', 'blue'],
  sjúkratrygginar: ['Sjúkratrygginar', 'blue'],
}

export function Badges({badgeVariant, title}: BadgesProps): JSX.Element | null {
  if (badgeVariant == null) {
    return null
  }

  const [badgeTitle, badgeColor] = badgeVariantMap[badgeVariant]
  return (
    <ProductTagBox color={badgeColor}>
      <H6>{badgeTitle || title}</H6>
    </ProductTagBox>
  )
}

export function Labels({
  labelVariant,
  labelImage,
  title,
  slug,
}: LabelsProps): JSX.Element | null {
  if (labelVariant == null) {
    return null
  }
  if (!labelImage) {
    let labelTitle = ''
    let labelColor = ''
    if (labelVariantMap[labelVariant]) {
      ;[labelTitle, labelColor] = labelVariantMap[labelVariant]
    } else {
      labelTitle = labelVariant
    }
    return (
      <ProductTagBox color={labelColor}>
        <H6>{labelTitle || title}</H6>
      </ProductTagBox>
    )
  }
  return <EcoCertImage imageSrc={labelImage} slug={slug} />
}

export default function ProductTag({
  badgeVariant,
  labelVariant,
  labelImage,
  slug,
  title = '',
}: Props): JSX.Element {
  return (
    <>
      <Badges badgeVariant={badgeVariant} title={title} />
      <Labels
        labelVariant={labelVariant}
        labelImage={labelImage}
        title={title}
        slug={slug}
      />
    </>
  )
}
