import type {RvProduct} from 'src/api/local'
import {ProductTabContextProvider} from './ProductTabContext'
import ProductTabContent from './ProductTabContent'

interface Props {
  product: RvProduct
}

export function ProductTabs({product}: Props): JSX.Element {
  return (
    <ProductTabContextProvider product={product}>
      <ProductTabContent />
    </ProductTabContextProvider>
  )
}
