import Image from 'next/image'
import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import ArrowRightUpLineIcon from 'remixicon-react/ArrowRightUpLineIcon'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon'

import {useShoppingList} from 'src/context/ShoppingListContext'
import {DeleteConfirmation, Link, Modal} from '../common'
import theme from '../../themes/theme'
import {H5, PSmall} from '../typography'
import shadowStyles from '../../styles/shadowStyles'
import {BackgroundImageCover} from '../../styles/imageStyles'
import {CornerButton} from '../buttons'

const TrashButton = styled.div`
  opacity: 0;
  transition: opacity 0.15s;
  z-index: 1;
`

const EmptyListText = styled.div`
  min-height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${palette('background')};
  color: ${palette('black40')};
`

const CategoryTitleBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: flex-end;
  color: ${palette('secondary')};
  border-top: 1px solid ${palette('black20')};
  min-height: 125px;
  transition: background-color 0.15s;

  ${H5} {
    position: relative;
    padding-top: 16px;
    padding-bottom: 8px;
    text-align: left;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 20px;
      border-top: 1px solid ${palette('red')};
    }
  }
`

const AddIconWrapper = styled.div`
  height: 50%;
  background: ${palette('background')};
  display: flex;
  justify-content: center;
  align-items: center;

  .remixicon-icon {
    fill: ${palette('black20')};
  }
`

const IconContainer = styled.div`
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  transition: visibility 0.15s;
`

const CardContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 250px;
  min-width: 200px;
  max-width: 500px;
  margin-bottom: 20px;
  transition: color 0.5s, border-color 0.5s, background-color 0.5s;
  border: 1px solid ${palette('black20')};
  background: ${palette('white')};
  cursor: pointer;
  ${shadowStyles({
    color: 'red',
    outlined: false,
    length: 8,
    showOnHover: true,
  })};

  &:hover&:not(:disabled) {
    border: 1px solid ${palette('red')};

    ${AddIconWrapper} {
      .remixicon-icon {
        fill: ${palette('primary')};
      }
    }

    ${IconContainer} {
      visibility: visible;
    }

    ${TrashButton} {
      opacity: 1;
      cursor: pointer;
    }
  }

  &:active {
    ${CategoryTitleBox} {
      background-color: ${palette('red10')};
    }
  }

  ${BackgroundImageCover} {
    background-color: ${palette('white')};
  }

  &:focus,
  &:focus-visible {
    outline: 3px solid ${palette('sky')};
  }
`

const CreateButton = styled.button`
  background-color: ${palette('white')};
  border: none;
  padding: 0;
  height: 250px;
  cursor: pointer;
`
interface Props {
  listId?: string
  src?: string
  title?: string
  href?: string
  description?: string
  linkCard?: boolean
  emptyList?: boolean
  onClickCreate?: () => void
}

export default function ShoppingListCard({
  listId,
  src,
  title,
  description,
  linkCard,
  emptyList,
  href,
  onClickCreate,
}: Props): JSX.Element {
  const {deleteShoppingLists} = useShoppingList()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const handleRemove = useCallback((): void => {
    if (listId) {
      deleteShoppingLists(listId)
    }
    setShowDeleteModal(false)
  }, [listId, deleteShoppingLists])

  return (
    <>
      <CardContainer>
        {linkCard ? (
          <>
            <TrashButton>
              <CornerButton
                icon={<DeleteBinLineIcon size={16} />}
                onClick={() => setShowDeleteModal(true)}
              />
            </TrashButton>
            {href ? (
              <Link href={href}>
                {!emptyList ? (
                  <BackgroundImageCover>
                    {src ? (
                      <Image
                        src={src}
                        layout="fill"
                        objectFit="contain"
                        objectPosition="center"
                      />
                    ) : null}
                  </BackgroundImageCover>
                ) : (
                  <EmptyListText>Þessi óskalisti er tómur</EmptyListText>
                )}
                <CategoryTitleBox>
                  <IconContainer>
                    <ArrowRightUpLineIcon color={theme.palette.red} />
                  </IconContainer>

                  <H5>{title || 'Vöruflokkur'}</H5>
                  <PSmall color="black40">{description}</PSmall>
                </CategoryTitleBox>
              </Link>
            ) : null}
          </>
        ) : (
          <CreateButton onClick={onClickCreate}>
            <AddIconWrapper>
              <AddLineIcon />
            </AddIconWrapper>
            <CategoryTitleBox>
              <H5>{title}</H5>
            </CategoryTitleBox>
          </CreateButton>
        )}
      </CardContainer>
      <Modal setShow={setShowDeleteModal} show={showDeleteModal}>
        <DeleteConfirmation
          title={`Ertu viss um að þú viljir eyða „${title}“?`}
          text="Þú munt ekki geta tekið þessa aðgerð til baka."
          onCancel={() => setShowDeleteModal(false)}
          onRemove={handleRemove}
          cancelText="Hætta við"
          submitText="Eyða lista"
        />
      </Modal>
    </>
  )
}
