import {paragraphStyles} from 'src/components/typography'
import styled from 'styled-components'
import {gridTheme} from 'src/themes'

export const Description = styled.div`
  ${paragraphStyles}
  margin-bottom: 24px;
  display: none;
  @media (min-width: ${gridTheme.breakpoints.lg}px) {
    display: block;
  }
`
