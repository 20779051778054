import React, {useEffect} from 'react'
import {useOverlay} from 'src/context/OverlayContext'

import {useUser} from 'src/context/UserContext'
import {LoginForm, CompanyUserForm, ChooseCustomer} from '.'
import {Loader} from '../common'
import {ShowEnd} from '../registration/ShowEnd'
import EmailForm from './EmailForm'

// TODO(style): Needs better styling/design
export default function LoginPage(): JSX.Element {
  const {
    user,
    missingEmail,
    changeUserEmail,
    userLoading,
    userCompanies,
    userCompaniesLoading,
    showChooseCustomer,
    showRegistrationMsg,
    resetUser,
    selectAccount,
  } = useUser()
  const {setSideDrawerIsOpen} = useOverlay()
  const showSelectAccount =
    showChooseCustomer && selectAccount && userCompanies.length > 0
  const showLoader = userLoading || (user && userCompaniesLoading)
  const showLoginForm = (!user && !userLoading) || resetUser
  const showEmailForm = user && missingEmail
  const showCompanyUserForm =
    user &&
    !missingEmail &&
    !userCompanies?.length &&
    !showRegistrationMsg &&
    !userCompaniesLoading

  useEffect(() => {
    if (showSelectAccount) {
      requestAnimationFrame(() => setSideDrawerIsOpen(true))
    } else {
      requestAnimationFrame(() => setSideDrawerIsOpen(false))
    }
  }, [setSideDrawerIsOpen, showSelectAccount])

  return (
    <>
      {/* TODO: Needs a better loader */}
      {showLoader && <Loader />}
      {showLoginForm && <LoginForm />}
      {showEmailForm && (
        <EmailForm changeUserEmail={changeUserEmail} id={user?.id} />
      )}
      {showCompanyUserForm && <CompanyUserForm />}
      {showRegistrationMsg && <ShowEnd />}
      {showSelectAccount && <ChooseCustomer />}
    </>
  )
}
