import React from 'react'
import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'
import type {FieldConfig} from 'formik'
import {useField} from 'formik'
import shadowStyles from '../../styles/shadowStyles'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  withGutter?: boolean
  color?: 'primary' | 'secondary'
}

interface LabelProps {
  withGutter: boolean
  disabled: boolean
}

interface CircleProps {
  color: 'primary' | 'secondary'
}

const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: ${ifProp({disabled: true}, 'not-allowed', 'pointer')};
  margin-bottom: ${ifProp('withGutter', '24px', '0')};
`

const Text = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: ${palette('blue')};
  line-height: 1.2;
  margin-left: 12px;
`

const Circle = styled.div<CircleProps>`
  position: relative;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border: 1px solid ${(p) => palette(p.color)};
  border-radius: 10px;
  box-sizing: border-box;
  background-color: ${palette('white')};
  transition: transform 0.5s;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background-color: ${(p) => palette(p.color)};
    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:before {
    border-radius: 10px;
  }
`

const RadioInput = styled.input`
  visibility: hidden;
  position: absolute;
  border-radius: 0;

  &:checked ~ ${Circle} {
    &:after {
      transform: scale(1);
    }
  }

  &:disabled {
    ~ ${Text} {
      color: ${palette('black40')};
    }
    ~ ${Circle} {
      border-color: ${palette('black40')};
      background-color: ${palette('black5')};

      &:hover {
        transform: none;

        &::before {
          transform: translateZ(-1em);
        }
      }
    }
    &:checked {
      ~ ${Circle} {
        background-color: ${palette('black20')};

        &:after {
          background-color: ${palette('black40')};
        }
      }
    }
  }
`
const InputContainer = styled.div<CircleProps>`
  width: fit-content;
  height: fit-content;
  border: 1px solid transparent;
  border-radius: 10px;
  ${(p) =>
    shadowStyles({
      color: p.color,
      outlined: false,
      length: 4,
      showOnHover: true,
      borderRadius: 10,
    })}
`

export default function RadioButton({
  label,
  disabled,
  withGutter = false,
  color = 'primary',
  ...props
}: Props): JSX.Element {
  return (
    <Label withGutter={withGutter} disabled={!!disabled}>
      {!!label && <Text>{label}</Text>}
      <InputContainer color={color}>
        <RadioInput type="radio" disabled={disabled} {...props} />
        <Circle color={color} />
      </InputContainer>
    </Label>
  )
}

export function FormikRadioButton<T>(
  props: Props & React.InputHTMLAttributes<HTMLInputElement> & FieldConfig<T>,
): JSX.Element {
  const [field] = useField(props)

  return <RadioButton {...field} {...props} />
}
