import type {Dispatch, SetStateAction} from 'react'
import {createContext, useContext} from 'react'
import type {RvProduct} from 'src/api/local'

export interface ProductCartPickerContextProps {
  type: string
  setType: Dispatch<SetStateAction<string>>
  currentQuantity: number
  setCurrentQuantity: (value: number) => void
  product: RvProduct
  qtyInCart: number
}

export const ProductCartPickerContext = createContext<null | ProductCartPickerContextProps>(
  null,
)

export const useProductCartPickerContext = (): ProductCartPickerContextProps => {
  const data = useContext(ProductCartPickerContext)
  if (data == null) {
    throw new Error('invalid usage of context')
  }

  return data
}
