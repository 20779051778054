import {useProductTabContext} from '../ProductTabContext'
import {EcoCert} from './EcoCert'

export const EcoCerts = (): JSX.Element | null => {
  const {product} = useProductTabContext()
  const value = product.ecoCerts
  if (value == null) {
    return null
  }
  return (
    <>
      {value.map((item) => (
        <EcoCert
          slug={item.slug}
          key={item.id}
          title={item.name}
          imageSrc={item.thumbnail?.url ?? null}
        />
      ))}
    </>
  )
}
