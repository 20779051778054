// import HeartLineIcon from 'remixicon-react/HeartLineIcon'
import Layout2LineIcon from 'remixicon-react/Layout2LineIcon'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import BuildingLineIcon from 'remixicon-react/Building4LineIcon'
import {paths} from 'src/utils/paths'
import type {Item} from './AccountSidebar'

export const items: Item[] = [
  {
    key: 'account',
    title: 'Yfirlit',
    href: paths.account,
    icon: Layout2LineIcon,
  },
  {
    key: 'myInfo',
    title: 'Mínar upplýsingar',
    href: paths.myInfo,
    icon: UserLineIcon,
  },
  {
    key: 'myOrders',
    title: 'Mínar pantanir',
    href: paths.myOrders,
    icon: UserLineIcon,
  },
  {
    key: 'fyrirtaekjabeidni',
    title: 'Tengjast fyrirtæki',
    href: paths.companyRequest,
    icon: BuildingLineIcon,
  },
  // {
  //   key: 'myShoppingList',
  //   title: 'Óskalistar',
  //   href: paths.myShoppingList,
  //   icon: HeartLineIcon,
  // },
]
