/* eslint-disable no-console */
import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {useFormik} from 'formik'
import EyeLineIcon from 'remixicon-react/EyeLineIcon'
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon'

import {useUser} from 'src/context/UserContext'

import {Title4} from 'src/components/typography'
import type {TokenCreateMutationVariables} from 'src/api/saleor/generated'
import {validateEmail} from 'src/utils/validation'
import {SpacingWrapper} from '../layout'
import {Button} from '../buttons'
import {Input} from '../form/Input'
import Loader from '../common/Loader'

interface ErrorProps {
  email?: string
  password?: string
}

const Divider = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`
const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

export default function EmailLoginForm(): JSX.Element {
  const {signInWithEmail, errorMsg, setErrorMsg, isVerifyingLogin} = useUser()
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const togglePassword = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  function validateEmailLoginFormFields(
    values: TokenCreateMutationVariables,
  ): ErrorProps {
    const errors: ErrorProps = {}

    if (!values.email) {
      errors.email = 'Hér vantar netfang'
    } else if (!validateEmail(values.email)) {
      errors.email = 'Netfangið er ekki á réttu formi'
    }

    if (!values.password) {
      errors.password = 'Hér vantar lykilorð'
    }

    return errors
  }

  const initialValues: TokenCreateMutationVariables = {
    email: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    validateOnChange: false,

    validate: (values) => validateEmailLoginFormFields(values),
    onSubmit: (values) => {
      signInWithEmail(values.email, values.password)
    },
  })

  const handleClick = useCallback(() => {
    setErrorMsg(null)
  }, [setErrorMsg])

  return (
    <>
      {isVerifyingLogin ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <Title4 withGutter>Innskráning</Title4>
          <SpacingWrapper spacing={24}>
            <form onSubmit={formik.handleSubmit}>
              <Input
                label="Netfang"
                type="email"
                name="email"
                withGutter
                value={formik.values.email}
                showError={!!formik.errors.email || !!errorMsg}
                errorMsg={formik.errors.email || errorMsg || ''}
                onChange={formik.handleChange}
              />
              <Input
                label="Lykilorð"
                type={showPassword ? 'text' : 'password'}
                name="password"
                withGutter
                value={formik.values.password}
                showError={!!formik.errors.password || !!errorMsg}
                errorMsg={formik.errors.password || errorMsg || ''}
                onChange={formik.handleChange}
                icon={
                  showPassword ? (
                    <EyeOffLineIcon
                      onClick={togglePassword}
                      style={{cursor: 'pointer'}}
                    />
                  ) : (
                    <EyeLineIcon
                      onClick={togglePassword}
                      style={{cursor: 'pointer'}}
                    />
                  )
                }
              />
              <Divider />
              <Button onClick={handleClick} type="submit">
                Skrá inn
              </Button>
            </form>
          </SpacingWrapper>
        </>
      )}
    </>
  )
}
