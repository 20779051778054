import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  {[SubKey in K]?: Maybe<T[SubKey]>}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  {[SubKey in K]: Maybe<T[SubKey]>}
const defaultOptions = {}

export const ProductBySlugDocument = gql`
  query ProductBySlug($slug: String!) {
    productBySlug(slug: $slug) @client {
      __typename
      id
      slug
      isAvailable
      hidePrice
      name
      relatedProducts {
        __typename
        id
        productId
      }
      description
      fullDescription
      sku
      variantId
      categoryName
      breadcrumbs {
        __typename
        id
        title
        href
      }
      defaultUnitType {
        __typename
        id
        name
        displayNameSingular
        displayNamePlural
        minQuantity
        quantityPerUnit
      }
      unitTypes {
        __typename
        displayNamePlural
        displayNameSingular
        id
        name
        minQuantity
        quantityPerUnit
      }
      miscTags {
        __typename
        id
        slug
        name
        thumbnail {
          __typename
          id
          url
        }
      }
      ecoCerts {
        __typename
        id
        slug
        name
        thumbnail {
          id
          url
        }
      }
      securityDocs {
        __typename
        id
        slug
        name
        url
      }
      infoDocs {
        __typename
        id
        slug
        name
        url
      }
      media {
        ... on Image {
          __typename
          type
          id
          url
        }
        ... on Video {
          __typename
          id
          type
          thumbnail {
            id
            url
          }
          youtubeID
          embedURL
        }
      }
      labels {
        id
        slug
        name
        thumbnail {
          id
          url
        }
      }
      pricing {
        id
        unitType
        gross
        grossFormatted
        net
        netFormatted
        discount
        discountFormatted
        grossOriginalPriceFormatted
        minQuantity
        quantityPerUnit
        displayNameSingular
        displayNamePlural
        netUnitPrice
        netUnitPriceFormatted
        grossUnitPrice
        grossUnitPriceFormatted
      }
      offerString
      thumbnail {
        __typename
        id
        url
      }
    }
  }
`

/**
 * __useProductBySlugQuery__
 *
 * To run a query within a React component, call `useProductBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProductBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductBySlugQuery,
    ProductBySlugQueryVariables
  >,
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<ProductBySlugQuery, ProductBySlugQueryVariables>(
    ProductBySlugDocument,
    options,
  )
}
export function useProductBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductBySlugQuery,
    ProductBySlugQueryVariables
  >,
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<ProductBySlugQuery, ProductBySlugQueryVariables>(
    ProductBySlugDocument,
    options,
  )
}
export type ProductBySlugQueryHookResult = ReturnType<
  typeof useProductBySlugQuery
>
export type ProductBySlugLazyQueryHookResult = ReturnType<
  typeof useProductBySlugLazyQuery
>
export type ProductBySlugQueryResult = Apollo.QueryResult<
  ProductBySlugQuery,
  ProductBySlugQueryVariables
>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type Breadcrumb = {
  __typename?: 'Breadcrumb'
  /** Breadcrumb href */
  href: Scalars['String']
  /** Unique identifier for the resource */
  id: Scalars['ID']
  /** Creadcrumb href */
  title: Scalars['String']
}

export type EcoCert = {
  __typename?: 'EcoCert'
  /** Unique identifier for the resource */
  id: Scalars['ID']
  /** Cert name */
  name: Scalars['String']
  /** Cert slug */
  slug: Scalars['String']
  /** Cert thumbnail */
  thumbnail: Maybe<Image>
}

export type Image = {
  __typename?: 'Image'
  /** Unique identifier for the resource */
  id: Scalars['ID']
  type: Scalars['String']
  /** Image URL */
  url: Scalars['String']
}

/** Media */
export type Media = Image | Video

export type MiscTag = {
  __typename?: 'MiscTag'
  /** Unique identifier for the resource */
  id: Scalars['ID']
  /** Misc tag name */
  name: Scalars['String']
  /** Misc tag slug */
  slug: Scalars['String']
  /** Misc tag thumbnail */
  thumbnail: Maybe<Image>
}

/** Extra discount */
export type ProductExtraDiscount = {
  __typename?: 'ProductExtraDiscount'
  discount: Maybe<Scalars['Float']>
  discountFormatted: Maybe<Scalars['String']>
  gross: Scalars['Float']
  grossFormatted: Scalars['String']
  grossUnitPrice: Scalars['Float']
  grossUnitPriceFormatted: Scalars['String']
  /** Unique identifier for the resource */
  id: Scalars['ID']
  minQuantity: Scalars['Int']
  net: Scalars['Float']
  netFormatted: Scalars['String']
  netUnitPrice: Scalars['Float']
  netUnitPriceFormatted: Scalars['String']
}

/** Price of product */
export type ProductPricing = {
  __typename?: 'ProductPricing'
  discount: Maybe<Scalars['Float']>
  discountFormatted: Maybe<Scalars['String']>
  displayNamePlural: Scalars['String']
  displayNameSingular: Scalars['String']
  extraDiscounts: Array<Maybe<ProductExtraDiscount>>
  gross: Scalars['Float']
  grossFormatted: Scalars['String']
  grossOriginalPrice: Maybe<Scalars['Float']>
  grossOriginalPriceFormatted: Maybe<Scalars['String']>
  grossUnitPrice: Scalars['Float']
  grossUnitPriceFormatted: Scalars['String']
  /** Unique identifier for the resource */
  id: Scalars['ID']
  minQuantity: Scalars['Int']
  net: Scalars['Float']
  netFormatted: Scalars['String']
  netOriginalPrice: Maybe<Scalars['Float']>
  netOriginalPriceFormatted: Maybe<Scalars['String']>
  netUnitPrice: Scalars['Float']
  netUnitPriceFormatted: Scalars['String']
  originalDiscount: Maybe<Scalars['Float']>
  quantityPerUnit: Scalars['Int']
  /** Unittype */
  unitType: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  productBySlug: Maybe<RvProduct>
}

export type Query_ProductBySlugArgs = {
  slug: Scalars['String']
}

export type RvLabel = {
  __typename?: 'RVLabel'
  /** Unique identifier for the resource */
  id: Scalars['ID']
  /** Slug */
  slug: Scalars['String']
  name: Scalars['String']
  thumbnail: Maybe<Image>
}

/** Rekstrarvorur Product */
export type RvProduct = RvProductNode & {
  __typename?: 'RVProduct'
  relatedProducts: Array<RelatedProduct>
  hidePrice: Maybe<Scalars['Boolean']>
  breadcrumbs: Array<Breadcrumb>
  labels: Array<RvLabel>
  /** Category Name */
  categoryName: Maybe<Scalars['String']>
  defaultUnitType: Maybe<UnitType>
  /** Short product description */
  description: Scalars['String']
  /** Full product description   */
  fullDescription: Scalars['String']
  ecoCerts: Array<EcoCert>
  /** Unique identifier for the resource */
  id: Scalars['ID']
  infoDocs: Array<SecurityDoc>
  /** Whether the product is in stock and visible or not */
  isAvailable: Scalars['Boolean']
  /** Products media */
  media: Array<Media>
  miscTags: Array<MiscTag>
  /** Product name */
  name: Scalars['String']
  offerString: Maybe<Scalars['String']>
  /** Product Price */
  pricing: Maybe<Array<ProductPricing>>
  securityDocs: Array<SecurityDoc>
  /** Stock Price Unit */
  sku: Maybe<Scalars['String']>
  /** Unique readable identifier for the resource */
  slug: Scalars['String']
  thumbnail: Maybe<Image>
  unitTypes: Array<UnitType>
  /** Variant Id */
  variantId: Maybe<Scalars['String']>
}

export type RvProductNode = {
  breadcrumbs: Array<Breadcrumb>
  /** Category Name */
  categoryName: Maybe<Scalars['String']>
  defaultUnitType: Maybe<UnitType>
  /** Product description */
  description: Scalars['String']
  ecoCerts: Array<EcoCert>
  /** Unique identifier for the resource */
  id: Scalars['ID']
  infoDocs: Array<SecurityDoc>
  /** Whether the product is in stock and visible or not */
  isAvailable: Scalars['Boolean']
  /** Products media */
  media: Array<Media>
  miscTags: Array<MiscTag>
  /** Product name */
  name: Scalars['String']
  offerString: Maybe<Scalars['String']>
  /** Product Price */
  pricing: Maybe<Array<ProductPricing>>
  securityDocs: Array<SecurityDoc>
  /** Stock Price Unit */
  sku: Maybe<Scalars['String']>
  /** Unique readable identifier for the resource */
  slug: Scalars['String']
  thumbnail: Maybe<Image>
  unitTypes: Array<UnitType>
  /** Variant Id */
  variantId: Maybe<Scalars['String']>
}

export type RelatedProduct = {
  __typename?: 'RelatedProduct'
  /** Unique identifier for the resource */
  id: Scalars['ID']
  productId: Scalars['String']
}

export type SecurityDoc = {
  __typename?: 'SecurityDoc'
  /** Unique identifier for the resource */
  id: Scalars['ID']
  /** Security doc name */
  name: Scalars['String']
  /** Security doc slug */
  slug: Scalars['String']
  /** Security doc thumbnail */
  thumbnail: Maybe<Image>
  /** Security doc url */
  url: Scalars['String']
}

export type UnitType = {
  __typename?: 'UnitType'
  /** Display Name Plural */
  displayNamePlural: Scalars['String']
  /** Unit Display Name singular */
  displayNameSingular: Scalars['String']
  /** Variant id */
  id: Scalars['ID']
  /** Minimum quantity */
  minQuantity: Scalars['Float']
  /** Unit type name */
  name: Scalars['String']
  /** Quantity Per Unit */
  quantityPerUnit: Scalars['Float']
}

export type Video = {
  __typename?: 'Video'
  /** Youtube Embed url */
  embedURL: Scalars['String']
  /** Unique identifier for the resource */
  id: Scalars['ID']
  /** Video thumbnail */
  thumbnail: Image
  type: Scalars['String']
  /** Youtube Video ID */
  youtubeID: Scalars['String']
}

export type ProductBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type ProductBySlugQuery = {__typename?: 'Query'} & {
  productBySlug: Maybe<
    {__typename: 'RVProduct'} & Pick<
      RvProduct,
      | 'id'
      | 'slug'
      | 'isAvailable'
      | 'hidePrice'
      | 'name'
      | 'description'
      | 'fullDescription'
      | 'sku'
      | 'variantId'
      | 'categoryName'
      | 'offerString'
    > & {
        relatedProducts: Array<
          {__typename: 'RelatedProduct'} & Pick<
            RelatedProduct,
            'id' | 'productId'
          >
        >
        breadcrumbs: Array<
          {__typename: 'Breadcrumb'} & Pick<Breadcrumb, 'id' | 'title' | 'href'>
        >
        defaultUnitType: Maybe<
          {__typename: 'UnitType'} & Pick<
            UnitType,
            | 'id'
            | 'name'
            | 'displayNameSingular'
            | 'displayNamePlural'
            | 'minQuantity'
            | 'quantityPerUnit'
          >
        >
        unitTypes: Array<
          {__typename: 'UnitType'} & Pick<
            UnitType,
            | 'displayNamePlural'
            | 'displayNameSingular'
            | 'id'
            | 'name'
            | 'minQuantity'
            | 'quantityPerUnit'
          >
        >
        miscTags: Array<
          {__typename: 'MiscTag'} & Pick<MiscTag, 'id' | 'slug' | 'name'> & {
              thumbnail: Maybe<
                {__typename: 'Image'} & Pick<Image, 'id' | 'url'>
              >
            }
        >
        ecoCerts: Array<
          {__typename: 'EcoCert'} & Pick<EcoCert, 'id' | 'slug' | 'name'> & {
              thumbnail: Maybe<
                {__typename?: 'Image'} & Pick<Image, 'id' | 'url'>
              >
            }
        >
        securityDocs: Array<
          {__typename: 'SecurityDoc'} & Pick<
            SecurityDoc,
            'id' | 'slug' | 'name' | 'url'
          >
        >
        infoDocs: Array<
          {__typename: 'SecurityDoc'} & Pick<
            SecurityDoc,
            'id' | 'slug' | 'name' | 'url'
          >
        >
        media: Array<
          | ({__typename: 'Image'} & Pick<Image, 'type' | 'id' | 'url'>)
          | ({__typename: 'Video'} & Pick<
              Video,
              'id' | 'type' | 'youtubeID' | 'embedURL'
            > & {thumbnail: {__typename?: 'Image'} & Pick<Image, 'id' | 'url'>})
        >
        labels: Array<
          {__typename?: 'RVLabel'} & Pick<RvLabel, 'id' | 'slug' | 'name'> & {
              thumbnail: Maybe<
                {__typename?: 'Image'} & Pick<Image, 'id' | 'url'>
              >
            }
        >
        pricing: Maybe<
          Array<
            {__typename?: 'ProductPricing'} & Pick<
              ProductPricing,
              | 'id'
              | 'unitType'
              | 'gross'
              | 'grossFormatted'
              | 'net'
              | 'netFormatted'
              | 'discount'
              | 'discountFormatted'
              | 'grossOriginalPriceFormatted'
              | 'minQuantity'
              | 'quantityPerUnit'
              | 'displayNameSingular'
              | 'displayNamePlural'
              | 'netUnitPrice'
              | 'netUnitPriceFormatted'
              | 'grossUnitPrice'
              | 'grossUnitPriceFormatted'
            >
          >
        >
        thumbnail: Maybe<{__typename: 'Image'} & Pick<Image, 'id' | 'url'>>
      }
  >
}
