import React from 'react'
import type {FormikProps} from 'formik'
import {withFormik} from 'formik'

import type {EmailChangeRequest} from 'src/api/rest'
import {validateEmail} from 'src/utils/validation'
import {emailChangeErrorCodeStrings} from 'src/constants'
import {Button} from '../buttons'
import {FormikInput} from '../form'
import {H5, P} from '../typography'

interface Props {
  id: number
  changeUserEmail: (id: number, data: EmailChangeRequest) => Promise<void>
}

interface FormValues {
  email: string
}

interface ErrorProps {
  email?: string
}

const Form = ({
  handleSubmit,
  isSubmitting,
}: FormikProps<FormValues>): JSX.Element => (
  <form onSubmit={handleSubmit}>
    <H5 withGutter>Hvað er netfangið þitt?</H5>
    <P withGutter>
      Netfangið er notað til að senda upplýsingar um pöntun. Við munum ekki nota
      það til að senda þér fjölpóst.
    </P>
    <div style={{marginBottom: '38px'}}>
      <FormikInput name="email" type="email" label="Netfang" />
    </div>

    <Button type="submit" withGutter loading={isSubmitting}>
      Áfram
    </Button>
  </form>
)

const EmailForm = withFormik<Props, FormValues>({
  mapPropsToValues: () => ({
    email: '',
  }),

  validate: ({email}) => {
    const errors: ErrorProps = {}

    if (!email) {
      errors.email = 'Hér vantar netfang'
    } else if (!validateEmail(email)) {
      errors.email = 'Netfangið er ekki á réttu formi'
    }
    return errors
  },

  handleSubmit: (
    values,
    {props: {id, changeUserEmail}, setFieldError, setSubmitting},
  ) => {
    changeUserEmail(id, values)
      .then(() => setSubmitting(false))
      .catch((err) => {
        const error = emailChangeErrorCodeStrings[err?.error?.code]
        setFieldError('email', error || 'Óþekkt villa, reynið aftur síðar')
        setSubmitting(false)
      })
  },

  displayName: 'EmailForm',
})(Form)

export default EmailForm
