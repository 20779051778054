/* TODO: Fix those links */
/* eslint-disable @next/next/no-html-link-for-pages */
import React, {useMemo} from 'react'
import Link from 'next/link'
import {Col, Container, media, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import PhoneFillIcon from 'remixicon-react/PhoneFillIcon'
import MapPinFillIcon from 'remixicon-react/MapPinFillIcon'
import MailFillIcon from 'remixicon-react/MailFillIcon'
import TimeFillIcon from 'remixicon-react/TimeFillIcon'

import {usePageContext} from 'src/context/PageContext'
import {theme} from 'src/themes'
import {formatPhoneNumber} from 'src/utils/strings'
import {H6, PSmall, Title5} from '../typography'
import shadowStyles from '../../styles/shadowStyles'
import {SpacingBottom} from './SpacingWrapper'

const FooterContainer = styled.div`
  padding: 32px 0;
  ${media.xl`
    max-width: ${theme.headerAndFooterSize.xLargeDesktop};
    margin: auto;
    margin-top: 16px;
  `}
`

const FooterWrapper = styled.div`
  line-height: 1.5;
  margin: 16px 12px 0 21px;

  ${shadowStyles({
    color: 'red',
    outlined: false,
    length: 8,
    showOnHover: false,
  })}
`

const MainInfo = styled.div`
  background-color: ${palette('white')};
  border: 1px solid ${palette('red')};
  border-bottom: 4px solid ${palette('red')};
  padding: 20px 30px;
  @media (max-width: ${theme.screenSize.sizeMD}) {
    padding: 10px;
  }
`

const Details = styled.div`
  background-color: ${palette('white')};
  border: 1px solid ${palette('red')};
  padding: 40px 30px;
  > div:first-child {
    justify-content: space-between;
  }
  ${media.md`
  padding: 60px 20px 24px;
  `}
`

const FooterSection = styled.div`
  display: flex;
  justify-content: flex-start;

  ${media.sm`
    justify-content: center;
  `}

  ${Title5} {
    line-height: 1.5;
  }

  ${media.sm`
    ${Title5} {
      white-space: nowrap;
    }
  `}
`

const FooterSectionInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0;
  ${media.md`
  padding: 16px;
  `}
`

const PageLink = styled.a`
  margin-bottom: 10px;
  transition: color 0.15s;
  cursor: pointer;

  &:hover {
    color: ${palette('red')};
  }
`

const FooterTitle = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${H6} {
    margin-bottom: 4px;
  }
  svg {
    margin-right: 8px;
    margin-bottom: 2px;
    height: 20px;
    color: ${palette('red')};
  }
`

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;

  ${media.sm`
    max-width: 170px;
    margin:auto;
  `}
`

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: baseline;
  text-align: center;
  gap: 10px;
  width: 100%;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  ${media.sm`
    flex-direction: row;
    justify-content: center;
  `}
`
const TermLinks = styled(PSmall)`
  display: flex;
  column-gap: 10px;
`

const Copyright = styled.span`
  margin-right: 5px;
  color: ${palette('black20')};
`
const LogoWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: baseline;
  width: 100%;
  @media (max-width: ${theme.screenSize.sizeSM}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 40px;
  }
`
const AwardLogo = styled.img`
  max-height: 120px;

  ${media.lg`
    max-height: 180px;
  `}
`

export default function Footer(): JSX.Element {
  const {footerData, getPagesByCategory} = usePageContext()
  const {
    email,
    phoneNumber,
    location,
    openingHours,
    pageCategories,
    awards,
  } = footerData
  const featureFlag = useMemo(
    () => ({
      showMenu: true,
      privacyPolicy: true,
      showDisclaimers: false,
    }),
    [],
  )
  return (
    <FooterContainer>
      <FooterWrapper>
        <MainInfo>
          <Container>
            <Row>
              <Col xs={12} sm={4}>
                <FooterSection>
                  <FooterSectionInner>
                    <FooterTitle>
                      <PhoneFillIcon size={18} />
                      <H6>Þjónustuver</H6>
                    </FooterTitle>

                    {phoneNumber ? (
                      <a href={`tel:+354${phoneNumber}`}>
                        <Title5>{formatPhoneNumber(phoneNumber)}</Title5>
                      </a>
                    ) : null}
                  </FooterSectionInner>
                </FooterSection>
              </Col>
              <Col xs={12} sm={4}>
                <FooterSection>
                  <FooterSectionInner>
                    <FooterTitle>
                      <MapPinFillIcon size={18} />
                      <H6>Staðsetning</H6>
                    </FooterTitle>
                    <a
                      href={`http://maps.google.com/?q=Rekstrarvorur ${location}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Title5>{location}</Title5>
                    </a>
                  </FooterSectionInner>
                </FooterSection>
              </Col>
              <Col xs={12} sm={4}>
                <FooterSection>
                  <FooterSectionInner>
                    <FooterTitle>
                      <MailFillIcon size={18} />
                      <H6>Netfang</H6>
                    </FooterTitle>
                    <Title5>
                      <a href={`mailto:${email}`}>{email}</a>
                    </Title5>
                  </FooterSectionInner>
                </FooterSection>
              </Col>
            </Row>
          </Container>
        </MainInfo>
        {featureFlag.showMenu ? (
          <Details>
            <Row>
              <Col xs={12} sm={6} md={3}>
                <DetailsSection>
                  {openingHours?.map((openingHour) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <SpacingBottom spacing={30} key={openingHour.id}>
                      <FooterTitle>
                        <TimeFillIcon size={14} />
                        <H6>{openingHour.name}</H6>
                      </FooterTitle>
                      <Title5>{openingHour.openingHours}</Title5>
                    </SpacingBottom>
                  ))}
                </DetailsSection>
              </Col>
              {pageCategories?.map((category) => {
                const pages = getPagesByCategory(category.id)
                return (
                  <Col xs={12} sm={6} md={3} key={category.id}>
                    <DetailsSection>
                      {pages?.map((page) =>
                        page.path ? (
                          <Link href={page.path} key={page.id} passHref>
                            <PageLink>{page.heading}</PageLink>
                          </Link>
                        ) : null,
                      )}
                    </DetailsSection>
                  </Col>
                )
              })}
              <Col xs={12} sm={6} md={3}>
                <LogoWrapper>
                  {awards?.map((award) => (
                    <AwardLogo
                      key={award.id}
                      src={award.url}
                      alt={award?.alt || 'Award 2022'}
                    />
                  ))}
                </LogoWrapper>
              </Col>
            </Row>
            <Row>
              <Col>
                <FooterBottom>
                  <PSmall color="black40">
                    <Copyright>©2022 Rekstrarvörur</Copyright>
                  </PSmall>
                  <TermLinks color="black40">
                    {featureFlag.privacyPolicy ? (
                      <a href="/stefnur">Persónuverndarstefna</a>
                    ) : null}
                    {featureFlag.privacyPolicy && featureFlag.showDisclaimers
                      ? '|'
                      : null}
                    {featureFlag.showDisclaimers ? (
                      <a href="/">Skilmálar</a>
                    ) : null}
                  </TermLinks>
                </FooterBottom>
              </Col>
            </Row>
          </Details>
        ) : null}
      </FooterWrapper>
    </FooterContainer>
  )
}
