import {EcoCertImage} from './EcoCertImage'
import {LabelWrapper, CertTitle} from './styles'

export const EcoCert = ({
  title,
  imageSrc,
  slug,
}: {
  title: string
  slug: string
  imageSrc: string | null
}): JSX.Element | null => (
  <LabelWrapper>
    <EcoCertImage imageSrc={imageSrc} alt={title} slug={slug} />
    <CertTitle>{title}</CertTitle>
  </LabelWrapper>
)
