import {useCallback, useEffect, useState} from 'react'
import {isSSR} from 'src/utils/isSSR'
import {getScrollbarWidth} from 'src/utils/getScrollbarWidth'
import {useScrollTop} from './useScrollTop'

const isShowingScrollbar = (): boolean =>
  isSSR ? false : document.documentElement.scrollHeight > window.innerHeight

export const useIsScrollbarActive = (): {
  active: boolean
  width: number
  top: number
} => {
  const [isShowing, setIsShowing] = useState<boolean>(isShowingScrollbar())
  const [width, setWidth] = useState(getScrollbarWidth())
  const top = useScrollTop()
  const updateFn = useCallback(() => {
    if (isShowingScrollbar() !== isShowing) {
      setIsShowing(!isShowing)
      setWidth(getScrollbarWidth())
    }
  }, [isShowing])
  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateFn)
    if (typeof resizeObserver !== 'undefined' && resizeObserver) {
      resizeObserver.observe(document.body)
    }
    return () => {
      if (typeof resizeObserver !== 'undefined' && resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [updateFn])

  return {
    active: isShowing,
    width,
    top,
  }
}
