import type {RvTheme} from 'src/themes/theme'
import type {FlattenInterpolation, ThemeProps} from 'styled-components'
import {css} from 'styled-components'
import {palette} from 'styled-tools'

interface Props {
  color?: 'red' | 'black'
}

export default function scrollStyle({
  color = 'black',
}: Props): FlattenInterpolation<ThemeProps<RvTheme>> {
  return css`
    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${palette(`${color}10`)};
      border: 1px solid ${palette(color)};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${palette(color)};
    }

    &:focus {
      outline: none;
    }
  `
}
