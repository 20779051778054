import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useRouter} from 'next/router'

import styled from 'styled-components'
import {ifProp, palette, prop} from 'styled-tools'
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'

import type {NavCategoryFragment} from 'src/api/saleor/generated'
import {useNavigationCategoriesQuery} from 'src/api/saleor/generated'
import type {
  Webpage_header_links as HeaderLinks,
  Webpage_header_links_PageCategoryRecord as PageCategory,
} from 'src/api/datocms/types/Webpage'
import {paths, generatePath} from 'src/utils/paths'
import {usePageContext} from 'src/context/PageContext'
import {useWindowSize} from 'react-use'
import {Link, Image, Expandable} from '../../common'
import {NavBarLink} from './Header'
import theme from '../../../themes/theme'
import {H4, Title7} from '../../typography'
import scrollStyle from '../../../styles/scrollStyle'
import {SubCategories} from './SubCategories'
import PageCategoryLinks from './PageCategoryLinks'

const NavDropwDownContainer = styled.div``

export const DropdownWrapper = styled.div`
  background-color: ${palette('white')};
  display: flex;
  position: relative;
  flex-direction: row;
  border: 1px solid ${palette('red')};
  max-height: calc(100vh - 135px);
  overflow: hidden;

  ${Title7} {
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  @media (min-width: ${theme.screenSize.sizeMD}) {
    max-height: calc(100vh - 180px);
  }
`

interface CategorySectionProps {
  show?: boolean
}

const CategorySection = styled.div<CategorySectionProps>`
  display: flex;
  flex-direction: column;
  background-color: ${palette('white')};
  padding-left: 15px;
  border-right: 1px solid ${palette('red')};
  flex-shrink: 0;
  transition: transform 0.25s ease-in;
  overflow: auto;
  width: 100%;

  @media (min-width: 768px) {
    width: 350px;
  }
`

const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  transition: transform 0.25s ease-in;
  padding: 0 20px;

  a {
    justify-content: flex-start;
    font-weight: 600;
  }

  @media (min-width: ${theme.screenSize.sizeMD}) {
    display: none;
  }
`

const SubCategorySection = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  @media (min-width: ${theme.screenSize.sizeMD}) {
    // display: block;
    width: fit-content;
    column-count: 1;
    column-fill: auto;
    height: 100%;
  }

  ${scrollStyle({color: 'red'})}
`
const CatCoverImage = styled.div`
  height: 100%;
  margin: auto;
  padding-right: 20px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  justify-item: center;
  align-items: center;
  a {
    border: 1px solid #93939391;
    padding: 10px 5px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  a div {
    height: 150px;
    width: 150px;
  }

  @media screen and (max-width: 1700px) {
    a div {
      height: 100px;
      width: 120px;
    }
  }

  @media screen and (max-width: 1600px) {
    a div {
      height: 100px;
      width: 85px;
    }
  }
  @media screen and (max-width: 1400px) {
    a div {
      height: 100px;
      width: 75px;
    }
  }
`
interface CategoryProps {
  selected?: boolean
}

const Category = styled.span<CategoryProps>`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  svg {
    margin-right: 10px;
    width: ${ifProp('selected', '30px', '0')};
    visibility: ${ifProp('selected', 'visible', 'hidden')};
    transition: width 0.2s ease-in-out;
  }

  &:hover {
    cursor: pointer;

    svg {
      visibility: visible;
      width: 30px;
    }
  }
`

const ProductInfo = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
`

interface ShowProps {
  show: boolean
}

const MobileNavigation = styled.div<ShowProps>`
  display: ${ifProp({show: true}, 'flex', 'none')};
  align-items: center;
  padding: 8px;
  border-top: 1px solid ${palette('red')};
  @media (min-width: ${theme.screenSize.sizeMD}) {
    display: none;
  }
`

interface SliderProps {
  level: number
}

const MobileSlider = styled.div<SliderProps>`
  display: flex;
  transition: transform 0.25s ease-in;
  transform: translateX(-${prop('level')}00%);
  width: 100%;

  @media (min-width: ${theme.screenSize.sizeMD}) {
    transform: none;
    width: 100%;
  }
`

const BackLink = styled.span`
  padding-right: 10px;
`

const Sku = styled.p`
  color: gray;
  margin-bottom: 0px;
  font-size: 12px;

  @media screen and (max-width: 1600px) {
    font-size: 8px;
    margin-bottom: 0px;
    font-size: 10px;
  }
`

const ProductName = styled.p`
  font-size: 15px;
  line-height: 20px;
  @media screen and (max-width: 1600px) {
    font-size: 10px;
  }
`

const Section = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${Title7} {
    cursor: pointer;
    &:hover {
      color: ${palette('red')};
    }
  }

  ${scrollStyle({color: 'red'})};
`

const hideImg = 1300

interface Props {
  show: boolean
  close: () => void
  links: HeaderLinks[]
}

export default function NavDropdown({show, close, links}: Props): JSX.Element {
  const {width} = useWindowSize()
  const {navigationInfo} = usePageContext()

  const [categoryMap, setCategoryMap] = useState<
    Record<string, NavCategoryFragment>
  >()
  const [categories, setCategories] = useState<string[]>()
  const [navImages, setNavImages] = useState<
    Record<
      string,
      Array<{
        slug: string
        image_url: string
        id: string
        name: string
        sku: string
      }>
    >
  >()

  useEffect(() => {
    const nav_images_map: Record<
      string,
      Array<{
        slug: string
        image_url: string
        id: string
        name: string
        sku: string
      }>
    > = {}
    const catArr: string[] | undefined = navigationInfo?.navBlocks?.map(
      // eslint-disable-next-line no-return-assign
      (block) => {
        nav_images_map[block.slug] = block.productlist
          ? JSON.parse(block.productlist as string)
          : []
        return block.slug
      },
    )
    setNavImages(nav_images_map)
    setCategories(catArr)
  }, [navigationInfo])

  const {data: categoriesData} = useNavigationCategoriesQuery()

  useEffect(() => {
    const categories_map: Record<string, NavCategoryFragment> = {}
    categoriesData?.categories?.edges?.forEach((cat) => {
      const {node} = cat
      categories_map[node.slug] = node
    })
    setCategoryMap(categories_map)
  }, [categoriesData])

  const [
    selectedCategory,
    setSelectedCategory,
  ] = useState<NavCategoryFragment | null>(null)
  const [
    selectedPageCategory,
    setSelectedPageCategory,
  ] = useState<PageCategory>()
  const [level, setLevel] = useState<number>(0)
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [showDropdown, setShowDropdown] = useState<boolean | undefined>()
  const router = useRouter()

  useEffect(() => {
    setShowDropdown(show)
  }, [show])
  const prev = useCallback(() => {
    setLevel(level - 1)
    setSelectedPageCategory(undefined)
  }, [setLevel, setSelectedPageCategory, level])

  const next = useCallback(() => {
    setLevel(level + 1)
  }, [setLevel, level])

  const selectPageCategoryMobile = useCallback(
    (category: PageCategory) => {
      next()
      setSelectedPageCategory(category)
    },
    [next, setSelectedPageCategory],
  )

  const selectCategory = useCallback(() => {
    close()
    setLevel(2)
  }, [close])

  // eslint-disable-next-line unused-imports/no-unused-vars
  const hoverCategory = useCallback(
    (category: NavCategoryFragment) => {
      setSelectedCategory(category)
    },
    [setSelectedCategory],
  )

  useEffect(() => {
    if (router) {
      router.events.on('routeChangeStart', close)
    }
  }, [router, close])

  const isMobile = useMemo(() => {
    const minScreenWidth = theme.screenSize.sizeMD.slice(0, -2)
    return width < Number(minScreenWidth)
  }, [width])

  return (
    <NavDropwDownContainer>
      <Expandable expanded={show}>
        <MobileNavigation show={level !== 0}>
          <BackLink onClick={() => prev()}>
            <ArrowLeftLineIcon color={theme.palette.red} />
          </BackLink>
          {level === 1 && (
            <H4>
              {!selectedPageCategory
                ? 'Vefverslun'
                : selectedPageCategory?.name}
            </H4>
          )}
          {level === 2 && <H4>{selectedCategory?.name}</H4>}
        </MobileNavigation>
        {show && (
          <DropdownWrapper>
            <MobileSlider level={level}>
              <LinksSection>
                <Section>
                  <NavBarLink onClick={() => next()}>Vefverslun</NavBarLink>
                  {links.map((link) => {
                    if (link.__typename === 'PageRecord') {
                      if (!link.path) {
                        return null
                      }
                      return (
                        <Link href={link.path} key={link.id}>
                          <NavBarLink>{link.heading}</NavBarLink>
                        </Link>
                      )
                    }
                    return (
                      <NavBarLink
                        key={link.id}
                        onClick={() => selectPageCategoryMobile(link)}
                      >
                        {link.name}
                      </NavBarLink>
                    )
                  })}
                </Section>
              </LinksSection>
              {/* TODO: Add section for tags */}
              {/* <TagSection>New in</TagSection> */}

              <CategorySection>
                <Section>
                  {!selectedPageCategory && (
                    <Link href={paths.categories}>
                      <Title7 left={width < 768 ? 0 : 10} bottom={30} top={10}>
                        Allir vöruflokkar
                      </Title7>
                    </Link>
                  )}
                  {(!isMobile || !selectedPageCategory) &&
                  categoryMap &&
                  categories?.length
                    ? categories.map(
                        (category) =>
                          categoryMap[category] && (
                            <div key={categoryMap[category].id}>
                              <Link
                                href={generatePath(
                                  paths.categories,
                                  categoryMap[category].slug,
                                  'landingPage=true',
                                )}
                                shallow
                              >
                                {width < 768 ? (
                                  <Category
                                    key={categoryMap[category].id}
                                    selected={
                                      selectedCategory === categoryMap[category]
                                    }
                                    onClick={() => selectCategory()}
                                  >
                                    <Title7>
                                      {categoryMap[category].name}
                                    </Title7>
                                  </Category>
                                ) : (
                                  <Category
                                    key={categoryMap[category].id}
                                    selected={
                                      selectedCategory === categoryMap[category]
                                    }
                                    onClick={() => selectCategory()}
                                    onMouseOver={() =>
                                      hoverCategory(categoryMap[category])
                                    }
                                  >
                                    <ArrowRightLineIcon
                                      color={theme.palette.red}
                                    />
                                    <Title7>
                                      {categoryMap[category].name}
                                    </Title7>
                                  </Category>
                                )}
                              </Link>
                            </div>
                          ),
                      )
                    : selectedPageCategory && (
                        <PageCategoryLinks category={selectedPageCategory} />
                      )}
                </Section>
              </CategorySection>

              <SubCategorySection>
                {selectedCategory ? (
                  <SubCategories category={selectedCategory} />
                ) : null}
              </SubCategorySection>
            </MobileSlider>

            {width > hideImg && (
              <>
                {selectedCategory &&
                  navImages &&
                  navImages[selectedCategory?.slug]?.length && (
                    <CatCoverImage>
                      {navImages[selectedCategory?.slug].map((item) => (
                        <a href={`/vara/${item.slug}`}>
                          <Image
                            height={width > 1350 ? 200 : 150}
                            width={width > 1350 ? 200 : 150}
                            objectFit="contain"
                            layout="fill"
                            src={item.image_url}
                            alt={selectedCategory?.name}
                            useImg
                          />
                          <ProductInfo>
                            <Sku>{item.sku}</Sku>
                            <ProductName>{item.name}</ProductName>
                          </ProductInfo>
                        </a>
                      ))}
                    </CatCoverImage>
                  )}
              </>
            )}
          </DropdownWrapper>
        )}
      </Expandable>
    </NavDropwDownContainer>
  )
}
