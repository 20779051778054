import {isTouchDevice} from 'src/utils/isTouch'
import styled from 'styled-components'

export const FavoriteButton = styled.div`
  opacity: ${isTouchDevice ? 1 : 0};
  transition: opacity 0.15s;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  &.selected {
    opacity: 1;
  }
`
