import Image from 'next/image'
import {MiniImage, MiniSlide, Wrapper} from './styles'
import type {RVVideo} from './interfaces'

export const ProductMediaYoutubeImage = ({
  value,
  isSelected,
}: {
  value: RVVideo
  isSelected: boolean
}): JSX.Element => (
  <Wrapper>
    <MiniSlide selected={isSelected}>
      <MiniImage>
        <Image
          alt=""
          src={value.thumbnail.url}
          layout="fill"
          objectFit="contain"
          objectPosition="top"
          unoptimized
        />
      </MiniImage>
    </MiniSlide>
  </Wrapper>
)
