import React, {useCallback} from 'react'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import cn from 'classnames'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import BuildingLineIcon from 'remixicon-react/Building4LineIcon'
import LogoutBoxLineIcon from 'remixicon-react/LogoutBoxLineIcon'

import {theme} from 'src/themes'
import scrollStyle from 'src/styles/scrollStyle'
import {media} from 'styled-bootstrap-grid'
import {useRouter} from 'next/router'
import {toast} from 'react-toastify'
import {paths} from '../../utils/paths'
import shadowStyles from '../../styles/shadowStyles'
import {useUser} from '../../context/UserContext'
import Link from '../common/Link'
import {PSmall} from '../typography'
import {useCurrentPath} from './hooks'

interface Props {
  setShowUserDropdown: React.Dispatch<React.SetStateAction<boolean>>
  hideDesktop?: boolean
}

export const DropdownWrapper = styled.div`
  position: absolute;
  right: 0;
  min-width: 230px;
  transition: opacity 0.15s;
  z-index: 9999;
  max-height: 60vh;
  ${scrollStyle({color: 'red'})}
`

export const Dropdown = styled.div`
  background-color: ${palette('white')};
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette('red')};
  padding-bottom: 20px;
  ${shadowStyles({
    color: 'red',
    outlined: false,
    length: 8,
    showOnHover: false,
  })}
`

const CustomerDropdown = styled.div`
  margin-bottom: 8px;
`

const CustomerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 4px 10px 20px;

  &.selected {
    background-color: ${palette('red10')};
    font-weight: 700;

    .remixicon-icon {
      fill: ${palette('primary')};
    }
  }
`

const Customer = styled.div`
  cursor: pointer;
  font-size: 0.75rem;
  padding-left: 10px;

  &:hover {
    color: ${palette('primary')};
  }
`

const LineDivider = styled.div`
  margin: 0;
  border-top: 1px solid ${palette('red20')};
`

const UserMenuLink = styled(Link)`
  padding: 10px 20px;

  &:hover {
    color: ${palette('primary')};
  }

  &.hideDesktop {
    display: block;
  }

  ${media.md`

    &.hideDesktop {
      display: none;
    }

  `}
`

const SignOutWrapper = styled.button`
  padding: 10px 20px 0 20px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  .remixicon-icon {
    margin-right: 6px;
  }

  &:hover {
    color: ${palette('primary')};
  }
`

export default function AccountNavDropdown({
  setShowUserDropdown,
  hideDesktop = true,
}: Props): JSX.Element {
  const router = useRouter()
  const currentPath = useCurrentPath()
  const isDeliveryRoute = currentPath === paths.delivery
  const isPaymentRoute = currentPath === paths.payment

  const routeCheck = useCallback((): void => {
    if (isDeliveryRoute || isPaymentRoute) {
      router.push(paths.checkout)
      toast.warning('Vinsamlegast uppfærið móttakanda og greiðanda.')
    }
  }, [isDeliveryRoute, isPaymentRoute, router])

  const {
    user,
    companyId,
    setCompanyId,
    removeCompanyId,
    userCompanies,
    signOut,
  } = useUser()

  const handleCompanyClick = useCallback(
    (id: number): void => {
      setCompanyId(`${id}`)
      routeCheck()
      setShowUserDropdown(false)
    },
    [routeCheck, setCompanyId, setShowUserDropdown],
  )

  const handleUserClick = useCallback((): void => {
    removeCompanyId()
    routeCheck()
    setShowUserDropdown(false)
  }, [removeCompanyId, routeCheck, setShowUserDropdown])

  const handleUserMenuLinkClick = useCallback((): void => {
    setShowUserDropdown(false)
  }, [setShowUserDropdown])

  const handleSignOut = useCallback((): void => {
    setShowUserDropdown(false)
    signOut()
  }, [signOut, setShowUserDropdown])

  return (
    <DropdownWrapper>
      <Dropdown>
        {!!userCompanies?.length && (
          <CustomerDropdown>
            <CustomerWrapper
              onClick={handleUserClick}
              className={cn({selected: !companyId})}
            >
              <UserLineIcon color={theme.palette.black90} size={18} />
              <Customer>{user?.name}</Customer>
            </CustomerWrapper>
            {userCompanies?.map((company) => (
              <div key={company.id}>
                <LineDivider />
                <CustomerWrapper
                  onClick={() => handleCompanyClick(company.id)}
                  className={cn({selected: companyId === `${company.id}`})}
                >
                  <BuildingLineIcon color={theme.palette.black90} size={16} />
                  <Customer>{company.name}</Customer>
                </CustomerWrapper>
                <LineDivider />
              </div>
            ))}
          </CustomerDropdown>
        )}
        <UserMenuLink onClick={handleUserMenuLinkClick} href={paths.myInfo}>
          Mínar upplýsingar
        </UserMenuLink>
        <UserMenuLink onClick={handleUserMenuLinkClick} href={paths.myOrders}>
          Reikningar
        </UserMenuLink>
        <UserMenuLink
          onClick={handleUserMenuLinkClick}
          href={paths.salesHistory}
        >
          Sölusaga
        </UserMenuLink>
        <UserMenuLink
          onClick={handleUserMenuLinkClick}
          href={paths.myTransactions}
        >
          Hreyfingayfirlit
        </UserMenuLink>
        <UserMenuLink
          onClick={handleUserMenuLinkClick}
          href={paths.companyRequest}
        >
          Tengjast fyrirtæki
        </UserMenuLink>
        <UserMenuLink
          onClick={handleUserMenuLinkClick}
          href={paths.shoppingList}
          className={cn({hideDesktop})}
        >
          Óskalistar
        </UserMenuLink>
        <SignOutWrapper onClick={handleSignOut}>
          <LogoutBoxLineIcon size={16} color={theme.palette.primary} />
          <PSmall>Útskráning</PSmall>
        </SignOutWrapper>
      </Dropdown>
    </DropdownWrapper>
  )
}
