import {useState, useLayoutEffect} from 'react'

import {isSSR} from 'src/utils/isSSR'

export const useScrollTop = (): number => {
  const [scrollTop, setScrollTop] = useState<number>(isSSR ? 0 : window.scrollY)
  useLayoutEffect(() => {
    const fn = (): void => {
      setScrollTop(window.scrollY)
    }
    window.addEventListener('scroll', fn)
    fn()
    return () => {
      window.removeEventListener('scroll', fn)
    }
  }, [])
  return scrollTop
}
