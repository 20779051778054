import styled from 'styled-components'

export const BackgroundImageCover = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const BackgroundImageContain = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
