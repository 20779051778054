import {ProductTagsWrapper} from '..'
import {ProductTags} from './components'
import {useProductCardContext} from './context'

export const CardProductTags = (): JSX.Element => {
  const {product} = useProductCardContext()
  return (
    <ProductTags>
      <ProductTagsWrapper
        labels={product.labels}
        isAvailable={product.isAvailable}
      />
    </ProductTags>
  )
}
