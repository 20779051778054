import SaleorRichTextContent from '../../../content/SaleorRichTextContent'
import {useProductTabContext} from '../ProductTabContext'
import {MiscInfoTags} from './MiscInfoTags/MiscInfoTags'

export const ProductDescription = (): JSX.Element => {
  const {product} = useProductTabContext()
  return (
    <>
      <SaleorRichTextContent jsonString={product.fullDescription} />
      <MiscInfoTags />
    </>
  )
}
