import {useState, useEffect, useRef} from 'react'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import {useWindowSize} from 'react-use'
import Image from 'next/image'
import {H4} from 'src/components/typography/Headings'
import {PSmall} from 'src/components/typography/P'
import {
  PopUpMiniSlide,
  MediaPopUpContainer,
  MiniImage,
  CloseButton,
  SlideBox,
} from './styles'
import {ProductMediaVideo} from './ProductMediaVideo'
import type {RVMedia, RVMedias} from './interfaces'
import {typeIsVideo} from './interfaces'

export const ProductMediaPopUp = ({value}: {value: RVMedia}): JSX.Element => {
  const {width} = useWindowSize()
  if (typeIsVideo(value)) {
    return (
      <div className="popup-media">
        {width <= 600 ? (
          <ProductMediaVideo
            value={value}
            height="300px"
            styledProps={{marginTop: '20px'}}
          />
        ) : (
          <ProductMediaVideo value={value} width="75%" />
        )}
      </div>
    )
  }
  const {url} = value
  return (
    <div className="popup-image">
      <Image
        alt=""
        src={url}
        objectFit="contain"
        layout="fill"
        objectPosition="center"
      />
    </div>
  )
}

export const PopUpSlide = ({
  value,
  isSelected,
  index,
  toggleIndex,
}: {
  value: RVMedia
  isSelected: boolean
  index: number
  toggleIndex: (index: number) => void
}): JSX.Element => {
  if (typeIsVideo(value)) {
    return (
      <PopUpMiniSlide
        className="popup-slide"
        selected={isSelected}
        onClick={() => {
          toggleIndex(index)
        }}
      >
        <MiniImage>
          <Image
            alt=""
            src={value.thumbnail.url}
            layout="fill"
            objectFit="contain"
            objectPosition="top"
            unoptimized
          />
        </MiniImage>
      </PopUpMiniSlide>
    )
  }
  const {url} = value
  return (
    <div>
      <PopUpMiniSlide
        selected={isSelected}
        onClick={() => {
          toggleIndex(index)
        }}
      >
        <Image
          alt=""
          src={url}
          layout="fill"
          objectFit="contain"
          objectPosition="center"
        />
      </PopUpMiniSlide>
    </div>
  )
}

export const MediaPopUp = ({
  prodMedia,
  proName,
  proSku,
  currentSlide,
  toggleMediaPopUp,
}: {
  prodMedia: RVMedias
  proName: string
  proSku: string | null
  currentSlide: number
  toggleMediaPopUp: (value: boolean) => void
}): JSX.Element => {
  const [slide, setCurrentSlide] = useState<number>(0)
  const popUpRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setCurrentSlide(currentSlide)
  }, [currentSlide])

  const handleClose = (): void => {
    const popUpEle = document.getElementById('popup-backdrop')
    if (popUpEle?.style) {
      popUpEle.style.display = 'none'
      document.body.style.overflow = 'scroll'
    }
    toggleMediaPopUp(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      if (
        popUpRef.current &&
        !popUpRef?.current?.contains(event.target as Node | null)
      ) {
        handleClose()
      }
    })
  })

  const toggleIndex = (index: number): void => setCurrentSlide(index)

  return (
    <MediaPopUpContainer className="pop-up" ref={popUpRef}>
      <div className="popup-wrapper">
        <CloseButton onClick={handleClose}>
          <CloseLineIcon size={14} />
        </CloseButton>
        <ProductMediaPopUp value={prodMedia[slide]} />
        <div className="slide-box">
          <PSmall color="black60" className="sku-text">
            {proSku}
          </PSmall>
          <H4>{proName}</H4>
          <SlideBox>
            {prodMedia.map((value, index) => (
              <PopUpSlide
                toggleIndex={toggleIndex}
                key={value.id}
                index={index}
                value={value}
                isSelected={index === slide}
              />
            ))}
          </SlideBox>
        </div>
      </div>
    </MediaPopUpContainer>
  )
}
