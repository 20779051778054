import React from 'react'
import Head from 'next/head'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {useOverlay} from 'src/context/OverlayContext'
import {useUser} from 'src/context/UserContext'
import {theme} from 'src/themes'
import SideDrawer from '../common/SideDrawer'
import LoginPage from '../account/LoginPage'
import Footer from './Footer'
import {Header} from '.'
import {SearchBar} from '../search'

const Page = styled.div`
  position: relative;
  background-color: ${palette('background')};
  z-index: 0;
`

const PageContentSection = styled.main`
  min-height: 80vh;
  overflow: hidden;
  padding-top: 175px;
  @media (max-width: ${theme.screenSize.sizeLG}) {
    padding-top: 100px;
  }
`
const Backdrop = styled.div`
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
`
interface Props {
  title?: string
  children: React.ReactNode
}

const SearchBarContainer = styled.div`
  display: block;
  border-width: 2px;
  border: 1px solid ${palette('red')};
  margin: 0 15px 12px 15px;
  @media (min-width: ${theme.screenSize.sizeMD}) {
    display: none;
  }
`

export default function PageContainer({title, children}: Props): JSX.Element {
  const {setSideDrawerIsOpen, sideDrawerIsOpen} = useOverlay()
  const {
    user,
    userLoading,
    missingEmail,
    showChooseCustomer,
    userCompanies,
    userCompaniesLoading,
    signOut: handleSignOut,
    showRegistrationMsg,
  } = useUser()

  const inEmailStep = user && missingEmail
  const isOpenIf =
    (sideDrawerIsOpen &&
      (!user ||
        userLoading ||
        showChooseCustomer ||
        (user && userCompaniesLoading) ||
        (user &&
          !missingEmail &&
          !userCompanies?.length &&
          !userCompaniesLoading &&
          !showRegistrationMsg) ||
        showRegistrationMsg)) ||
    (user && missingEmail)

  return (
    <Page>
      <Head>
        <title>{title || 'Rekstrarvörur'}</title>
      </Head>
      <>
        <SideDrawer
          setIsOpen={setSideDrawerIsOpen}
          isOpen={!!isOpenIf}
          inEmailStep={!!inEmailStep}
          handleSignOut={handleSignOut}
        >
          <LoginPage />
        </SideDrawer>
      </>
      <Backdrop id="popup-backdrop" />
      <Header />
      <PageContentSection>
        <SearchBarContainer>
          <SearchBar border showDropdown />
        </SearchBarContainer>
        {children}
      </PageContentSection>

      <Footer />
    </Page>
  )
}
