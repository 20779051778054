import React, {useCallback, useMemo} from 'react'
import {Col, Row} from 'styled-bootstrap-grid'
import SubtractLineIcon from 'remixicon-react/SubtractLineIcon'

import {theme} from 'src/themes'
import styled from 'styled-components'
import {
  ShoppingListCard,
  CreateNewShoppingList,
} from 'src/components/shoppingLists'
import {useShoppingList} from '../../context/ShoppingListContext'
import Modal from '../common/Modal'
import {UnderlineButton} from '../buttons'
import {PSmall} from '../typography'
import {DeleteConfirmation} from '../common'

const SubTitleWrapper = styled.div`
  margin-top: -10px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`

export default function ShoppingListsPage(): JSX.Element {
  const {
    shoppingLists,
    deleteModalIsOpen,
    setDeleteModalIsOpen,
    deleteShoppingLists,
    createModalIsOpen,
    setCreateModalIsOpen,
  } = useShoppingList()

  const handleShoppingLists = useMemo(
    () => (
      <>
        {shoppingLists?.map((list) => (
          <Col key={list.id} sm={4} md={3} lg={2}>
            <ShoppingListCard
              title={list.name}
              src={
                list.items[0]?.productVariant?.firstImage?.image?.productList ||
                '/assets/no-image.svg'
              }
              href={`/oskalistar/${list.id}`}
              description={
                list.items.length === 1
                  ? `${list.items.length} vara`
                  : `${list.items.length} vörur`
              }
              listId={list.id}
              linkCard
              emptyList={!list.items.length}
            />
          </Col>
        ))}
      </>
    ),
    [shoppingLists],
  )

  const handleDeleteAll = useCallback(() => {
    const promiseArray = shoppingLists?.map(({id}) => deleteShoppingLists(id))

    Promise.all(promiseArray)
      .then(() => {
        if (!setDeleteModalIsOpen) {
          return
        }
        setDeleteModalIsOpen(false)
      })
      .catch((err) => {
        // TODO: handle error
        // eslint-disable-next-line no-console
        console.error(err)
      })
  }, [deleteShoppingLists, setDeleteModalIsOpen, shoppingLists])
  const handleSetDeleteModalIsOpen = useCallback(
    (value: boolean) => {
      if (setDeleteModalIsOpen) {
        setDeleteModalIsOpen(value)
      }
    },
    [setDeleteModalIsOpen],
  )
  const handleDeleteModalFalse = useCallback(() => {
    if (setDeleteModalIsOpen) {
      setDeleteModalIsOpen(false)
    }
  }, [setDeleteModalIsOpen])

  const handleDeleteClick = useCallback(() => {
    if (!setDeleteModalIsOpen) {
      return
    }
    setDeleteModalIsOpen(true)
  }, [setDeleteModalIsOpen])
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <SubTitleWrapper>
                {shoppingLists && (
                  <>
                    {shoppingLists.length === 1 ? (
                      <PSmall>{shoppingLists.length} listi</PSmall>
                    ) : (
                      <PSmall>{shoppingLists.length} listar</PSmall>
                    )}
                  </>
                )}
                <UnderlineButton
                  icon={<SubtractLineIcon color={theme.palette.primary} />}
                  text="Eyða öllum óskalistum"
                  onClick={handleDeleteClick}
                />
              </SubTitleWrapper>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            {handleShoppingLists}
            <Col md={3} lg={2}>
              <ShoppingListCard
                title="Búa til nýjan óskalista"
                onClickCreate={() => setCreateModalIsOpen(true)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal setShow={setCreateModalIsOpen} show={createModalIsOpen}>
        <CreateNewShoppingList />
      </Modal>
      <Modal setShow={handleSetDeleteModalIsOpen} show={!!deleteModalIsOpen}>
        <DeleteConfirmation
          title="Ertu viss um að þú viljir eyða öllum óskalistunum þínum?"
          text="Þú munt ekki geta tekið þessa aðgerð til baka."
          onCancel={handleDeleteModalFalse}
          onRemove={handleDeleteAll}
          cancelText="Hætta við"
          submitText="Eyða öllu"
        />
      </Modal>
    </>
  )
}
