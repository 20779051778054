import React from 'react'
import InfoCard from 'src/components/account/InfoCard'
import BankCardLineIcon from 'remixicon-react/BankCard2LineIcon'
import {P, Title7} from 'src/components/typography'
import {theme} from 'src/themes'
import {useUser} from '../../context/UserContext'

export default function PaymentCardsInfo(): JSX.Element {
  const {valitorCards} = useUser()

  const handlePaymentCards = (): JSX.Element => (
    <>
      {valitorCards?.map((value) => (
        <InfoCard
          key={value.id}
          icon={<BankCardLineIcon size={30} color={theme.palette.primary} />}
          title=""
          description={
            <>
              <P>
                {value.brand} - {value.maskedCardNumber?.slice(-4)}
              </P>
              <P>
                Gildistími {value.expMonth}/{value.expYear}
              </P>
            </>
          }
        />
      ))}
    </>
  )
  return (
    <>
      <div>
        {!!valitorCards?.length && <Title7 withGutter>Veski</Title7>}
        {valitorCards?.length ? handlePaymentCards() : null}
        {/* <UnderlineButton
          icon={<AddLineIcon color={theme.palette.primary} />}
          text="Nýtt greiðslukort"
          onClick={handlePaymentCard}
        /> */}
      </div>
    </>
  )
}
