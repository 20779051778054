import React, {useRef, useState, useEffect} from 'react'
import {useClickAway} from 'react-use'
import styled, {css} from 'styled-components'
import {Container, media} from 'styled-bootstrap-grid'
import {palette, theme as styledTheme} from 'styled-tools'

import _Menu4LineIcon from 'remixicon-react/Menu4LineIcon'
import PhoneFillIcon from 'remixicon-react/PhoneFillIcon'
import MailFillIcon from 'remixicon-react/MailFillIcon'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'

import {usePageContext} from 'src/context/PageContext'
import {gridTheme} from 'src/themes'
import {isNotNull} from 'src/utils/isNotNull'
import {useScrollTop} from 'src/hooks/useScrollTop'
import Link from '../../common/Link'
import {headingsCommonStyle} from '../../typography/Headings'
import NavDropdown from './NavDropdown'
import shadowStyles from '../../../styles/shadowStyles'
import HeaderCart from '../../cart/HeaderCart'
import {AccountNav} from '../../account/AccountNav'
import {SearchBar} from '../../search'
import {paths} from '../../../utils/paths'
import theme from '../../../themes/theme'
import NavDatoCategoryLink from './NavPageDropdown'

interface HeaderContainerprops {
  scroll: boolean
}

const Menu4LineIcon = styled(_Menu4LineIcon)`
  margin-top: 1px;
`
const HeaderContainer = styled.div<HeaderContainerprops>`
  position: sticky;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: ${styledTheme('zIndex.header')};
  ${media.xl`
    max-width: ${theme.headerAndFooterSize.xLargeDesktop};
    margin: auto;
  `}

  @media screen and (max-width:786px) {
    top: ${(props) => (props.scroll ? '-100%' : '0')};
    transition: all 0.3s;
  }
`
const HeaderInnerContainer = styled.div`
  position: absolute;
  width: 100%;
`

export const NavBarWrapper = styled.div`
  max-width: 100%;
  margin: 16px 12px 0 21px;
  border: 1px solid ${palette('red')};
  height: 100%;
  background-color: ${palette('white')};
  ${shadowStyles({
    color: 'red',
    outlined: false,
    length: 8,
    showOnHover: false,
  })}

  ${media.lg`
    margin-top: 0px;
  `}
`

export const NavBrandLogo = styled.img`
  width: 28px;
  margin-top: -8px;
  margin-right: 8px;

  ${media.lg`
    width: 53px;
    margin-top: -43px;
  `}
`

export const NavBrand = styled(Link)`
  display: flex;
  align-items: center;
  padding-left: 0;
  cursor: pointer;

  ${media.lg`
    padding-right: 16px;
  `}
`

export const NavBarContentWrapper = styled.div`
  position: relative;
  align-items: end;
  margin-top: -1px;
`

const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 45px;

  @media (max-width: ${theme.screenSize.sizeMD}) {
    padding-left: 30px;
    padding-right: 14px;
  }

  ${media.lg`
    height: 80px;
  `}
`

export const NavBarLinkWrapper = styled.div`
  display: none;
  justify-content: flex-start;
  width: 100%;
  border-top: 1px solid ${palette('black10')};
  padding: 0 45px;
  ${media.md`
  display: flex;
  justify-content:space-between;
  `}
  ${media.lg`
  display: flex;
  justify-content:flex-start;
  `}
`

interface LinkTextProps {
  text?: string
}

export const NavBarLink = styled.div<LinkTextProps>`
  display: inline-block;
  margin: 5px 40px 5px 0;
  font-size: 1rem;
  color: inherit;
  cursor: pointer;
  transition: color 0.15s;
  line-height: 1.8;
  font-weight: 400;
  ${media.md`
    margin-right: 0px;
  `}
  ${media.lg`
    margin-right: 50px;
  `}

  &:hover {
    color: ${palette('red')};
  }

  &::before {
    display: block;
    content: '${(props) => props.text}';
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`

export const NavBarFunctions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  @media (max-width: ${gridTheme.breakpoints.lg - 1}px) {
    width: 100%;
    margin-left: auto;
  }
`

export const iconLinkStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  transition: transform 0.15s;
  padding: 8px;

  &:hover {
    color: ${palette('primary')};
  }
`

export const NavBarIconLink = styled.div`
  ${iconLinkStyles}
`

const MenuIconLink = styled.div`
  ${iconLinkStyles}

  ${media.md`
    display: none;
  `}
`

const CartIconLink = styled(Link)`
  ${iconLinkStyles}
`

const NavBrandText = styled.div`
  font-size: 1.5rem;
  ${headingsCommonStyle}
`

const SearchWrapper = styled.div`
  background-color: ${palette('background')};
  box-shadow: inset 0px -3px 0px #e8e8e8;
`

const Info = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${palette('background')};
`

const InfoItem = styled.div`
  display: none;
  font-size: 0.875rem;
  padding: 10px;

  svg {
    margin-right: 10px;
  }

  ${media.lg`
    display: flex;
  `}
`

const InfoWrapper = styled.div`
  width: 100%;
  background-color: ${palette('background')};

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    height: 60px;
    background-color: ${palette('background')};
    z-index: -1;
  }
`

export default function Header(): JSX.Element {
  const {
    headerData,
    currentOpenDropdown,
    setOpenDropdown,
    closeDropdown,
  } = usePageContext()

  const [scroll, setScroll] = useState<boolean>(false)
  const [scrollValue, setScrollValue] = useState<number>(0)
  const scrollTop = useScrollTop()

  useEffect(() => {
    if (scrollValue < scrollTop) {
      setScroll(true)
    } else {
      setScroll(false)
    }
    setScrollValue(scrollTop)
  }, [scrollTop, scrollValue])

  const dropdownId = 'main'
  const showDropdown = currentOpenDropdown === dropdownId
  const menuRef = useRef(null)
  const {links} = headerData

  function toggleDropdown(): void {
    if (showDropdown) {
      closeDropdown()
    } else {
      setOpenDropdown(dropdownId)
    }
  }

  useClickAway(menuRef, () => {
    closeDropdown()
  })
  return (
    <HeaderContainer className="header-container" scroll={scroll}>
      <HeaderInnerContainer>
        <InfoWrapper>
          <Container>
            <Info>
              <InfoItem>
                <PhoneFillIcon size={14} />
                <a href="tel:+520 6666">520 6666</a>
              </InfoItem>
              <InfoItem>
                <MailFillIcon size={14} />
                <a href="mailto:sala@rv.is">sala@rv.is</a>
              </InfoItem>
            </Info>
          </Container>
        </InfoWrapper>
        <NavBarWrapper ref={menuRef} className="navbar-wrapper">
          <NavBar>
            <NavBrand href={paths.home}>
              <NavBrandLogo src="/assets/rv-logo-solid.png" />
              <NavBrandText>Rekstrarvörur</NavBrandText>
            </NavBrand>
            <SearchWrapper>
              <SearchBar />
            </SearchWrapper>
            <NavBarContentWrapper>
              <NavBarFunctions>
                <AccountNav />
                <CartIconLink href={paths.cart}>
                  <HeaderCart />
                </CartIconLink>
                <MenuIconLink onClick={toggleDropdown}>
                  {!showDropdown && (
                    <Menu4LineIcon color={theme.palette.red} size={28} />
                  )}
                  {showDropdown && (
                    <CloseLineIcon color={theme.palette.red} size={28} />
                  )}
                </MenuIconLink>
              </NavBarFunctions>
            </NavBarContentWrapper>
          </NavBar>
          <NavBarLinkWrapper>
            <NavBarLink
              onClick={toggleDropdown}
              style={{
                fontWeight: showDropdown ? 700 : 400,
              }}
              text="Vefverslun"
            >
              Vefverslun
            </NavBarLink>
            {links.filter(isNotNull).map((link) => {
              if (link.__typename === 'PageRecord') {
                if (!link.path || !link.id || !link.heading) {
                  return null
                }
                return (
                  <Link
                    href={link.path}
                    key={link.id}
                    shallow={link.path.startsWith(paths.categories)}
                  >
                    <NavBarLink text={link.heading}>{link.heading}</NavBarLink>
                  </Link>
                )
              }
              return <NavDatoCategoryLink key={link.id} category={link} />
            })}
          </NavBarLinkWrapper>
          <NavDropdown
            show={showDropdown}
            close={closeDropdown}
            links={links}
          />
        </NavBarWrapper>
      </HeaderInnerContainer>
    </HeaderContainer>
  )
}
