import {useMemo} from 'react'
import {ProductCardContainer} from './components'
import {ProductCardContext} from './context'
import {CardProductTags} from './CardProductCardTags'
import {CardWrapper} from './CardWrapper'
import {CardProductUser} from './CardProductUser'
import {CardTitle} from './CardTitle'
import {CardShop} from './CardShop'
import {CardProductModal} from './CardProductModel'
import type {ProductCardProps} from './interfaces'
import {useProductValue} from './hooks/useProductValue'
import {CardPopOver} from './CardPopOver'

export const ProductCard = ({
  render,
  slug,
  selectedVariant,
  selectedType,
  injected,
  shoppingList,
  shoppingListProductId,
  shallow,
}: ProductCardProps): JSX.Element | null => {
  const value = useProductValue({
    slug,
    selectedType,
    injected,
    selectedVariant,
    shoppingList,
    shoppingListProductId,
    shallow,
  })

  const components = useMemo(() => {
    const child = (
      <ProductCardContext.Provider value={value}>
        <ProductCardContainer>
          <CardProductTags />
          <CardWrapper>
            <CardProductUser />
            <CardTitle />
            <CardShop />
            <CardPopOver />
          </CardWrapper>
        </ProductCardContainer>
        <CardProductModal />
      </ProductCardContext.Provider>
    )
    if (render) {
      return render(child)
    }
    return child
  }, [value, render])
  if (value == null) {
    // TODO: Add loader
    return null
  }
  return components
}
