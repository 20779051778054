import React from 'react'
import {gridTheme} from 'src/themes'
import styled from 'styled-components'
import {prop} from 'styled-tools'
import {FluidSpacing} from './SpacingWrapper'

interface BlockProps {
  customWidth: number
}

const BlockContainer = styled.div<BlockProps>`
  max-width: ${prop('customWidth')}px;
  margin: auto;
  img {
    max-width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    height: auto !important;
    object-fit: contain;
  }
`
interface Props {
  children: React.ReactNode
  backgroundColor?: string
  customWidth?: number
}

export default function BlockWrapper({
  backgroundColor,
  children,
  customWidth = gridTheme.breakpoints.xl,
}: Props): JSX.Element {
  return (
    <FluidSpacing from={30} to={30} style={{backgroundColor}}>
      <BlockContainer customWidth={customWidth}>{children}</BlockContainer>
    </FluidSpacing>
  )
}
