import {Modal} from 'src/components/common'
import {
  AddItemToShoppingList,
  DeleteShoppingListItem,
} from 'src/components/shoppingLists'
import {useProductCardContext} from './context'

export const CardProductModal = (): JSX.Element | null => {
  const {
    product,
    setShowModal,
    showModal,
    itemInShoppingList,
    shoppingListProductId,
    shoppingListId,
    shoppingList,
  } = useProductCardContext()
  if (shoppingList) {
    if (!shoppingListProductId || !shoppingListId) {
      return null
    }
    return (
      <Modal setShow={setShowModal} show={showModal}>
        <DeleteShoppingListItem
          id={shoppingListProductId}
          listId={shoppingListId}
          setShowModal={setShowModal}
        />
      </Modal>
    )
  }
  if (!product.variantId) {
    return null
  }
  return (
    <Modal setShow={setShowModal} show={showModal}>
      <AddItemToShoppingList
        title={product.name}
        image={product.thumbnail?.url}
        id={product.variantId}
        itemInShoppingList={itemInShoppingList}
      />
    </Modal>
  )
}
