import React from 'react'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {Link} from 'src/components/common'
import type {NavCategoryFragment} from 'src/api/saleor/generated'
import {generatePath, paths} from 'src/utils/paths'

const SubCategory = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  line-height: 20px;
  &:hover {
    cursor: pointer;
    color: ${palette('red')};
  }
`
const SubCategoryText = styled.div`
  padding: 5px 0;
`

interface SubCategoriesProps {
  category: NavCategoryFragment
}

export function SubCategories({category}: SubCategoriesProps): JSX.Element {
  return (
    <>
      {category?.children?.edges.map((subCategory) => (
        <Link
          href={generatePath(paths.categories, subCategory.node.slug)}
          key={subCategory.node.id}
        >
          <SubCategory>
            <SubCategoryText>{subCategory.node.name}</SubCategoryText>
          </SubCategory>
        </Link>
      ))}
    </>
  )
}
