import React, {useEffect} from 'react'
import type {FormikProps} from 'formik'
import {withFormik} from 'formik'

import {restApi} from 'src/api/rest'
import {isValidCompanySsn} from 'src/utils/strings'
import {toast} from 'react-toastify'
import {useUser} from 'src/context/UserContext'
import {Button} from '../buttons'
import {FormikInput} from '../form'
import {H5, P} from '../typography'

interface FormValues {
  companyName: string
  companySsn: string
  message?: string
}

interface ErrorProps {
  companyName?: string
  companySsn?: string
}

const COMPLETED_STATUS = 'CompletedWow'

const Form = ({
  handleSubmit,
  status,
  isSubmitting,
}: FormikProps<FormValues>): JSX.Element => {
  const {setShowRegistrationMsg} = useUser()

  useEffect(() => {
    if (status === COMPLETED_STATUS) {
      setShowRegistrationMsg(true)
    }
  }, [setShowRegistrationMsg, status])

  return (
    <form onSubmit={handleSubmit}>
      <H5 withGutter>Má bjóða þér að tengjast fyrirtæki?</H5>
      <P withGutter>Hér getur þú óskað eftir aðgangi að fyrirtæki.</P>
      <div style={{marginBottom: '32px'}}>
        <FormikInput name="companyName" type="text" label="Nafn fyrirtækis*" />
      </div>
      <div style={{marginBottom: '32px'}}>
        <FormikInput
          name="companySsn"
          type="text"
          label="Kennitala fyrirtækis*"
        />
      </div>
      <div style={{marginBottom: '32px'}}>
        <FormikInput
          name="message"
          type="textarea"
          label="Skilaboð - deild eða staður sem versla á fyrir eða aðrar upplýsingar"
        />
      </div>

      <Button type="submit" withGutter loading={isSubmitting}>
        Senda
      </Button>
    </form>
  )
}

const CompanyUserForm = withFormik<ErrorProps, FormValues>({
  mapPropsToValues: () => ({
    companyName: '',
    companySsn: '',
    message: '',
  }),

  validate: ({companyName, companySsn}) => {
    const errors: ErrorProps = {}

    if (!companyName) {
      errors.companyName = 'Hér vantar nafn'
    }
    if (!companySsn) {
      errors.companySsn = 'Hér vantar kennitölu'
    } else if (!isValidCompanySsn(companySsn)) {
      errors.companySsn = 'Ógild fyrirtækja kennitala'
    }
    return errors
  },

  handleSubmit: (values, {resetForm, setSubmitting, setStatus}) =>
    restApi
      .companyUserRequestsCreate(values)
      .then((res) => {
        if (res.statusText === 'Created') {
          // toast.success('Beiðni hefur verið stofnuð!')
          setStatus(COMPLETED_STATUS)
        }
        setSubmitting(false)
        resetForm()
      })
      .catch(() => {
        toast.error('Óþekkt villa, reynið aftur síðar')
        setSubmitting(false)
        return false
      }),
  displayName: 'CompanyUserForm',
})(Form)

export default CompanyUserForm
