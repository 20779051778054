import React, {useCallback} from 'react'
import {useFormik} from 'formik'
import {Col, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import type {AddressInput, Address} from 'src/api/saleor/generated'
import {CountryCode} from 'src/api/saleor/generated'
import {Title5} from 'src/components/typography'
import {isValidSsn, phoneRegExp} from 'src/utils/strings'
import {Input} from '../form/Input'
import Button from '../buttons/Button'
import {useUser} from '../../context/UserContext'
import {CleaveInput} from '../form'

export const Form = styled.form`
  text-align: start;
`
export const FormContainer = styled.div`
  width: 100%;
`
export const FormWrapper = styled.div`
  padding-top: 25px;
  width: 100%;
`
export const TitleWrapper = styled.div`
  text-align: center;
`

export const ButtonWrapper = styled.div`
  padding-bottom: 0px;
`

interface Props {
  setShow?: (value: boolean) => void
  handleAddAddress?: (address: Address) => void
  formTitle?: string
}
export interface AddressProps {
  id?: string
  firstName?: string
  lastName?: string
  phone?: string
  streetAddress1?: string
  city?: string
  postalCode?: string
}
interface AddressFormErrorProps {
  firstName?: string
  lastName?: string
  phone?: string
  streetAddress1?: string
  city?: string
  postalCode?: string
}

export default function AddressForm({
  id,
  firstName,
  lastName,
  phone,
  streetAddress1,
  city,
  postalCode,
  setShow,
  handleAddAddress,
  formTitle,
}: AddressProps & Props): JSX.Element {
  const {createAddress, updateAddress, deleteAddress} = useUser()

  function validateAddressFormFields(
    values: AddressInput,
  ): AddressFormErrorProps {
    const errors: AddressFormErrorProps = {}

    if (!values.lastName) {
      errors.lastName = 'Hér vantar kennitölu'
    } else if (!isValidSsn(values.lastName)) {
      errors.lastName = 'Ógild kennitala'
    }
    if (!values.firstName) {
      errors.firstName = 'Hér vantar nafn'
    }
    if (!values.streetAddress1) {
      errors.streetAddress1 = 'Hér vantar heimilisfang'
    }
    if (!values.city) {
      errors.city = 'Hér vantar stað'
    }
    if (!values.postalCode) {
      errors.postalCode = 'Hér vantar póstnúmer'
    }
    if (!values.phone) {
      errors.phone = 'Hér vantar símanúmer'
    } else if (!phoneRegExp.test(values.phone)) {
      errors.phone = 'Símanúmer þarf að vera 7 tölustafir'
    }
    return errors
  }

  const onComplete = (address: Address): void => {
    if (setShow) {
      setShow(false)
    }
    if (handleAddAddress) {
      handleAddAddress(address)
    }
  }

  const code: CountryCode = CountryCode.Is
  const initialValues: AddressInput = {
    firstName,
    lastName,
    phone,
    streetAddress1,
    city,
    postalCode,
    country: code,
  }

  const formik = useFormik({
    initialValues,
    validateOnChange: false,

    validate: (values) => validateAddressFormFields(values),
    onSubmit: (values, {resetForm}) => {
      if (!id) {
        createAddress(values).then((res: Address | void | null | undefined) => {
          if (
            (res && formTitle === 'Móttakandi') ||
            (res && formTitle === 'Greiðandi')
          ) {
            onComplete(res)
          } else if (setShow) {
            setShow(false)
          }
        })
      } else {
        updateAddress(id, values)
        if (setShow) {
          setShow(false)
        }
      }
      resetForm()
    },
  })

  const handleDelete = useCallback(() => {
    if (id) {
      deleteAddress(id)
    }
    if (setShow) {
      setShow(false)
    }
  }, [deleteAddress, id, setShow])

  return (
    <FormContainer>
      <TitleWrapper>
        <Title5>{formTitle}</Title5>
      </TitleWrapper>
      <FormWrapper>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={6}>
              <CleaveInput
                label="Kennitala"
                name="lastName"
                type="tel"
                onChange={formik.handleChange}
                withGutter
                value={lastName}
                showError={!!formik.errors.lastName}
                errorMsg={formik.errors.lastName}
                options={{
                  numericOnly: true,
                  delimiters: ['-'],
                  blocks: [6, 4],
                }}
              />
            </Col>
            <Col md={6}>
              <Input
                label="Nafn"
                name="firstName"
                type="text"
                onChange={formik.handleChange}
                withGutter
                defaultValue={firstName}
                showError={!!formik.errors.firstName}
                errorMsg={formik.errors.firstName}
              />
            </Col>
            <Col md={6}>
              <CleaveInput
                label="Símanúmer"
                name="phone"
                type="tel"
                onChange={formik.handleChange}
                withGutter
                value={phone}
                showError={!!formik.errors.phone}
                errorMsg={formik.errors.phone}
                options={{
                  numericOnly: true,
                  delimiters: [' '],
                  blocks: [3, 4],
                }}
              />
            </Col>

            <Col md={6}>
              <Input
                label="Heimilisfang"
                name="streetAddress1"
                type="text"
                onChange={formik.handleChange}
                withGutter
                defaultValue={streetAddress1}
                showError={!!formik.errors.streetAddress1}
                errorMsg={formik.errors.streetAddress1}
              />
            </Col>
            <Col md={6}>
              <CleaveInput
                label="Póstnúmer"
                name="postalCode"
                type="tel"
                onChange={formik.handleChange}
                withGutter
                value={postalCode}
                showError={!!formik.errors.postalCode}
                errorMsg={formik.errors.postalCode}
                options={{
                  numericOnly: true,
                  blocks: [3],
                }}
              />
            </Col>
            <Col md={6}>
              <Input
                label="Staður"
                name="city"
                type="text"
                onChange={formik.handleChange}
                withGutter
                defaultValue={city}
                showError={!!formik.errors.city}
                errorMsg={formik.errors.city}
              />
            </Col>
            <Col md={6}>
              {id && (
                <Button fill withGutter outlined onClick={handleDelete}>
                  Eyða
                </Button>
              )}
            </Col>
            <Col md={6}>
              <Button fill withGutter type="submit">
                Vista
              </Button>
            </Col>
          </Row>
        </Form>
      </FormWrapper>
    </FormContainer>
  )
}
