import React, {useState, useRef} from 'react'
import {useClickAway} from 'react-use'
import UserLineIcon from 'remixicon-react/User3LineIcon'
import HeartLineIcon from 'remixicon-react/Heart3LineIcon'
import BuildingLineIcon from 'remixicon-react/Building4LineIcon'
import styled, {css} from 'styled-components'
import {palette} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'

import {splitName} from 'src/utils/strings'
import {paths} from '../../utils/paths'
import Link from '../common/Link'
import theme from '../../themes/theme'
import {AccountNavDropdown} from '.'
import {useUser} from '../../context/UserContext'
import {useOverlay} from '../../context/OverlayContext'

const NavBarIconText = styled.span`
  display: none;
  margin: 0 15px 0 10px;
  font-size: 0.875rem;

  ${media.md`
    display: flex;
  `}

  @media screen and (max-width:1400px) {
    display: none;
  }
`
export const iconLinkStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  transition: transform 0.15s;
  padding: 8px;

  &:hover {
    color: ${palette('primary')};
  }
`

const ShoppingListLink = styled(Link)`
  ${iconLinkStyles}
  display: none;

  ${media.md`
    display: flex;
  `}
`

const UserIconButton = styled.div`
  ${iconLinkStyles}
`

const UserWrapper = styled.div`
  position: relative;
`

const UserIconWrapper = styled.div`
  ${iconLinkStyles}
`

export const AccountNav = (): JSX.Element | null => {
  const {user, currentCompany, showLogin} = useUser()
  const {setSideDrawerIsOpen} = useOverlay()
  const [showUserDropdown, setShowUserDropdown] = useState(false)
  const userMenuRef = useRef(null)

  const handleClick = (): void => {
    setSideDrawerIsOpen(true)
  }

  const toggleDropdown = (): void => {
    setShowUserDropdown(!showUserDropdown)
  }

  useClickAway(userMenuRef, () => {
    setShowUserDropdown(false)
  })
  if (!showLogin) {
    return null
  }
  return (
    <>
      {user ? (
        <>
          <ShoppingListLink href={paths.shoppingList}>
            <HeartLineIcon color={theme.palette.red} size={28} />
            <NavBarIconText>Óskalistar</NavBarIconText>
          </ShoppingListLink>
          <UserWrapper ref={userMenuRef}>
            <UserIconWrapper onClick={toggleDropdown}>
              {currentCompany ? (
                <>
                  <BuildingLineIcon color={theme.palette.red} size={26} />
                  <NavBarIconText>{currentCompany.name}</NavBarIconText>
                </>
              ) : (
                <>
                  <UserLineIcon color={theme.palette.red} size={28} />
                  <NavBarIconText>{splitName(user.name)?.[0]}</NavBarIconText>
                </>
              )}
            </UserIconWrapper>
            {showUserDropdown && (
              <AccountNavDropdown setShowUserDropdown={setShowUserDropdown} />
            )}
          </UserWrapper>
        </>
      ) : (
        <UserIconButton onClick={handleClick}>
          <UserLineIcon color={theme.palette.red} size={28} />
          <NavBarIconText>Innskráning</NavBarIconText>
        </UserIconButton>
      )}
    </>
  )
}
