/* eslint-disable unused-imports/no-unused-vars */
import React, {useEffect, useCallback, useMemo, useRef} from 'react'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {motion} from 'framer-motion'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import LogoutBoxLineIcon from 'remixicon-react/LogoutBoxLineIcon'

import {theme} from 'src/themes'
import {useTrap} from 'src/hooks/useTrap'
import {useIsScrollbarActive} from 'src/hooks/useIsScrollbarActive'
import {useUser} from 'src/context/UserContext'
import {CornerButton} from '../buttons'
import {PSmall} from '../typography'

interface Props {
  isOpen?: boolean
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  children?: React.ReactNode
  inEmailStep?: boolean
  handleSignOut: () => void
}

const DrawerWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 700;
  pointer-events: none;
`

const DrawerOverlay = styled(motion.aside)<{isClosed: boolean}>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: ${({isClosed}) =>
    isClosed ? 'transparent' : 'rgba(0, 0, 0, 0.1)'};
  transition: background-color 300ms;
`

const DrawerContent = styled(motion.div)<{isClosed: boolean; top: number}>`
  -webkit-overflow-scrolling: 'touch';
  background-color: rgba(250, f 250, 252, 0.9);
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  min-height: 100%;
  backdrop-filter: blur(2px);
  box-shadow: -8px 2px 150px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -8px 2px 150px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -8px 2px 150px 1px rgba(0, 0, 0, 0.75);
  z-index: 1;
  max-width: 100%;
  padding-top: ${({top}) => top}px;
  pointer-events: ${({isClosed}) => (isClosed ? 'none' : 'all')};
  background-color: rgba(250, 250, 252, 0.9);
  overflow-y: auto;
`

const DrawerInnerContent = styled.div`
  width: 500px;
  max-width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
`

const ContentContainer = styled(motion.div)`
  padding: 100px 22px;

  ${media.md`
    padding: 100px;
  `}
`

const SignOutWrapper = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 14px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  .remixicon-icon {
    margin-right: 6px;
  }
`

const contentVariants = {
  initial: {
    x: 0,
    opacity: 0,
  },
  active: {
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 0.25,
      ease: [0.42, 0, 0.58, 1],
    },
  },
}

export default function SideDrawer({
  isOpen,
  setIsOpen,
  children,
  inEmailStep,
  handleSignOut,
}: Props): JSX.Element {
  const {stopResetUser, resetSelectAccount} = useUser()
  const {
    active: scrollIsActive,
    width: scrollWidth,
    top: scrollTop,
  } = useIsScrollbarActive()
  const handleEnd = useCallback(() => {
    const nextEl = document.querySelector('#__next') as HTMLDivElement
    nextEl.style.transform = ''
    nextEl.style.width = ''
  }, [])
  const handleStart = useCallback(() => {
    const nextEl = document.querySelector('#__next') as HTMLDivElement
    const width = scrollIsActive ? scrollWidth : 0
    nextEl.style.marginLeft = 'calc(100vw - 100%)'
    nextEl.style.transform = `translateX(-${width}px)`
    nextEl.style.width = `calc(100vw + ${width}px)`
  }, [scrollIsActive, scrollWidth])

  // useLockBodyScroll({enable: !!isOpen, handleEnd, handleStart})
  const onKeyDown = useCallback(
    ({key}) => {
      if (!isOpen || !setIsOpen) {
        return
      }
      if (key === 'Escape') {
        setIsOpen(false)
      }
    },
    [setIsOpen, isOpen],
  )

  const handleClose = useCallback(() => {
    if (!setIsOpen) {
      return
    }
    setIsOpen(false)
    stopResetUser()
    resetSelectAccount()
  }, [resetSelectAccount, setIsOpen, stopResetUser])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', onKeyDown)
      return () => window.removeEventListener('keydown', onKeyDown)
    }
    return () => {
      // Empty on purpose
    }
  }, [onKeyDown])

  const shouldClose = useCallback(
    ({target}: React.BaseSyntheticEvent): void => {
      if (target?.id === 'drawer-overlay' && !!setIsOpen) {
        setIsOpen(false)
      }
    },
    [setIsOpen],
  )
  const ref = useRef<HTMLDivElement>(null)
  useTrap({enabled: !!isOpen, ref})

  const animate = useMemo(() => {
    const transition = {
      type: 'spring',
      damping: 40,
      stiffness: 400,
      delayChildren: 0.5,
      when: 'beforeChildren',
    }
    if (isOpen) {
      return {
        width: '500px',
        transition,
      }
    }
    return {
      width: 0,
      transition,
    }
  }, [isOpen])
  useEffect(() => {
    const nextEl = document.querySelector('#__next') as HTMLDivElement
    if (isOpen) {
      const width = scrollIsActive ? scrollWidth : 0
      // nextEl.style.transform = `translateX(-${width / 2}px)`
      nextEl.style.width = `calc(100vw + ${width}px)`
    } else {
      requestAnimationFrame(() => {
        nextEl.style.transform = ''
        nextEl.style.width = ''
      })
    }
  }, [isOpen, scrollIsActive, scrollWidth])

  return (
    <DrawerWrapper>
      <>
        <DrawerOverlay
          onClick={shouldClose}
          initial={false}
          isClosed={!isOpen}
        />
        <DrawerContent
          animate={animate}
          role="dialog"
          aria-modal
          isClosed={!isOpen}
          hidden={!isOpen}
          top={scrollTop}
        >
          <DrawerInnerContent ref={ref}>
            {isOpen && inEmailStep ? (
              <SignOutWrapper onClick={handleSignOut}>
                <LogoutBoxLineIcon size={24} color={theme.palette.primary} />
                <PSmall>Útskráning</PSmall>
              </SignOutWrapper>
            ) : null}

            {isOpen && !inEmailStep ? (
              <CornerButton
                icon={<CloseLineIcon size={16} />}
                onClick={handleClose}
              />
            ) : null}

            <ContentContainer
              variants={contentVariants}
              initial="initial"
              animate="active"
              exit="active"
            >
              {children}
            </ContentContainer>
          </DrawerInnerContent>
        </DrawerContent>
      </>
    </DrawerWrapper>
  )
}
