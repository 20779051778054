import React from 'react'
import {Col, Row} from 'styled-bootstrap-grid'

import {H5} from 'src/components/typography'

import {SpacingWrapper} from '../layout'
import UserInfo from './UserInfo'
import PaymentCardsInfo from './PaymentCardsInfo'
import AddressInfo from './AddressInfo'

export default function UserOverview(): JSX.Element {
  return (
    <Row>
      <Col sm={5} smOffset={1}>
        <H5 withGutter>Mínar Upplýsingar</H5>

        <SpacingWrapper spacing={24}>
          <UserInfo />
        </SpacingWrapper>
        <SpacingWrapper spacing={24}>
          <AddressInfo />
        </SpacingWrapper>
        <SpacingWrapper spacing={24}>
          <PaymentCardsInfo />
        </SpacingWrapper>
      </Col>
      {/* <Col sm={5} smOffset={1}>
        <H5 withGutter>Tengdir fyrirtækjaaðgangar</H5>
        <SpacingWrapper spacing={24}>
          <CompanyInfo />
        </SpacingWrapper>
      </Col> */}
    </Row>
  )
}
