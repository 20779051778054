import {useCallback, useContext, useMemo} from 'react'
import {CartButton} from 'src/components/buttons'
import {P} from 'src/components/typography'
import styled from 'styled-components'
import {
  useCheckout,
  useIsCheckoutLoading,
  useIsInitialLoad,
} from 'src/context/CheckoutContext'
import {getPluralOrSingular} from 'src/utils/icelandicQuantity'
import {useProductCartPickerContext} from './context'
import QtyPicker from '../../form/QtyPicker'
import {ProductCardContext} from '../ProductCard/context'

const QtyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  ${P} {
    margin-left: 16px;
  }
`

export const ProductCartAdd = (): JSX.Element | null => {
  const {
    currentQuantity,
    setCurrentQuantity,
    qtyInCart,
    type,
    product,
  } = useProductCartPickerContext()
  const isAvailable = product
  const productContext = useContext(ProductCardContext)

  const {cartUpdate} = useCheckout()
  const cartIsUpdating = useIsCheckoutLoading()
  const checkoutLoading = useIsInitialLoad()

  const currentItem = useMemo(() => {
    let item = product.unitTypes.find((unitType) => unitType.name === type)
    if (!item) {
      const _type = type === 'STK' ? 'KS' : 'STK'
      item = product.unitTypes.find((unitType) => unitType.name === _type)
      if (!item) {
        throw new Error('Invalid type')
      }
    }
    return item
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.name, product.unitTypes, type])

  const itemQuantity = useMemo(
    () => currentItem.quantityPerUnit * currentQuantity + qtyInCart,
    [currentItem.quantityPerUnit, currentQuantity, qtyInCart],
  )

  const addToCart = useCallback(async () => {
    if (!product.variantId) {
      return
    }
    await cartUpdate({
      [product.variantId]: itemQuantity,
    })
    if (productContext && productContext.setShowPopup) {
      productContext.setShowPopup(false)
    }
  }, [cartUpdate, itemQuantity, product.variantId, productContext])

  const name = useMemo(
    () =>
      getPluralOrSingular({
        singular: currentItem.displayNameSingular,
        plural: currentItem.displayNamePlural,
        value: currentQuantity,
        showQuantity: false,
      }),
    [
      currentItem.displayNamePlural,
      currentItem.displayNameSingular,
      currentQuantity,
    ],
  )
  const handleChange = useCallback(
    (value: number) => {
      setCurrentQuantity(value)
      return false
    },
    [setCurrentQuantity],
  )

  const specialOrder = product.labels.some((s) => s.name === 'Sérpöntun')
  const cartButtonText = 'Setja í körfu'

  if (!isAvailable) {
    return null
  }
  return (
    <>
      {!specialOrder && (
        <QtyWrapper>
          <QtyPicker qty={currentQuantity} onUpdate={handleChange} />
          <P>{name}</P>
        </QtyWrapper>
      )}
      <CartButton
        color="red"
        fill
        onClick={addToCart}
        loading={cartIsUpdating}
        disabled={checkoutLoading}
      >
        {cartButtonText}
      </CartButton>
    </>
  )
}
