import styled from 'styled-components'
import {useProductTabContext} from '../../ProductTabContext'
import {EcoCert} from '../../Certifications/EcoCert'

const Wrapper = styled.div`
  margin: 16px 0;
`

const Title = styled.div`
  font-weight: bolder;
  margin: 16px 0;
`

export const MiscInfoTags = (): JSX.Element | null => {
  const {product} = useProductTabContext()
  const value = product.miscTags.filter((v) => v.thumbnail !== null)
  if (value == null || value.length === 0) {
    return null
  }
  return (
    <Wrapper>
      <Title>Merki</Title>
      {value.map((item) => (
        <EcoCert
          imageSrc={item.thumbnail?.url ?? null}
          title={item.name}
          key={item.id}
          slug={item.slug}
        />
      ))}
    </Wrapper>
  )
}
