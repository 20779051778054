/* eslint-disable react/no-unused-prop-types */
import React, {useCallback} from 'react'
import type {
  UseSelectStateChange,
  UseMultipleSelectionStateChange,
} from 'downshift'
import {useSelect, useMultipleSelection} from 'downshift'
import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'
import type {RemixiconReactIconComponentType} from 'remixicon-react'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon'
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon'
import cn from 'classnames'

import type {FieldConfig} from 'formik'
import {useField} from 'formik'
import {PLarge} from '../typography'

import {theme} from '../../themes'
import scrollStyle from '../../styles/scrollStyle'
import shadowStyles from '../../styles/shadowStyles'
import {inputCommon, InputLabel, InputSleeve, InputWrapper} from './Input'

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  transition: box-shadow 0.15s;
`

const IconWrapper = styled.div`
  height: 18px;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
`

const SelectBtn = styled.button`
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${palette('white')};
  z-index: 0;
  font-weight: 500;
  font-size: 1rem;
  color: ${palette('black')};
  text-align: left;
  border: 0;
  padding-left: 16px;

  &.withBorder {
    border: 1px solid ${palette('black')};
    transition: border-color 0.15s;
    ${inputCommon}
  }
`

interface MenuWrapperProps {
  fit?: boolean
  align: 'left' | 'right'
  hidden?: boolean
}

const MenuWrapper = styled.div<MenuWrapperProps>`
  position: absolute;
  right: ${ifProp({align: 'right'}, '0', 'inherit')};
  left: ${ifProp({align: 'left'}, '-5px', 'inherit')};
  margin-top: 12px;
  min-width: ${ifProp({fit: true}, 'calc(100% + 6px)', '300px')};
  pointer-events: ${ifProp({hidden: true}, 'none', 'auto')};
  padding-left: 4px;
  padding-bottom: 4px;
  transition: opacity 0.15s;
`

const Menu = styled.ul`
  z-index: 2;
  list-style: none;
  background-color: ${palette('white')};
  border: 1px solid ${palette('black')};
  ${shadowStyles({
    color: 'black',
    outlined: false,
    length: 4,
    showOnHover: false,
  })};
  &:before {
    z-index: -1;
  }
`

const MenuInner = styled.div`
  max-height: 350px;

  ${scrollStyle({color: 'black'})}
`

const ListItem = styled.li`
  height: 50px;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0 16px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.15s, background-color 0.15s;

  &:hover {
    background-color: ${palette('black5')};
  }
`

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  transition: background-color 0.15s, border-color 0.15s;
  margin-right: 8px;
  border: 1px solid ${palette('black')};
`

interface Props<Item> {
  placeholder: string
  items: Item[]
  selectedItem?: Item
  icon?: RemixiconReactIconComponentType
  itemToString: (item: Item | null) => string
  handleSelectedItemChange?: (changes: UseSelectStateChange<Item>) => void
  align?: 'left' | 'right'
  fit?: boolean
  label?: string
  withGutter?: boolean
  setTouched?: (touched: boolean) => void
  noBorder?: boolean
}

export function Select<Item>({
  items,
  placeholder,
  align = 'left',
  selectedItem: initialSelectedItem,
  fit,
  itemToString,
  handleSelectedItemChange,
  setTouched,
  noBorder,
}: Props<Item>): JSX.Element {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useSelect({
    items,
    selectedItem: initialSelectedItem,
    itemToString,
    onSelectedItemChange: handleSelectedItemChange,
  })

  const onBlur = useCallback(() => {
    if (!setTouched) {
      return
    }
    setTouched(true)
  }, [setTouched])

  return (
    <SelectContainer>
      <SelectBtn
        type="button"
        {...getToggleButtonProps()}
        className={cn({withBorder: !noBorder})}
      >
        {isOpen && !selectedItem && !placeholder && 'Veldu möguleika'}

        <PLarge>{placeholder}</PLarge>

        <IconWrapper>
          {isOpen ? (
            <ArrowUpSLineIcon size={18} />
          ) : (
            <ArrowDownSLineIcon size={18} />
          )}
        </IconWrapper>
      </SelectBtn>

      <MenuWrapper align={align} fit={fit} hidden={!isOpen}>
        <Menu
          {...getMenuProps({onBlur})}
          style={{
            opacity: isOpen ? 1 : 0,
            pointerEvents: isOpen ? 'unset' : 'none',
          }}
        >
          {items.map((item, index) => {
            const highlighted = highlightedIndex === index
            const selected = selectedItem === item
            const props = getItemProps({item, index})

            return (
              <ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                {...props}
                style={{
                  fontWeight: selected ? 700 : 400,
                  backgroundColor: highlighted
                    ? theme.palette.black10
                    : 'transparent',
                }}
              >
                {itemToString(item)}
              </ListItem>
            )
          })}
        </Menu>
      </MenuWrapper>
    </SelectContainer>
  )
}

interface MultiSelectProps<Item> {
  items: Item[]
  placeholder: string
  align?: 'left' | 'right'
  selectedItems?: Item[]
  fit?: boolean
  itemToString: (item: Item | null) => string
  handleSelectedItemsChange?: (
    changes: UseMultipleSelectionStateChange<Item>,
  ) => void
  noBorder?: boolean
}

export function MultiSelect<Item>({
  items,
  placeholder,
  align = 'left',
  selectedItems: initialSelectedItems,
  fit,
  itemToString,
  handleSelectedItemsChange,
  noBorder,
}: MultiSelectProps<Item>): JSX.Element {
  const {
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    selectedItems: initialSelectedItems,
    onSelectedItemsChange: handleSelectedItemsChange,
  })
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    items,
    stateReducer: (state, actionAndChanges) => {
      const {changes, type} = actionAndChanges
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          }
        default:
      }
      return changes
    },
    onStateChange: ({type, selectedItem: newSelectedItem}) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (!newSelectedItem) {
            break
          }
          if (!selectedItems.includes(newSelectedItem)) {
            addSelectedItem(newSelectedItem)
          } else {
            removeSelectedItem(newSelectedItem)
          }
          break
        default:
          break
      }
    },
  })

  return (
    <SelectContainer>
      <SelectBtn
        type="button"
        {...getToggleButtonProps(getDropdownProps({preventKeyAction: isOpen}))}
        className={cn({withBorder: !noBorder})}
      >
        <PLarge>{placeholder}</PLarge>

        <IconWrapper>
          {isOpen ? (
            <ArrowUpSLineIcon size={18} />
          ) : (
            <ArrowDownSLineIcon size={18} />
          )}
        </IconWrapper>
      </SelectBtn>

      <MenuWrapper align={align} fit={fit}>
        <Menu
          {...getMenuProps()}
          style={{
            opacity: isOpen ? 1 : 0,
            pointerEvents: isOpen ? 'unset' : 'none',
          }}
        >
          <MenuInner>
            {isOpen &&
              items.map((item, index) => {
                const highlighted = highlightedIndex === index
                const selected = selectedItems.includes(item)
                const props = getItemProps({item, index})

                return (
                  <ListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    {...props}
                    style={{
                      backgroundColor: highlighted
                        ? theme.palette.black10
                        : 'transparent',
                    }}
                  >
                    <Checkbox
                      style={{
                        backgroundColor: selected
                          ? theme.palette.secondary
                          : theme.palette.white,
                      }}
                    >
                      <CheckLineIcon color={theme.palette.white} />
                    </Checkbox>{' '}
                    {itemToString(item)}
                  </ListItem>
                )
              })}
          </MenuInner>
        </Menu>
      </MenuWrapper>
    </SelectContainer>
  )
}

export function FormikSelect<Item>(
  props: React.SelectHTMLAttributes<HTMLSelectElement> &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FieldConfig<any> &
    Props<Item>,
): JSX.Element {
  const [_field, meta, helpers] = useField(props)

  const {setValue, setTouched} = helpers
  const value = meta?.value as Item
  const errorMsg = !!meta.touched && meta.error

  const onSelect = useCallback(
    (changes: UseSelectStateChange<Item>) => {
      setValue(changes?.selectedItem)
    },
    [setValue],
  )

  return (
    <InputWrapper withGutter={props.withGutter}>
      <InputLabel htmlFor={props.id} disabled={props.disabled}>
        {props.label}
      </InputLabel>
      <InputSleeve errorMsg={errorMsg || undefined} showError={!!errorMsg}>
        <Select
          setTouched={setTouched}
          itemToString={props.itemToString}
          items={props.items}
          selectedItem={value}
          placeholder={props.itemToString(value) || props.placeholder}
          handleSelectedItemChange={onSelect}
        />
      </InputSleeve>
    </InputWrapper>
  )
}
