import { replace } from 'lodash'
import {
  format as formatSSN,
  isValid as ssnValid,
  isCompany as ssnCompanyValid,
} from 'kennitala'

// This allows 7 digits plus spaces and not letters
export const phoneRegExp = /^(?:[0-9]\x20?){6,6}[0-9]$/

export const postRegExp = /\b(10[1-9]|1[1-9][0-9]|[2-8][0-9]{2}|900)\b/

// for email validation
export const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

// TODO: Test
export function parsePhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) {
    return ''
  }
  const trimmed = replace(phoneNumber, ' ', '')
  return trimmed
}

// TODO: Test
export function formatPhoneNumber(phone: string): string {
  if (typeof phone !== 'string') {
    return ''
  }
  const trimmed = phone?.replace('+354', '')
  if (trimmed.length < 7) {
    return trimmed
  }

  return `${trimmed.substr(0, 3)} ${trimmed.substr(3, trimmed.length)}`
}

// TODO: Test
export function formatPhoneNumberNoSpaces(phone: string): string {
  const trimmed = phone?.replace('+354', '').replace(' ', '')

  return trimmed
}

// TODO: Test
export function removeSpaces(str: string): string {
  return str?.replace(/\s/g, '')
}

export function splitName(fullName: string): [string, string] {
  const lastSpace = fullName.lastIndexOf(' ')
  const name = fullName.substring(0, lastSpace)

  const ssn = formatSSN(fullName.split(' ').pop() ?? '')

  return [name, ssn]
}

// TODO: Test
export function removeFileExt(str: string): string {
  return str.replace(/\.[^/.]+$/, '')
}

export function isValidSsn(ssn: string): boolean {
  return /(9|0)$/.test(ssn) && ssnValid(ssn)
}

export function isValidCompanySsn(ssn: string): boolean {
  return /(9|0)$/.test(ssn) && ssnCompanyValid(ssn)
}

export function getAlgoliaProductIndex(): string {
  const indexMap: Record<string, string> = {
    localhost: 'dev',
    development: 'dev',
    production: 'prod',
  }
  const currentENV: string =
    process.env.NEXT_PUBLIC_ENVIRONMENT || 'development'
  const indexName = `${indexMap[currentENV]}_PRODUCT`
  return indexName
}
