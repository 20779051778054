/* eslint-disable no-console */
import {useState} from 'react'
import type {RvProduct} from 'src/api/local'
import {useFormik} from 'formik'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import {theme} from 'src/themes'
import {Input} from 'src/components/form/Input'
import {Button} from 'src/components/buttons'
import {phoneRegExp, emailRegExp, postRegExp} from 'src/utils/strings'
import {ProductCartPickerContext} from './context'
import {useCartPickerValue} from './hooks/useCartPickerValue'
import {ProductCartAdd} from './ProductCartAdd'
import {ProductCartObject} from './ProductCartObject'
import {ProductCartOfferingPieceOrBox} from './ProductCartOfferingPieceOrBox'

interface ProductCartPickerProps {
  product: RvProduct
  drawer?: boolean
}
interface Drawer {
  drawer?: boolean
}

const numberRegExp = /\d+/

const FormContainer = styled.div``
const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  .form-header {
    font-weight: bold;
    line-height: 1.5;
    font-size: 18px;
    margin-right: 25px;
    margin-bottom: 5px;
  }
  .text-area {
    min-height: 60px;
    margin-bottom: 10px;
  }
  div {
    margin: 0px;
  }

  @media (max-width: ${theme.screenSize.sizeMD}) {
    input {
      font-weight: 400;
    }
  }
`
const SuccessBox = styled.div`
  margin-top: 40px;
  padding: 10px;
  line-height: 25px;
  border: 1px solid ${palette('red')};
  .success_text {
    font-weight: 800;
  }
`

const ErrorText = styled.p`
  color: ${palette('red')};
  font-weight: 800;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  left: 0px;
`

const InputContainer = styled.div<Drawer>`
  position: relative;
  input {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: ${({drawer}) => (drawer ? '14px' : '16px')};
    line-height: ${({drawer}) => (drawer ? '20px' : '22px')};
    padding: ${({drawer}) => (drawer ? '6px 10px' : '10px')};
    height: auto;
  }
  .text-area {
    height: 60px;
    margin-bottom: 20px;
  }
  .text-area + p {
    position: absolute;
    bottom: 20px;
  }

  @media (max-width: ${theme.screenSize.sizeMD}) {
    input {
      font-weight: 400;
    }
  }
`

export const ProductRequestForm = ({
  product,
  drawer,
}: {
  product: RvProduct
  drawer?: boolean
}): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(true)
  const [disableButton, setDisableButton] = useState<boolean>(false)
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [error, setError] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [phoneError, setPhoneError] = useState<string>('')
  const [postError, setPostError] = useState<string>('')
  const [messageError, setMessageError] = useState<string>('')
  const [employeeError, setEmployeeError] = useState<string>('')
  const [companyNameError, setCompanyNameError] = useState<string>('')
  const initialValues = {
    name: '',
    email: '',
    message: '',
    companyName: '',
    phone: '',
    post: '',
    employees: '',
  }

  const submitForm = (values: {
    name: string
    email: string
    message: string
    companyName: string
    phone: string
    post: string
    employees: string
  }): void => {
    if (!values.name) {
      setError('Sláðu inn gilt nafn')
      setNameError('Sláðu inn gilt nafn')
    } else {
      setError('')
      setNameError('')
    }
    if (!values.companyName) {
      setError('Sláðu inn gilt nafn fyrirtækis/stofnunar')
      setCompanyNameError('Sláðu inn gilt nafn fyrirtækis/stofnunar')
    } else {
      setError('')
      setCompanyNameError('')
    }
    if (!values.message) {
      setError('Sláðu inn gilt fyrirspurn')
      setMessageError('Sláðu inn gilt fyrirspurn')
    } else {
      setError('')
      setMessageError('')
    }
    if (!values.email || !emailRegExp.test(values.email)) {
      setError('Sláðu inn gilt netfang')
      setEmailError('Sláðu inn gilt netfang')
    } else {
      setError('')
      setEmailError('')
    }
    if (!phoneRegExp.test(values.phone)) {
      setError('Sláðu inn gilt símanúmer')
      setPhoneError('Sláðu inn gilt símanúmer')
    } else {
      setError('')
      setPhoneError('')
    }
    if (!numberRegExp.test(values.employees)) {
      setError('Sláðu inn gilt starfsmannafjöldi')
      setEmployeeError('Sláðu inn gilt starfsmannafjöldi')
    } else {
      setError('')
      setEmployeeError('')
    }
    if (!postRegExp.test(values.post)) {
      setError('Sláðu inn gilt póstnúmer')
      setPostError('Sláðu inn gilt póstnúmer')
    } else {
      setError('')
      setPostError('')
    }

    if (
      !values.name ||
      !phoneRegExp.test(values.phone) ||
      !values.email ||
      !emailRegExp.test(values.email) ||
      !postRegExp.test(values.post) ||
      !values.message ||
      !values.companyName ||
      !numberRegExp.test(values.employees)
    )
      return

    setDisableButton(false)
    const url = product.thumbnail?.url.split('/media/')
    const convertMedia = url?.length
      ? `https://saleor.rv.reon.is/media/${url[1]}`
      : ''
    const Inputdata = {
      userName: values.name,
      userEmail: values.email,
      message: values.message,
      comapanyName: values.companyName,
      phone: values.phone,
      post: values.post,
      employees: values.employees,
      ProductInfo: {
        productId: product.id,
        productSlug: product.slug,
        category: product.categoryName,
        productName: product.name,
        image: convertMedia,
      },
    }
    const kaffi_skus = [
      'ZIA8124A',
      'NIO202',
      '30001',
      '30500',
      '25001',
      '101.G',
      'ZIA8224AL',
    ]
    const data = {
      receiver_email:
        product?.sku && kaffi_skus.includes(product.sku) ? 'kaffi@rv.is' : '',
      event_name: 'Notify special order',
      event_data: Inputdata,
    }

    fetch(`${process.env.NEXT_PUBLIC_SALEOR_URL}/api-klavio/`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', accept: 'application/json'},
      body: JSON.stringify(data),
    })
      .then((resp: Response) => {
        if (resp && resp.status === 200) {
          setShowForm(false)
        }
        if (resp && resp.type === 'error') {
          setDisableButton(false)
          setError('Gat ekki sent tölvupóst, Vinsamlegast reyndu aftur!')
          console.log('error', {resp})
        }
      })
      .catch((err) => {
        setDisableButton(false)
        setError('Gat ekki sent tölvupóst, Vinsamlegast reyndu aftur!')
        console.log('error', err)
      })
  }

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    onSubmit: (values) => submitForm(values),
  })

  return (
    <FormContainer>
      {showForm ? (
        <Form onSubmit={formik.handleSubmit}>
          <p className="form-header">Fyrirspurn um verð</p>
          <InputContainer drawer={drawer}>
            <Input
              placeholder="Nafn"
              name="name"
              type="text"
              onChange={formik.handleChange}
              showError={!!formik.errors.name}
              errorMsg={nameError}
            />
            {nameError && <ErrorText>{nameError}</ErrorText>}
          </InputContainer>
          <InputContainer drawer={drawer}>
            <Input
              placeholder="Nafn Fyrirtækis/Stofnunar"
              type="text"
              name="companyName"
              onChange={formik.handleChange}
            />
            {companyNameError && <ErrorText>{companyNameError}</ErrorText>}
          </InputContainer>
          <InputContainer drawer={drawer}>
            <Input
              placeholder="Póstnúmer"
              name="post"
              type="text"
              onChange={formik.handleChange}
              showError={!!formik.errors.post}
              errorMsg={postError}
            />
            {postError && <ErrorText>{postError}</ErrorText>}
          </InputContainer>
          <InputContainer drawer={drawer}>
            <Input
              placeholder="Netfang"
              name="email"
              type="email"
              onChange={formik.handleChange}
              showError={!!formik.errors.email}
              errorMsg={emailError}
            />
            {emailError && <ErrorText>{emailError}</ErrorText>}
          </InputContainer>
          <InputContainer drawer={drawer}>
            <Input
              placeholder="Starfsmannafjöldi"
              type="text"
              name="employees"
              onChange={formik.handleChange}
            />
            {employeeError && <ErrorText>{employeeError}</ErrorText>}
          </InputContainer>
          <InputContainer drawer={drawer}>
            <Input
              placeholder="Símanúmer"
              type="text"
              name="phone"
              onChange={formik.handleChange}
              showError={!!formik.errors.phone}
              errorMsg={phoneError}
            />
            {phoneError && <ErrorText>{phoneError}</ErrorText>}
          </InputContainer>
          <InputContainer drawer={drawer}>
            <Input
              placeholder="Fyrirspurn"
              type="textarea"
              className="text-area"
              name="message"
              onChange={formik.handleChange}
            />
            {messageError && <ErrorText>{messageError}</ErrorText>}
          </InputContainer>
          <Button fill withGutter disabled={disableButton} type="submit">
            Senda
          </Button>
        </Form>
      ) : (
        <SuccessBox>
          <div className="success_text">
            Tölvupóstur hefur verið sendur. Lið okkar mun hafa samband við þig
            fljótlega.
          </div>
        </SuccessBox>
      )}
    </FormContainer>
  )
}

export const ProductCartPicker = ({
  product,
  drawer,
}: ProductCartPickerProps): JSX.Element | null => {
  const value = useCartPickerValue({product})

  if (product.pricing == null || product.pricing.length === 0) {
    return null
  }

  return (
    <ProductCartPickerContext.Provider value={value}>
      {product.hidePrice ? (
        <ProductRequestForm product={product} drawer={drawer} />
      ) : (
        <>
          <>
            <ProductCartOfferingPieceOrBox />
            {value.product.unitTypes.map((item) => (
              <ProductCartObject item={item} key={item.id} />
            ))}
          </>
          {value.product.isAvailable && <ProductCartAdd />}
        </>
      )}
    </ProductCartPickerContext.Provider>
  )
}
