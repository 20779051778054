import {useMemo, useState} from 'react'
import type {RvProduct} from 'src/api/local'
import {useLocalProduct} from 'src/api/local/useLocalProduct'
import {useDataContext} from 'src/context/DataContext'
import {useShoppingList} from 'src/context/ShoppingListContext'
import {findItemInShoppingList} from 'src/utils/products'
import type {ProductProps} from '../context'
import type {ProductCardProps} from '../interfaces'

import {useShoppingListId} from './useShoppingListId'

export const useProductValue = ({
  slug,
  shallow,
  shoppingList,
  injected,
  shoppingListProductId,
}: ProductCardProps): ProductProps | null => {
  const {shoppingLists} = useShoppingList()
  const shoppingListId = useShoppingListId()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const ssrProduct = (useDataContext(slug) as RvProduct) ?? null

  const {data: localData, error, loading} = useLocalProduct({slug, injected})

  const data: RvProduct = useMemo(() => {
    if (ssrProduct && !localData?.productBySlug) {
      return ssrProduct
    }
    return (localData?.productBySlug as RvProduct) ?? null
  }, [ssrProduct, localData])

  const value = useMemo(() => {
    if (error) {
      return null
    }
    // Partial return. Pricing might still be loading.
    // Pricing will be empty on SSR
    if (!data && loading) {
      return null
    }
    if (!data) {
      return null
    }
    const {variantId} = data
    const itemInShoppingList = variantId
      ? findItemInShoppingList({
          variantId,
          shoppingLists: shoppingLists ?? [],
        })
      : []

    return {
      shallow: !!shallow,
      product: data as RvProduct,
      shoppingList,
      shoppingListProductId: shoppingListProductId ?? null,
      itemInShoppingList,
      shoppingListId,
      setShowPopup,
      showPopup,
      setShowModal,
      showModal,
    }
  }, [
    data,
    error,
    loading,
    shallow,
    shoppingList,
    shoppingListId,
    shoppingListProductId,
    shoppingLists,
    showModal,
    showPopup,
  ])

  return value
}
