import type {Dispatch, SetStateAction} from 'react'
import {createContext, useContext} from 'react'
import type {RvProduct} from 'src/api/local'
import type {ShoppingListItem} from 'src/api/rest'

export interface ProductProps {
  itemInShoppingList: ShoppingListItem[]
  product: RvProduct
  shoppingListId: string | null | undefined
  shoppingListProductId: string | null
  shallow: boolean
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  showPopup: boolean
  setShowPopup: Dispatch<SetStateAction<boolean>>
  shoppingList?: boolean
}

export const ProductCardContext = createContext<null | ProductProps>(null)

export const useProductCardContext = (): ProductProps => {
  const data = useContext(ProductCardContext)
  if (!data) {
    throw new Error('Only to use inside provider')
  }
  return data
}
