import React, {useState} from 'react'
import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'
import type {FieldConfig} from 'formik'
import {useField} from 'formik'

import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import shadowStyles from '../../styles/shadowStyles'
import theme from '../../themes/theme'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  center?: boolean
  errorMsg?: string
  withGutter?: boolean
  color?: 'primary' | 'secondary'
  fontWeight?: '400' | '600'
}

interface LabelProps {
  center?: boolean
  disabled?: boolean
}
interface TextProps {
  fontWeight?: '400' | '600'
}

interface BoxProps {
  checked: boolean
}

interface InputContainerProps {
  color: 'primary' | 'secondary'
  checked: boolean
}

interface CheckboxWrapperProps {
  withGutter: boolean
  color: 'primary' | 'secondary'
}

const Text = styled.div<TextProps>`
  font-weight: ${(p) => p.fontWeight};
  font-size: 1rem;
  color: ${palette('secondary')};
  line-height: 1;
  margin-left: 12px;
`

const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
  cursor: ${ifProp({disabled: true}, 'not-allowed', 'pointer')};
  justify-content: flex-end;
  padding: 4px;

  ${Text} {
    color: ${ifProp('disabled', palette('black40'), palette('secondary'))};
  }
`

const Box = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  transition: background-color 0.15s, border-color 0.15s;
  background-color: ${palette('white')};
`

const CheckboxInput = styled.input`
  position: absolute;
  border-radius: 0;
  opacity: 0;
  pointer-events: none;

  &:disabled {
    ~ ${Box} {
      border-color: ${palette('black40')};
    }
  }
`

const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  margin-bottom: ${ifProp('withGutter', '24px', '0')};

  ${Box} {
    border: 1px solid ${(p) => palette(p.color)};
  }
  ${CheckboxInput} {
    &:disabled {
      ~ ${Box} {
        &:hover {
          transform: none;

          &:before {
            transform: translateZ(-1em);
          }
        }
      }
    }
    &:checked {
      ~ ${Box} {
        background-color: ${(p) => palette(p.color)};
      &:disabled {
        ~ ${Box} {
          background-color: ${palette('black20')};

          &:hover {
            transform: none;

            &:before {
              transform: translateZ(-1em);
            }
          }
        }
      }
    }
  }
`
const InputContainer = styled.div<InputContainerProps>`
  width: fit-content;
  height: fit-content;
  ${(p) =>
    !p.checked
      ? shadowStyles({
          color: p.color,
          outlined: true,
          length: 4,
          showOnHover: true,
        })
      : shadowStyles({
          color: p.color,
          outlined: false,
          length: 4,
          showOnHover: true,
        })};
`

export default function Checkbox({
  label,
  center,
  withGutter,
  color = 'secondary',
  fontWeight = '600',
  ...props
}: Props): JSX.Element {
  const [checked, setChecked] = useState<boolean>()

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setChecked(e.target.checked)
  }

  return (
    <CheckboxWrapper withGutter={!!withGutter} color={color}>
      <Label center={center} disabled={props.disabled}>
        {!!label && <Text fontWeight={fontWeight}>{label}</Text>}
        <InputContainer checked={!!checked} color={color}>
          <CheckboxInput type="checkbox" {...props} onChange={handleChange} />
          <Box checked={!!checked}>
            <CheckLineIcon
              color={
                props.disabled && props.checked
                  ? theme.palette.black40
                  : theme.palette.white
              }
            />
          </Box>
        </InputContainer>
      </Label>
    </CheckboxWrapper>
  )
}

export function FormikCheckbox(
  props: Props & React.InputHTMLAttributes<HTMLInputElement> & FieldConfig,
): JSX.Element {
  const [field, meta] = useField(props)
  const errorMsg = !!meta.touched && meta.error

  return (
    <Checkbox
      {...field}
      {...props}
      checked={field.value}
      errorMsg={errorMsg || undefined}
    />
  )
}
