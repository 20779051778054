export const getPluralOrSingular = ({
  plural,
  singular,
  value,
  showQuantity = true,
}: {
  plural: string
  singular: string
  value: number
  showQuantity?: boolean
}): string => {
  const word = (() => {
    if (value.toString().endsWith('1') && !value.toString().endsWith('11')) {
      return singular
    }
    return plural
  })()
  if (!showQuantity) {
    return word
  }
  return `${value} ${word}`
}
