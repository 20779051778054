import {isSSR} from './isSSR'

export const getScrollbarWidth = (): number => {
  if (isSSR) {
    return 12
  }
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll'
  document.body.appendChild(outer)
  const inner = document.createElement('div')
  outer.appendChild(inner)
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth
  outer.parentNode?.removeChild(outer)

  return scrollbarWidth
}

export const SCROLLBAR_WIDTH = getScrollbarWidth()
