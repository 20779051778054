// REASON: Href gets added by NextJS
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link'
import Image from 'next/image'
import {NoImage} from 'src/components/common'
import {P, PSmall, Title6} from 'src/components/typography'
import {BackgroundImageContain} from 'src/styles/imageStyles'
import {paths} from 'src/utils/paths'
import styled from 'styled-components'
import {theme} from 'src/themes'
import {useMemo} from 'react'
import {Description, FlexBetween} from './components'
import {useProductCardContext} from './context'
import {cutText} from './utils/cutText'

const CardWrapper = styled.div`
  cursor: pointer;
  @media (max-width: ${theme.screenSize.sizeMD}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const CardText = styled.div`
  @media (max-width: ${theme.screenSize.sizeMD}) {
    display: flex;
    flex-direction: column;
  }
`

const ProductImage = styled.div`
  height: 180px;
  width: 100%;
  margin-bottom: 24px;
  position: relative;
  @media (max-width: ${theme.screenSize.sizeMD}) {
    height: 100px;
    width: 100%;
    max-width: 140px;
    margin-bottom: 0;
    margin-right: 16px;
  }
`

export const CardTitle = (): JSX.Element => {
  const {product} = useProductCardContext()
  const data: {__html: string} = useMemo(
    () => ({
      __html: cutText(product.description),
    }),
    [product.description],
  )
  return (
    <Link href={`${paths.product}/${product.slug}`}>
      <a>
        <CardWrapper>
          <CardText>
            <FlexBetween>
              <PSmall color="black40">{product.sku}</PSmall>
            </FlexBetween>

            <Title6>{product.name}</Title6>
            <P withGutter>{product.categoryName}</P>
          </CardText>
          <ProductImage>
            {product.thumbnail?.url ? (
              <BackgroundImageContain>
                <Image
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                  src={product.thumbnail.url ?? ''}
                />
              </BackgroundImageContain>
            ) : (
              <NoImage size={64} />
            )}
          </ProductImage>
          <Description dangerouslySetInnerHTML={data} />
        </CardWrapper>
      </a>
    </Link>
  )
}
