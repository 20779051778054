import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'
import {theme} from 'src/themes'
import shadowStyles from 'src/styles/shadowStyles'

export const Wrapper = styled.div``

export const MediaGallery = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  @media (max-width: ${theme.screenSize.sizeMD}) {
    justify-content: center;
  }
`

export const MiniContainer = styled.div`
  position: relative;
  margin-right: 16px;
  gap: 20px;
  height: 100%;
  width: 100px;

  .slick-track {
    height: auto !important;
  }
  @media (max-width: ${theme.screenSize.sizeMD}) {
    display: none;
  } ;
`
interface SlideProps {
  selected?: boolean
}

export const MiniSlide = styled.div<SlideProps>`
  position: relative;
  width: 96%;
  height: 80px;
  padding: 0 8px;
  margin: 0 4px 4px;
  border: 1px solid
    ${ifProp({selected: true}, palette('primary'), palette('black'))};
  transition: border-color 0.15s;
  background-color: ${palette('white')};
  cursor: pointer;

  ${ifProp(
    {selected: true},
    shadowStyles({
      color: 'red',
      outlined: false,
      length: 4,
      showOnHover: false,
    }),
  )}

  &:focus {
    outline: none !important;
  }
  @media (max-width: ${theme.screenSize.sizeMD}) {
    display: none;
  } ;
`

export const MiniImage = styled.div`
  position: relative;
  width: auto;
  display: flex;
  justify-content: center;
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: calc(100% - 120px);
  width: 100%;
  max-width: 320px;
  min-height: 400px;
  padding: 20px 0;
  margin: 0 auto;
  background-color: ${palette('white')};
  border: 1px solid ${palette('black80')};
  @media (max-width: ${theme.screenSize.sizeSM}) {
    min-height: 200px;
  }
  .main-slider {
    max-width: 320px;
    min-width: calc(90%);
    width: 90% !important;
    height: 200px;
    margin: auto;
    @media (min-width: ${theme.screenSize.sizeSM}) {
      height: 100% !important;
    }
    @media (max-width: ${theme.screenSize.sizeMD}) {
      min-width: calc(100% - 20px);
    }
  }

  .slick-list {
    height: 100% !important;
  }

  .slick-track {
    height: 100% !important;
  }
  .slick-slide {
    height: 100% !important;
    > div {
      position: relative;
      max-width: 400px;
      display: flex;
      margin: 0 auto;
      place-items: center;
      height: 100% !important;
    }
  }

  .slick-dots {
    bottom: 0;

    li {
      width: auto;

      button {
        width: 24px;
        padding: 0;

        &:before {
          content: '';
          top: 8px;
          width: 24px;
          height: 3px;
          background-color: ${palette('black20')};
        }
      }
    }

    li.slick-active button:before {
      top: 8px;
      height: 3px;
      background-color: ${palette('red')};
    }
  }

  @media (max-width: ${theme.screenSize.sizeMD}) {
    .main-slider {
      max-width: unset;
    }
  }
`

export const MainSlide = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 16px;
  cursor: pointer;
  &:focus {
    outline: none !important;
  }
`

export const MainImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  > span {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    transition: opacity 400ms;
  }
  .hidden {
    > span {
      opacity: 0;
    }
  }
  .shown {
    > span {
      opacity: 1;
    }
  }
  img {
  }
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  @media (max-width: ${theme.screenSize.sizeSM}) {
    &:before {
      padding-bottom: 110%;
    }
  }
`
export const MediaPopUpContainer = styled.div`
  position: fixed;
  z-index: 1000;
  top: 100px;
  left: 30px;
  padding: 10px;
  margin: auto;
  width: 95vw;
  height: 80vh;
  background-color: ${theme.palette.white};
  box-shadow: 5px 5px 5px ${theme.palette.black60};
  border-radius: 10px;
  .popup-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    display: grid;
    grid-direction: row;
    grid-gap: 20px;
    grid-template-columns: 3fr 1fr;
  }
  .popup-image {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 300px;
    }
    span {
      max-width: 70vw;
      max-height: 80vh;
    }
  }
  .video-iframe {
    width: 100%;
    height: 77vh;
  }
  H4 {
    margin-right: 20px;
  }
  .sku-text {
    margin-top: 30px;
  }
  @media (max-width: ${theme.screenSize.sizeSM}) {
    left: 10px;
    .popup-wrapper {
      display: grid;
      grid-direction: column;
      justify-content: center;
      grid-template-columns: 100%;
    }
    H4 {
      display: none !important;
    }
    .sku-text {
      display: none !important;
    }
    .video-iframe {
      width: 100%;
      height: 300px;
    }
    .slide-box {
      align-self: end;
      margin: auto;
      margin-bottom: 15px;
    }
    .popup-media {
      align-self: end;
    }
    .popup-image {
      align-self: center;
      span {
        max-width: 90vw;
        max-height: 65vh;
      }
    }
  }
`
export const CloseButton = styled.div`
  position: absolute;
  z-index: 10;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  border: 2px solid ${theme.palette.black};
  border-radius: 5px;
  right: 2px;
  cursor: pointer;
  &:hover {
    border: 2px solid ${theme.palette.error};
    svg {
      fill: ${theme.palette.error};
    }
  }
`

export const SlideBox = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

export const PopUpMiniSlide = styled.div<SlideProps>`
  position: relative;
  width: 80px;
  height: 80px;
  padding: 0 8px;
  margin: 0 4px 4px;
  border: 1px solid
    ${ifProp({selected: true}, palette('primary'), palette('black'))};
  transition: border-color 0.15s;
  background-color: ${palette('white')};
  cursor: pointer;

  ${ifProp(
    {selected: true},
    shadowStyles({
      color: 'red',
      outlined: false,
      length: 4,
      showOnHover: false,
    }),
  )}

  &:focus {
    outline: none !important;
  }
`

export const VideoContainer = styled.iframe`
  width: 100%;
  height: 100%;
`
