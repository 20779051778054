import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import Link from '../common/Link'
import {PSmall} from '../typography'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const BreadWrapper = styled.div`
  display: block;
`

const BreadLink = styled(PSmall)`
  display: inline;
  font-weight: 600;
  overflow: scroll;

  &.current {
    font-weight: 400;
    color: ${palette('black60')};
  }
`

const Seperator = styled.span`
  margin: 0 8px;
`

export type Breadcrumb = {title?: string; href?: string; shallow?: boolean}

interface Props {
  breadcrumbs: Breadcrumb[]
}

export default function Breadcrumbs({breadcrumbs}: Props): JSX.Element {
  return (
    <Wrapper>
      {breadcrumbs.map(
        (bread, i) =>
          !!bread.title && (
            <BreadWrapper key={bread.href}>
              {!!i && <Seperator>/</Seperator>}
              {bread.href ? (
                <Link href={bread.href} shallow={bread.shallow}>
                  <BreadLink
                    className={cn({
                      current: i === breadcrumbs.length - 1,
                    })}
                  >
                    {bread.title}
                  </BreadLink>
                </Link>
              ) : (
                <BreadLink
                  className={cn({
                    current: i === breadcrumbs.length - 1,
                  })}
                >
                  {bread.title}
                </BreadLink>
              )}
            </BreadWrapper>
          ),
      )}
    </Wrapper>
  )
}
