import React from 'react'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import {SpacingWrapper} from 'src/components/layout'

import styled from 'styled-components'
import {BreadcrumbsBar} from './BreadcrumbsBar'
import {useHasLowResolution} from '../hooks/useHasLowResolution'

export const AccountContainer = styled(Container)`
  margin: 0 12px 10px 21px;
  margin: auto;
`

interface Props {
  children: React.ReactNode
  cols?: number
}

export default function AccountPage({children, cols}: Props): JSX.Element {
  const hasLowResolution = useHasLowResolution()
  return (
    <AccountContainer>
      <Row>
        {/* hide sidebar until we will add more links to it, now its pointless */}
        {/* {hasLowResolution ? null : (
          <Col sm={3} lg={2}>
            <AccountSidebar />
          </Col>
        )} */}
        <BreadcrumbsBar />
        <Col sm={cols || (hasLowResolution ? 12 : 9)}>
          <SpacingWrapper spacing={60}>{children}</SpacingWrapper>
        </Col>
      </Row>
    </AccountContainer>
  )
}
