/* eslint-disable no-console */
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import {useUser} from 'src/context/UserContext'

import {Title4, Title6} from 'src/components/typography'
import {phoneRegExp, parsePhoneNumber} from 'src/utils/strings'
import {SpacingWrapper} from '../layout'
import {Button} from '../buttons'
import Loader from '../common/Loader'
import {CleaveInput} from '../form'

const Divider = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
`
const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
`
const LoadingMsg = styled.div`
  padding-left: 18px;
`

const ErrorTitle = styled(Title6)`
  color: #ef3f57;
  font-weight: normal;
`

type PhoneNumber = {
  phoneNumber: string
}

export default function LoginForm(): JSX.Element {
  const {signIn, errorMsg, isVerifyingLogin, resetUser} = useUser()

  // Initial values for formik hook
  const initialValues: PhoneNumber = {
    phoneNumber: '',
  }

  // Defining a scema for yup validation
  const phoneNumberSchema: Yup.SchemaOf<PhoneNumber> = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(phoneRegExp, 'Símanúmer þarf að vera 7 tölustafir')
      .required('Hér vantar símanúmer'),
  })

  // UseFormik to handle the form and validate it with yub
  const formik = useFormik({
    initialValues,
    validationSchema: phoneNumberSchema,
    validateOnChange: false,

    onSubmit: (values, {resetForm}) => {
      const phone: string = parsePhoneNumber(values.phoneNumber)
      signIn(phone)
      resetForm()
    },
  })

  return (
    <>
      {isVerifyingLogin ? (
        <LoaderWrapper>
          <Loader />
          <LoadingMsg>
            <Title6>Auðkenning hefur verið send.</Title6>
          </LoadingMsg>
        </LoaderWrapper>
      ) : (
        <>
          {resetUser && (
            <ErrorTitle withGutter>Notandalotunni þinni er lokið</ErrorTitle>
          )}
          <Title4 withGutter>Innskráning með rafrænum skilríkjum</Title4>
          <SpacingWrapper spacing={24}>
            <form onSubmit={formik.handleSubmit}>
              <CleaveInput
                label="Símanúmer"
                name="phoneNumber"
                type="tel"
                showError={!!formik.errors.phoneNumber || !!errorMsg}
                errorMsg={formik.errors.phoneNumber || errorMsg || ''}
                onChange={formik.handleChange}
                options={{
                  numericOnly: true,
                  delimiters: [' '],
                  blocks: [3, 4],
                }}
              />
              <Divider />
              <Button type="submit">Skrá inn</Button>
            </form>
          </SpacingWrapper>
        </>
      )}
    </>
  )
}
