import React, {useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import SubtractLineIcon from 'remixicon-react/SubtractLineIcon'
import {theme} from '../../themes'

const Box = styled.div`
  background-color: ${palette('white')};
  display: flex;
`

const Btn = styled.button`
  border: 1px solid ${palette('black')};
  background-color: ${palette('black5')};
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: background-color 0.15s;
  padding: 0;
  border-radius: 0;

  .remixicon-icon {
    transition: opacity 0.15s;
  }

  &:disabled {
    cursor: not-allowed;

    .remixicon-icon {
      opacity: 0.2;
    }
  }

  &:hover:not(:disabled) {
    background-color: ${palette('light')};
  }
`

const BtnRight = styled(Btn)`
  margin-left: auto;
`

const QtyNum = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  width: 40px;
  color: ${palette('blue')};
  border: 1px solid ${palette('black')};
  outline: none;
`
interface Props {
  qty: number
  isMin?: boolean
  onUpdate: (q: number) => void
  isMax?: boolean
  isDisabled?: boolean
}

export default function QtyPicker({
  qty,
  onUpdate,
  isMin = qty === 1,
  isMax,
  isDisabled,
}: Props): JSX.Element {
  const [value, setValue] = useState<string>(qty.toString())
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    setValue(qty.toString())
  }, [qty])
  const handleDecrease = useCallback(() => {
    onUpdate(qty - 1)
  }, [qty, onUpdate])
  const handleIncrease = useCallback(() => {
    onUpdate(qty + 1)
  }, [qty, onUpdate])

  const handleBlur = useCallback(() => {
    const newValue = parseInt(value, 10)
    if (Number.isNaN(newValue)) {
      setValue(qty.toString())
      return
    }
    onUpdate(newValue)
  }, [onUpdate, qty, value])
  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = ev.target.value
      setValue(newValue)
    },
    [],
  )

  const handleKeypress = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === 'Enter') {
        const newValue = parseInt(value, 10)

        if (ref.current) {
          ref.current.blur()
        }

        if (Number.isNaN(newValue)) {
          setValue(qty.toString())
          return
        }
        onUpdate(newValue)
      }
    },
    [onUpdate, qty, value],
  )

  return (
    <Box>
      <Btn
        type="button"
        onClick={handleDecrease}
        disabled={isMin || isDisabled}
      >
        <SubtractLineIcon size={16} color={theme.palette.black} />
      </Btn>
      <QtyNum
        value={value}
        ref={ref}
        type="text"
        pattern="[0-9]*"
        onKeyPress={handleKeypress}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isDisabled}
      />
      <BtnRight
        type="button"
        onClick={handleIncrease}
        disabled={isMax || isDisabled}
      >
        <AddLineIcon size={16} color={theme.palette.black} />
      </BtnRight>
    </Box>
  )
}
