import React from 'react'
import UserLineIcon from 'remixicon-react/User3LineIcon'
import {format as formatSSN} from 'kennitala'

import InfoCard from 'src/components/account/InfoCard'
import {P, Title7} from 'src/components/typography'
import {theme} from 'src/themes'
import {formatPhoneNumber} from 'src/utils/strings'
import {useUser} from '../../context/UserContext'

export default function UserInfo(): JSX.Element {
  const {user} = useUser()
  const ssn = user?.verified?.ssn
  const phoneNumber = user?.verified?.phoneNumber
    ? formatPhoneNumber(user?.verified?.phoneNumber)
    : ''
  /** This is only for debuging */
  if (user === null) {
    return <div>Engar notenda upplýsingar fundust</div>
  }

  return (
    <div>
      <Title7 withGutter>Persónuupplýsingar</Title7>
      {user && (
        <InfoCard
          icon={<UserLineIcon size={30} color={theme.palette.primary} />}
          title={`${user.name}`}
          description={
            <>
              <P>{ssn && formatSSN(ssn)}</P>
              <P>{phoneNumber}</P>
              <P>{user.email}</P>
            </>
          }
        />
      )}
    </div>
  )
}
