import type {
  ProductOrder,
  SelectedAttribute,
  AttributeCountableConnection,
  AttributeValue,
} from 'src/api/saleor/generated'
import {OrderDirection, ProductOrderField} from 'src/api/saleor/generated'

import {fromPairs} from 'lodash'
import type {ShoppingList, ShoppingListItem} from 'src/api/rest'
import {toGlobalId} from './graphql'
import {isNotNull, isNotNullKey} from './isNotNull'

export interface AttrOption {
  id: string
  attrName: string
  slug: string
  attrValues: AttributeValue[]
}

// TODO: test
export function mapAttributes(
  attributes: SelectedAttribute[],
): Record<string, SelectedAttribute> {
  return fromPairs(
    (attributes ?? [])
      ?.filter((value) => value && value.attribute && value.attribute.slug)
      .map((attr) => [attr.attribute.slug, attr]),
  )
}

// TODO: test
// TODO: Check if sorting works for price when products have price
export const sortingArgsMap: Record<string, [ProductOrder]> = {
  new: [{field: ProductOrderField.Date, direction: OrderDirection.Asc}],
  price_asc: [{field: ProductOrderField.Price, direction: OrderDirection.Asc}],
  alph_asc: [{field: ProductOrderField.Name, direction: OrderDirection.Asc}],
  // alph_desc: [{field: ProductOrderField.Name, direction: OrderDirection.Desc}],
  undefined: [{field: ProductOrderField.Date, direction: OrderDirection.Asc}],
}

// TODO: test
export const toAttrOption = (
  attributes: AttributeCountableConnection,
): AttrOption[] =>
  (attributes?.edges ?? []).map((a) => ({
    id: a.node.id,
    attrName: a.node.name ?? '',
    slug: a.node.slug ?? '',
    attrValues: (a.node.choices?.edges || []).map(({node}) => ({
      name: node.name,
      slug: node.slug ?? '',
      id: node.id,
    })),
  }))

// TODO: test
export const attributeFilters = (
  attributes: AttributeCountableConnection,
  filterQueryParams: string[],
): {slug: string; values: string[]}[] =>
  (
    attributes?.edges?.map((a) => ({
      slug: a.node.slug,
      values: a.node?.choices?.edges
        .filter(
          (v) => v?.node?.slug && filterQueryParams?.includes(v.node.slug),
        )
        .map((v) => v.node.slug),
    })) ?? []
  )
    .filter(isNotNullKey('slug'))
    .filter(
      (value): value is {slug: string; values: string[]} =>
        value.slug != null && value.values != null && value.values.length > 0,
    )

// TODO: test
export const findItemInShoppingList = ({
  variantId,
  shoppingLists,
}: {
  variantId: string
  shoppingLists: ShoppingList[]
}): ShoppingListItem[] =>
  (shoppingLists || [])
    .map((list) =>
      list.items.find(
        (item) =>
          toGlobalId('ProductVariant', item?.productVariant?.id) === variantId,
      ),
    )
    .filter(isNotNull)
