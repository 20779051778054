import styled from 'styled-components'
import {palette} from 'styled-tools'

export const Wrapper = styled.div`
  padding: 8px;
`

export const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  font-size: 1rem;
`

export const CertTitle = styled.div`
  color: ${palette('black90')};
  margin-right: 6px;
`

export const CertText = styled.div`
  color: ${palette('black40')};
`

export const CircleImage = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-left: 8px;
  border: 1px solid ${palette('black')};
  background-color: ${palette('white')};
  margin-right: 12px;
  position: relative;
  overflow: hidden;
`

export const NoImage = styled(CircleImage)`
  opacity: 0;
`
