import {gridTheme} from 'src/themes'
import {useSize} from './useSize'

const viewports = {
  high: gridTheme.breakpoints.md,
  low: 0,
}

export function useHasLowResolution(): boolean {
  const resolution = useSize({viewports})
  return resolution === 'low'
}
