import styled from 'styled-components'
import CheckboxCircleFillIcon from 'remixicon-react/CheckboxCircleFillIcon'
import {theme} from 'src/themes'
import {P} from '../typography'

const Wrapper = styled.div`
  margin-top: 24px;
`

const Block = styled(P)`
  margin-top: 12px;
  white-space: pre-wrap;
`

const TEXT =
  'Beiðni móttekin og verður afgreidd innan sólahrings. Ef fyrirtækinu þínu liggur á kaupum þá bendum við á að hafa samband við okkur í síma 520‑6666.'

export const ShowEnd = (): JSX.Element => (
  <Wrapper>
    <CheckboxCircleFillIcon color={theme.palette.success} />
    <Block withGutter>{TEXT}</Block>
  </Wrapper>
)
