import React, {useMemo} from 'react'
import styled from 'styled-components'

const RichTextContainer = styled.div`
  p {
    line-height: 1.8;
    max-width: 60ch;
  }
`

interface Props {
  jsonString: string
}

export default function SaleorRichTextContent({
  jsonString,
}: Props): JSX.Element | null {
  const data: {__html: string} = useMemo(
    () => ({
      __html: jsonString,
    }),
    [jsonString],
  )
  return <RichTextContainer dangerouslySetInnerHTML={data} />
}
