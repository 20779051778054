import React from 'react'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import SearchLineIcon from 'remixicon-react/SearchLineIcon'
import {theme} from '../../themes'

interface InputProps {
  border?: boolean
}

const InputWrapper = styled.div<InputProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  color: ${palette('black90')};

  svg {
    margin: 0 10px;
  }
`

const Input = styled.input`
  border: none;
  height: 100%;
  flex: 1;
  background: transparent;

  &:active,
  &:focus {
    outline: none !important;
  }
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  border?: boolean
}

export default function SearchInput(props: Props): JSX.Element {
  return (
    <InputWrapper border>
      <SearchLineIcon size={20} color={theme.palette.red} />
      <Input {...props} />
    </InputWrapper>
  )
}
