import React from 'react'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import {useUser} from 'src/context/UserContext'
import {theme} from 'src/themes'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {CompanyUserForm} from '.'
import {ShowEnd} from '../registration/ShowEnd'

const MessageWrapper = styled.div`
  padding: 0 24px 8px 24px;
  margin-bottom: 24px;
  border: 1px solid ${palette('success')};
`

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 10px;
  display: flex;
  cursor: pointer;
`

export default function CompanyRequestPage(): JSX.Element {
  const {showRegistrationMsg, setShowRegistrationMsg} = useUser()
  return (
    <Container>
      <Row>
        <Col md={5} mdOffset={1} mdOrder={2} mdAlignSelf="center">
          {showRegistrationMsg && (
            <MessageWrapper>
              <CloseButton onClick={() => setShowRegistrationMsg(false)}>
                <CloseLineIcon color={theme.palette.success} />
              </CloseButton>
              <ShowEnd />
            </MessageWrapper>
          )}
        </Col>
        <Col md={5} mdOffset={1} mdOrder={1}>
          <CompanyUserForm />
        </Col>
      </Row>
    </Container>
  )
}
