import React, {useCallback} from 'react'

import {DeleteConfirmation} from 'src/components/common'
import {useShoppingList} from '../../../context/ShoppingListContext'

interface Props {
  id: string
  listId: string
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DeleteShoppingListItem({
  id,
  listId,
  setShowModal,
}: Props): JSX.Element {
  const {deleteItemFromShoppingList, useSingleShoppingList} = useShoppingList()
  const {revalidateSingleShoppingList} = useSingleShoppingList(listId)
  const handleDeleteItem = useCallback(() => {
    deleteItemFromShoppingList(id)
    revalidateSingleShoppingList()
    setShowModal(false)
  }, [
    deleteItemFromShoppingList,
    id,
    revalidateSingleShoppingList,
    setShowModal,
  ])
  return (
    <DeleteConfirmation
      title="Ertu viss um að þú viljir eyða vöru úr óskalistanum þínum?"
      text="Þú munt ekki geta tekið þessa aðgerð til baka."
      onCancel={() => setShowModal(false)}
      onRemove={handleDeleteItem}
      cancelText="Hætta við"
      submitText="Eyða vöru"
    />
  )
}
