import {Breadcrumbs} from 'components/products'
import {useMemo} from 'react'
import {paths} from 'src/utils/paths'
import {BreadcrumbWrapper} from './BreadcrumbWrapper'
import {items} from './items'
import {useCurrentPath} from '../hooks/useCurrentPath'

interface Item {
  title: string
  href: string
}

const DEFAULT_BREADCRUMBS_ITEMS: Item[] = [
  {
    title: 'Forsíða',
    href: paths.home,
  },
]

export function BreadcrumbsBar(): JSX.Element {
  const currentPath = useCurrentPath()
  const breadcrumItems = useMemo(() => {
    const item =
      items.find((value) => value.href === currentPath, []) || items[0]
    return [...DEFAULT_BREADCRUMBS_ITEMS, item]
  }, [currentPath])
  return (
    <BreadcrumbWrapper>
      <Breadcrumbs breadcrumbs={breadcrumItems} />
    </BreadcrumbWrapper>
  )
}
