import cn from 'classnames'
import {CornerButton} from 'components/buttons'
import {useCallback} from 'react'
import HeartFillIcon from 'remixicon-react/HeartFillIcon'
import {useUser} from 'src/context/UserContext'
import {theme} from 'src/themes'
import {FavoriteButton} from './components'
import {useProductCardContext} from './context'

export const CardProductUser = (): JSX.Element | null => {
  const {user} = useUser()
  const {setShowModal, itemInShoppingList} = useProductCardContext()
  const handleClick = useCallback(() => setShowModal(true), [setShowModal])
  if (!user) {
    return null
  }
  return (
    <FavoriteButton className={cn({selected: !!itemInShoppingList.length})}>
      <CornerButton
        icon={
          <HeartFillIcon
            color={
              itemInShoppingList.length
                ? theme.palette.primary
                : theme.palette.black20
            }
            size={16}
          />
        }
        onClick={handleClick}
      />
    </FavoriteButton>
  )
}
