import React from 'react'

import Cleave from 'cleave.js/react'
import type {Props as CleaveProps} from 'cleave.js/react/props'
import type {FieldConfig} from 'formik'
import {useField} from 'formik'
import styled from 'styled-components'
import {ifProp, palette} from 'styled-tools'

import theme from 'src/themes/theme'

import type {InputProps} from './Input'
import {InputSleeve, InputLabel} from './Input'

export interface CleaveInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  showError?: boolean
  errorMsg?: string
  withGutter?: boolean
  label?: string
}

interface InputWrapperProps {
  withGutter?: boolean
}

const StyledCleaveInput = styled(Cleave)<InputProps>`
  border: 1px solid ${palette('secondary')};
  background-color: ${palette('white')};
  height: 50px;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  padding: 16px;

  &:hover&:not(:disabled) {
    outline: 1px solid ${palette('secondary')};
  }

  &:active,
  &:focus {
    border-width: 1px;
    border-color: ${palette('red')};
    outline: none !important;
  }

  &:disabled {
    background-color: ${palette('black5')};
    border-color: ${palette('black40')};
    cursor: not-allowed;
  }
`
const InputWrapper = styled.div<InputWrapperProps>`
  margin-bottom: ${ifProp({withGutter: true}, '32px', '0')};
`

export default function CleaveInput({
  errorMsg,
  showError,
  ...props
}: CleaveProps & InputProps): JSX.Element {
  return (
    <InputWrapper withGutter={props.withGutter}>
      <InputLabel htmlFor={props.id} disabled={props.disabled}>
        {props.label}
      </InputLabel>
      <InputSleeve showError={!!showError} errorMsg={errorMsg}>
        <StyledCleaveInput
          style={{
            borderBottom: showError
              ? `4px solid ${theme.palette.error}`
              : undefined,
          }}
          {...props}
        />
      </InputSleeve>
    </InputWrapper>
  )
}

export function FormikCleaveInput<T>(
  props: CleaveProps &
    CleaveInputProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    FieldConfig<T>,
): JSX.Element {
  const [field, meta] = useField(props)
  const errorMsg = !!meta.touched && meta.error

  return (
    <CleaveInput
      errorMsg={errorMsg || undefined}
      showError={!!errorMsg}
      {...field}
      {...props}
    />
  )
}
