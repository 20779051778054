import React, {useState, useEffect} from 'react'
import type {Settings} from 'react-slick'
import Slider from 'react-slick'

import {SpacingWrapper} from 'src/components/layout'
import {NoImage} from 'src/components/common'
import {MediaGallery, MiniContainer, MainContainer, MainSlide} from './styles'
import {ProductMediaMiniSlide} from './ProductMediaMiniSlide'
import {MediaPopUp} from './ProductMediaPopup'
import {ProductMediaMain} from './ProductMediaMain'
import type {RVMedias} from './interfaces'

interface Props {
  prodMedia: RVMedias
  proName: string
  proSku: string | null
}

export default function ProductMediaGallery({
  prodMedia,
  proName,
  proSku,
}: Props): JSX.Element {
  const [currSlide, setCurrSlide] = useState<number>(0)
  const [miniSlider, setMiniSlider] = useState<Slider | null | undefined>(
    undefined,
  )
  const [showMediaPopUp, setShowMediaPopUp] = useState<boolean>(false)
  const [mainSlider, setMainSlider] = useState<Slider | null | undefined>(
    undefined,
  )

  useEffect(() => {
    if (showMediaPopUp) {
      const popUpEle = document.getElementById('popup-backdrop')
      if (popUpEle?.style) {
        popUpEle.style.display = 'block'
        document.body.style.overflow = 'hidden'
      }
    }
  }, [showMediaPopUp])

  const toggleMediaPopUp = (value: boolean): void => {
    setShowMediaPopUp(value)
  }

  const miniSettings: Settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 400,
    verticalSwiping: true,
    swipeToSlide: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    waitForAnimate: false,
    vertical: true,
    focusOnSelect: true,
  }

  const mainSettings: Settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 400,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    beforeChange: (_: number, index: number) => setCurrSlide(Math.ceil(index)),
  }
  return (
    <>
      {showMediaPopUp && (
        <MediaPopUp
          prodMedia={prodMedia}
          proName={proName}
          proSku={proSku}
          currentSlide={currSlide}
          toggleMediaPopUp={toggleMediaPopUp}
        />
      )}
      <MediaGallery>
        <MiniContainer>
          <Slider
            ref={(ref) => setMiniSlider(ref)}
            asNavFor={mainSlider ?? undefined}
            {...miniSettings}
          >
            {prodMedia.map((value, index) => (
              <ProductMediaMiniSlide
                key={value.id}
                value={value}
                isSelected={index === currSlide}
              />
            ))}
          </Slider>
        </MiniContainer>

        <MainContainer
          onClick={() => {
            setShowMediaPopUp(true)
          }}
        >
          <Slider
            ref={(ref) => setMainSlider(ref)}
            asNavFor={miniSlider ?? undefined}
            className="main-slider"
            {...mainSettings}
          >
            {prodMedia.length ? (
              prodMedia?.map((value) => (
                <ProductMediaMain key={value.id} value={value} />
              ))
            ) : (
              <MainSlide>
                <SpacingWrapper spacing={16}>
                  <NoImage size={72} textSize="1.25rem" withPadding />
                </SpacingWrapper>
              </MainSlide>
            )}
          </Slider>
        </MainContainer>
      </MediaGallery>
    </>
  )
}
