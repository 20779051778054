import React, {useState, useEffect, useMemo} from 'react'
import {Container, Col, Row, media} from 'styled-bootstrap-grid'
import {useWindowSize} from 'react-use'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'
import algoliasearch from 'algoliasearch/lite'
import {BlockTitle} from 'src/components/layout'
import {ProductCard} from 'src/components/products'
import {Button} from 'src/components/buttons'
import Checkbox from 'src/components/form/Checkbox'
import {
  InstantSearch,
  SearchBox,
  connectInfiniteHits,
  connectRefinementList,
  connectStats,
  connectPagination,
  connectSortBy,
} from 'react-instantsearch-dom'
import Loader from 'src/components/common/Loader'
import {getAlgoliaProductIndex} from 'src/utils/strings'
import {theme as Theme} from 'src/themes'
import type {HitType} from './SearchBar'

interface Props {
  query: string
}

type ScreenType = 'large' | 'big' | 'medium' | 'small'

const SearchResultContainer = styled.div`
  margin-left: 20px;
  .ais-SearchBox {
    display: none;
  }
  .large {
    height: 95%;
  }
  .small {
    margin-right: 15px;
  }
  .title-block {
    margin-left: 40px;
    margin-top: 30px;
  }
  .stats-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .env-checkbox {
    margin-left: 20px;
    ${media.xs`
      margin-top: 75px;
  `}
  }
  .filter-box {
    width: 280px;
    margin-bottom: 10px;
  }
`
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 15px;
  border: 1px solid #19191b;
  background-color: #fefefe;
  font-size: 16px;
  font-family: Inter, sans-serif;
  overflow-y: scroll;
  label {
    margin-top: 2px;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
  input[type='checkbox'] {
    accent-color: rgb(239, 63, 87);
  }
`

const FilterTitle = styled.div`
  height: 50px;
  padding: 0 16px;
  margin-bottom: 4px;
  border: 1px solid #19191b;
  background-color: #fefefe;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
    color: ${palette('red')};
  }
`
const FilterHead = styled.button`
  height: 50px;
  padding: 0 16px;
  margin-bottom: 4px;
  border: 1px solid #19191b;
  background-color: #fefefe;
  font-family: 'LetterboardLite';
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
  .loader {
    margin: auto;
  }
`
const StatsContainer = styled.div`
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 14px;
`
const SearchResultsPage = styled.div``

const SortDropdown = styled.div`
position: relative;
width: 220px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: ${palette('white')};
border-radius: 5px;
border: 1.5px solid ${palette('black')};
  .sort-button {
    background-color: transparent;
    border: none !important;
    outline: none !important;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-size: 16px;
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
    }
  }
}
.options {
  width: 100%;
  position: absolute;
  top: 40px;
  z-index: 10;
  border: 1px solid ${palette('black')};
  background-color: ${palette('white')};
  ${media.lg`
    position: relative;
    top: 0;
    border-top: border-top: 1px solid ${palette('black')};
    border-bottom: none !important;
    border-left: none !important;
    border-right: none;
  `}
  li {
    padding: 5px;
    button {
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: none !important;
    font-size: 16px;
    &:hover {
      color: ${Theme.palette.error};
    }
    .selected {
      button {
        color: ${Theme.palette.error};
      }
    }
    }
    &:hover {
      cursor: pointer;
    }
  }
}
position: absolute;
z-index: 10;
right: 20px;
top: -30px;
${media.lg`
  top: -50px;
`}
${media.xs`
  top: 20px;
`}
align-self: flex-end;
margin-bottom: 10px;
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
`

export default function SearchResults({query}: Props): JSX.Element {
  const {width} = useWindowSize()
  const [stateQuery, setQuery] = useState<string>()
  const [screenWidth, setScreenWidth] = useState<number>()

  useEffect(() => {
    if (query) {
      setQuery(query)
    }
  }, [query])

  useEffect(() => {
    if (width) {
      setScreenWidth(width)
    }
  }, [width])

  const screenType: ScreenType = useMemo(() => {
    if (width >= 1180) {
      return 'large'
    }
    if (width < 1180 && width >= 950) {
      return 'big'
    }
    if (width < 950 && width > 720) {
      return 'medium'
    }
    if (width <= 720) {
      return 'small'
    }
    return 'large'
  }, [width])

  const applicationID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || ''
  const searchAPIKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
  const indexName = getAlgoliaProductIndex()

  const searchClient = algoliasearch(applicationID, searchAPIKey)

  function StatsComponent({
    nbHits,
    updateTotalHits,
  }: {
    nbHits: number
    updateTotalHits: (total: number) => void
  }): null {
    useEffect(() => {
      updateTotalHits(nbHits)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nbHits])

    return null
  }

  function PaginationComponent({
    currentRefinement,
    totalHits,
  }: {
    currentRefinement: number
    totalHits: number
  }): JSX.Element {
    const [loaded, setLoaded] = useState<number>(0)

    useEffect(() => {
      if (currentRefinement === 1) {
        setLoaded(() => {
          if (totalHits < 24) {
            return totalHits
          }
          return 24
        })
      } else {
        setLoaded(() => {
          if (currentRefinement * 24 > totalHits) {
            return totalHits
          }
          return currentRefinement * 24
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRefinement, totalHits])

    return (
      <StatsContainer>
        Þú hefur séð {loaded} af {totalHits}
      </StatsContainer>
    )
  }

  const CustomStats = connectStats(StatsComponent)
  const CustomPagination = connectPagination(PaginationComponent)

  function InfiniteHits({
    hits,
    hasMore,
    refineNext,
    cols,
  }: {
    hits: HitType[]
    hasMore: boolean
    refineNext: () => void
    cols: number
  }): JSX.Element {
    const [totalHits, setTotalHits] = useState<number>(0)

    const updateTotalHits = (total: number): void => {
      setTotalHits(total)
    }

    const OnLoadMore = (): void => {
      refineNext()
    }

    return (
      <>
        <Row style={{marginTop: '20px'}}>
          {hits.map((hit: HitType) => (
            <ProductCard
              key={hit.id}
              slug={hit.slug}
              render={(children) => (
                <Col key={hit.id} col={cols}>
                  {children}
                </Col>
              )}
            />
          ))}
        </Row>
        <div className="stats-container">
          <CustomStats updateTotalHits={updateTotalHits} />
          <CustomPagination defaultRefinement={24} totalHits={totalHits} />
          {hasMore && (
            <Button
              onClick={OnLoadMore}
              disabled={!hasMore}
              className="load-button"
              withGutter
              outlined
            >
              Sjá fleiri vörur
            </Button>
          )}
        </div>
      </>
    )
  }

  interface Items {
    count: number
    isRefined: boolean
    label: string
    value: string[]
  }

  function RefinementList({
    items,
    refine,
  }: {
    items: Items[]
    refine: (value: string[]) => void
  }): JSX.Element {
    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    useEffect(() => {
      if (screenType === 'large') {
        setShowDropdown(true)
      }
    }, [])
    return (
      <FilterBox className={screenType !== 'large' ? 'filter-box' : ''}>
        <FilterHead
          className={`filter_${screenType}`}
          onClick={() => {
            if (screenType !== 'large') {
              setShowDropdown(!showDropdown)
            }
          }}
        >
          Síur
        </FilterHead>
        {showDropdown && (
          <>
            {!items?.length ? (
              <div className="loader">
                <Loader />
              </div>
            ) : (
              <>
                <FilterTitle className={`filter_${screenType}`}>
                  <ArrowRightLineIcon /> Flokkar
                </FilterTitle>
                <FilterContainer className={`filter_${screenType}`}>
                  {items.map((item: Items, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>
                      <Row>
                        <input
                          type="checkbox"
                          name={item.label}
                          id={item.label}
                          checked={item.isRefined}
                          onChange={() => {
                            refine(item.value)
                          }}
                        />
                        <label htmlFor={item.label}>{item.label}</label>
                      </Row>
                    </div>
                  ))}
                </FilterContainer>
              </>
            )}
          </>
        )}
      </FilterBox>
    )
  }

  function EnvRefinement({
    refine,
  }: {
    refine: (value: string[]) => void
  }): JSX.Element {
    const [checked, setChecked] = useState<boolean>(false)
    useEffect(() => {
      if (checked) {
        refine(['true'])
      } else {
        refine(['true', 'false'])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked])
    return (
      <div className="env-checkbox">
        <Checkbox
          label="Sía vistvænar vörur"
          checked={checked}
          onClick={() => setChecked(!checked)}
        />
      </div>
    )
  }

  const SortContainer = ({
    items,
    refine,
  }: {
    items: {value: string; label: string}[]
    refine: (val: string) => void
  }): JSX.Element => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    const [sort, setSort] = useState<string>()
    const handleChange = (item: {value: string; label: string}): void => {
      setSort(item.label)
      setShowDropdown(false)
      refine(item.value)
    }

    return (
      <SortDropdown className="sort-container">
        <button
          type="button"
          className="sort-button"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <span>{sort ? `Raða eftir: ${sort}` : 'Raða eftir: Nafn'}</span>
          <i className={showDropdown ? 'arrow up' : 'arrow down'} />
        </button>
        {showDropdown && (
          <div className="options">
            <ul>
              {items.map((item: {label: string; value: string}) => (
                <li key={item.value}>
                  <button
                    type="button"
                    onClick={() => handleChange(item)}
                    className={sort === item.label ? 'selected' : ''}
                  >
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </SortDropdown>
    )
  }

  const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)

  const CustomRefinementList = connectRefinementList(RefinementList)

  const EnvCustomRefinementList = connectRefinementList(EnvRefinement)

  const CustomSortBy = connectSortBy(SortContainer)

  const colsObj = {
    small: 12,
    big: 4,
    medium: 6,
    large: 4,
  }

  const serachResultReturn: JSX.Element = useMemo(
    () => (
      <SearchResultsPage>
        <Container>
          <InstantSearch searchClient={searchClient} indexName={indexName}>
            {stateQuery && (
              <SearchResultContainer>
                <div className="title-block">
                  <BlockTitle title="Vöruleit" />
                </div>
                <Row>
                  <Col col={screenType === 'large' ? 3 : 12}>
                    <CustomRefinementList
                      attribute="category_name"
                      limit={40}
                    />
                  </Col>
                  <Col col={screenType === 'large' ? 9 : 12}>
                    <div
                      className={
                        screenType === 'large' ? 'refine-container' : ''
                      }
                    >
                      <EnvCustomRefinementList
                        attribute="eco-friendly"
                        limit={40}
                      />
                      <CustomSortBy
                        defaultRefinement={indexName}
                        items={[
                          {value: indexName, label: 'Nafn'},
                          // {
                          //   value: `${indexName}_price_desc`,
                          //   label: 'Hæsta verð',
                          // },
                          {
                            value: `${indexName}_price_asc`,
                            label: 'Lægsta verð',
                          },
                          {value: `${indexName}_date`, label: 'Nýjast'},
                        ]}
                      />
                      <SearchBox defaultRefinement={stateQuery} />
                      <CustomInfiniteHits cols={colsObj[screenType]} />
                    </div>
                  </Col>
                </Row>
              </SearchResultContainer>
            )}
          </InstantSearch>
        </Container>
      </SearchResultsPage>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [screenWidth, stateQuery],
  )

  return serachResultReturn
}
