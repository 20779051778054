/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable no-console */
/* eslint-disable @next/next/no-img-element */
import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react'
import {useRouter} from 'next/router'
import algoliasearch from 'algoliasearch/lite'
import SearchLineIcon from 'remixicon-react/SearchLineIcon'
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectInfiniteHits,
} from 'react-instantsearch-dom'
import Link from 'next/link'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {theme} from 'src/themes'
import {paths} from 'src/utils/paths'
import {getAlgoliaProductIndex} from 'src/utils/strings'

interface Props {
  border?: boolean
  showDropdown?: boolean
  query?: string
}

export type HitType = {
  id: string
  slug: string
  image_url: string
  name: string
  description_plaintext: string
}

const HitContainer = styled.div`
  display: flex;
  flex-direction: column;
  .hit-link {
    :hover {
      background-color: #f9f9f9;
      color: #ef3f57;
    }
  }
`

const SearchContainer = styled.div`
  position: absolute;
  max-height: 460px;
  width: 600px;
  top: 60px;
  z-index: 100;
  background-color: #fff;
  border-bottom: 1px solid rgba(232, 232, 232, 0.6);
  overflow-y: scroll;
  .product-name {
    padding-left: 10px;
  }
  .products-title {
    font-size: 18px;
    font-weight: 600;
    padding: 20px;
  }
  .hit-container {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(232, 232, 232, 0.6);
  }
  .hit-container > img {
    object-fit: contain;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
  border-left: 5px solid red;
  border-bottom: 5px solid red;
`

const SearchWrapper = styled.div`
  .search-flex {
    display: none;
    ${media.md`
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`}
  }
  input {
    outline: none;
    border: none;
    padding: 5px;
    background-color: transparent;
    display: none;
    ${media.md`
    display: block;
    width: 420px;
  `}
    appearence: none;
  }
  .ais-SearchBox {
    display: none;
    display: block;
    width: 420px;
  }

  .ais-SearchBox-form {
    outline: none;
    border: none;
    padding: 5px;
    background-color: transparent;
    :hover {
      .ais-SearchBox-submit {
        display: none;
      }
      .ais-SearchBox-reset {
        display: none;
      }
    }
    :focus-within {
      .ais-SearchBox-submit {
        display: none;
      }
      .ais-SearchBox-reset {
        display: none;
      }
    }
  }
  .ais-SearchBox-submit {
    display: none;
  }
  .ais-SearchBox-reset {
    display: none;
  }
  .ErrorText {
    background-color: #fff;
    padding: 15px;
  }
`
const MobileSearchContainer = styled.div`
  position: absolute;
  max-height: 500px;
  margin-top: 10px;
  width: 90%;
  z-index: 100;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(232, 232, 232, 0.6);
  overflow-y: scroll;
  border-left: 5px solid red;
  border-bottom: 5px solid red;
  .hit-container {
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border: 1px solid rgba(232, 232, 232, 0.6);
  }
  .hit-container > img {
    object-fit: contain;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
  .products-title {
    border: 1px solid rgba(232, 232, 232, 0.6);
    font-size: 18px;
    font-weight: 600;
    padding: 20px;
  }
`

const MobileSearchWrapper = styled.div`
  color: rgb(48, 48, 50);
  padding-left: 10px;
  .search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }
  input {
    outline: none;
    border: none;
    padding: 5px;
    background-color: transparent;
  }
  #mobileInput {
    width: 80vw;
    padding-right: 10px;
  }
  .ais-SearchBox-form {
    outline: none;
    border: none;
    padding: 5px;
    background-color: transparent;
    :hover {
      .ais-SearchBox-submit {
        display: none;
      }
      .ais-SearchBox-reset {
        display: none;
      }
    }
    :focus-within {
      .ais-SearchBox-submit {
        display: none;
      }
      .ais-SearchBox-reset {
        display: none;
      }
    }
  }
  .ais-SearchBox-submit {
    display: none;
  }
  .ais-SearchBox-reset {
    display: none;
  }
`

export default function SearchBar({border, showDropdown}: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)
  const mobileRef = useRef<HTMLDivElement>(null)

  const router = useRouter()
  const [showHits, setShowHit] = useState<boolean | undefined>()
  const [searchText, setSearchText] = useState<string>()
  const [isMobile, setIsMobile] = useState<boolean | undefined>()
  const [enter, setEnter] = useState<boolean | undefined>()
  const toggleShowHits = useCallback((value: boolean) => setShowHit(value), [])

  useEffect(() => {
    setIsMobile(border)
    setShowHit(showDropdown)
    if (!showDropdown) {
      setSearchText('')
    }
  }, [border, showDropdown])

  const useKeyboardEvent = (): {
    value: number
    handleKeypress: (val: KeyboardEvent) => void
    ResetValue: () => void
  } => {
    const [value, setValue] = useState<number>(0)
    const ResetValue = (val?: number): void => {
      if (val) {
        setValue(val)
      }
      setValue(0)
    }
    const handleKeypress = useCallback(
      (val: KeyboardEvent): void => {
        const focused = document.activeElement
        if (!focused) {
          return
        }
        if (
          focused?.getAttribute('id') === 'searchInput' ||
          focused?.getAttribute('data-search-box')
        ) {
          if (val.code === 'ArrowDown') {
            const newValue = value + 1
            val.preventDefault()
            setValue(newValue)
            return
          }
          if (val.code === 'ArrowUp') {
            const newValue = value - 1
            val.preventDefault()
            if (newValue < 0) {
              return
            }
            setValue(newValue)
            return
          }
        }
        if (val.code === 'Escape') {
          setValue(0)
        }
      },
      [value],
    )
    return {value, handleKeypress, ResetValue}
  }

  const {value, handleKeypress, ResetValue} = useKeyboardEvent()

  useEffect(() => {
    if (isMobile && showHits) {
      document.addEventListener('mousedown', (event) => {
        if (!mobileRef.current?.contains(event.target as Node | null)) {
          toggleShowHits(false)
        }
      })
    }
    if (showHits && !isMobile) {
      document.addEventListener('mousedown', (event) => {
        if (!ref.current?.contains(event.target as Node | null)) {
          toggleShowHits(false)
          ResetValue()
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    document.addEventListener('keydown', handleKeypress)
    return () => {
      document.removeEventListener('keydown', handleKeypress)
    }
  }, [handleKeypress])

  const applicationID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || ''
  const searchAPIKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
  const indexName = getAlgoliaProductIndex()

  const searchClient = useMemo(() => {
    const algoliaClient = algoliasearch(applicationID, searchAPIKey)
    return {
      ...algoliaClient,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      search(requests: any) {
        const filteredReq = requests.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (req: any) => req.params.query !== '',
        )
        if (filteredReq.length) {
          return algoliaClient.search(filteredReq)
        }
        return Promise.resolve({})
      },
    }
  }, [applicationID, searchAPIKey])

  function InfiniteHits({
    hits,
    keyValue,
    hasMore,
    refineNext,
    onEnter,
    resetValue,
  }: {
    hits: HitType[]
    keyValue: number
    refineNext: () => void
    hasMore: boolean
    onEnter: boolean | undefined
    resetValue: (val?: number) => void
  }): JSX.Element {
    const resultElement = document.getElementById('result-container')
    useEffect(() => {
      if (keyValue && keyValue <= hits.length) {
        const focusElement = resultElement?.children[keyValue - 1]
        if (focusElement) {
          ;(focusElement as HTMLElement).style.backgroundColor = '#f9f9f9'
          ;(focusElement as HTMLElement).style.color = '#EF3F57'
          focusElement?.scrollIntoView({behavior: 'smooth'})
        }
        if (keyValue === hits.length && hasMore) {
          resetValue(1)
          refineNext()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyValue, resultElement])

    useEffect(() => {
      if (onEnter) {
        router.push(`/vara/${hits[keyValue - 1].slug}`)
        setShowHit(false)
      }
    }, [hits, keyValue, onEnter])

    return (
      <>
        {/* <div className="products-title">Products</div> */}
        <HitContainer id="result-container">
          {hits.map((hit: HitType, index) => (
            <div data-search-box={index} key={hit.slug} className="hit-link">
              <Link href={`/vara/${hit.slug}`}>
                <a href={`/vara/${hit.slug}`}>
                  <div className="hit-container">
                    <img src={hit.image_url} width="40" height="40" alt="" />
                    <div>
                      <div className="product-name">{hit.name}</div>
                    </div>
                  </div>
                </a>
              </Link>
            </div>
          ))}
        </HitContainer>
      </>
    )
  }

  function Hit({hit}: {hit: HitType}): JSX.Element {
    return (
      <Link href={`/vara/${hit.slug}`}>
        <a href={`/vara/${hit.slug}`}>
          <div className="hit-container">
            <img src={hit.image_url} width="40" height="40" alt="" />
            <div>
              <div className="product-name">{hit.name}</div>
            </div>
          </div>
        </a>
      </Link>
    )
  }

  useEffect(() => {
    if (showHits && searchText) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [showHits, searchText])

  const handleSubmit = (
    event: React.SyntheticEvent<HTMLFormElement, Event>,
  ): void => {
    event.preventDefault()
    if (!value) {
      const path = `${paths.search}${searchText}`
      toggleShowHits(false)
      router.push(path, path, {shallow: true})
      return
    }
    setEnter(true)
  }

  const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)

  const serachBarReturn: JSX.Element = isMobile ? (
    <MobileSearchWrapper ref={mobileRef}>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <div className="search-box">
          <SearchLineIcon size={20} color={theme.palette.red} />
          <SearchBox
            inputId="mobileInput"
            translations={{
              placeholder: 'Að hverju leitar þú?',
            }}
            onSubmit={handleSubmit}
            onChange={(event) => {
              if (!showHits) {
                toggleShowHits(true)
              }
              setSearchText((event.target as HTMLInputElement).value)
              ResetValue()
            }}
          />
        </div>
        {searchText && showHits && (
          <>
            <MobileSearchContainer>
              <div className="products-title">Vörur</div>
              <Hits hitComponent={Hit} />
            </MobileSearchContainer>
          </>
        )}
      </InstantSearch>
    </MobileSearchWrapper>
  ) : (
    <SearchWrapper ref={ref}>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <div className="search-flex" tabIndex={0} data-search-box={0}>
          <SearchLineIcon size={20} color={theme.palette.red} />
          <SearchBox
            inputId="searchInput"
            data-search-box={0}
            translations={{
              placeholder: 'Að hverju leitar þú?',
            }}
            onSubmit={handleSubmit}
            onChange={(event) => {
              if (!showHits) {
                toggleShowHits(true)
              }
              setSearchText((event.target as HTMLInputElement).value)
              ResetValue()
            }}
          />
        </div>
        {searchText && showHits && (
          <>
            <SearchContainer id="search-container">
              <CustomInfiniteHits
                keyValue={value}
                onEnter={enter}
                resetValue={ResetValue}
              />
            </SearchContainer>
          </>
        )}
      </InstantSearch>
    </SearchWrapper>
  )

  return serachBarReturn
}
