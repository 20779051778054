import React from 'react'
import BuildingLineIcon from 'remixicon-react/Building4LineIcon'
import {format as formatSSN} from 'kennitala'

import {InfoCard} from 'src/components/account'
import {P, Title7} from 'src/components/typography'
import {theme} from 'src/themes'
import {formatPhoneNumber} from 'src/utils/strings'
import {useUser} from 'src/context/UserContext'

export default function CompanyInfo(): JSX.Element {
  const {userCompanies} = useUser()
  return (
    <>
      <Title7 withGutter>Fyrirtæki</Title7>
      {userCompanies?.map((company) => {
        const ssn = formatSSN(company.ssn)
        const phoneNumber = company.addresses?.[0]?.phone
          ? formatPhoneNumber(company.addresses?.[0]?.phone)
          : ''
        const streetAddress = company.addresses?.[0]?.streetAddress1
        const postalCode = company.addresses?.[0]?.postalCode
        const city = company.addresses?.[0]?.city
        return (
          <InfoCard
            key={company.id}
            icon={<BuildingLineIcon size={30} color={theme.palette.primary} />}
            title={company.name}
            description={
              <>
                {ssn ? <P>{formatSSN(ssn)}</P> : null}
                {phoneNumber ? <P>{phoneNumber}</P> : null}
                {streetAddress ? <P>{streetAddress},</P> : null}
                <P>
                  {postalCode} {city}
                </P>
              </>
            }
          />
        )
      })}
    </>
  )
}
