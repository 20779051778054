import React from 'react'

import styled from 'styled-components'
import {palette} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'
import ArrowRightUpLineIcon from 'remixicon-react/ArrowRightUpLineIcon'

import theme from 'src/themes/theme'
import {removeFileExt} from 'src/utils/strings'

import {useProductTabContext} from '../ProductTabContext'

const Wrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
`
const Card = styled.a`
  width: 100%;
  background-color: ${palette('white')};
  border: 1px solid ${palette('secondary')};
  margin-bottom: 8px;
`

const ContentWrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 16px;
  }
`

const CardTitle = styled.div`
  font-size: 1rem;
  color: ${palette('black90')};
`

const CardText = styled.div`
  display: none;

  ${media.sm`
    display: block;
    font-size: 0.875rem;
    font-style: italic;
    color: ${palette('black40')};
  `}
`

export default function Docs(): JSX.Element {
  const {product} = useProductTabContext()
  const secDocs = product.infoDocs
  return (
    <Wrapper>
      {secDocs?.map((doc) => (
        <Card key={doc.slug} href={doc.url} target="_blank">
          <ContentWrapper>
            <CardTitle>{removeFileExt(doc.name)}</CardTitle>
            <FlexWrapper>
              <CardText>Opna í nýjum vafra</CardText>
              <ArrowRightUpLineIcon color={theme.palette.primary} size={20} />
            </FlexWrapper>
          </ContentWrapper>
        </Card>
      ))}
    </Wrapper>
  )
}
