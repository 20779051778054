import {BackgroundImageContain} from 'src/styles/imageStyles'
import {removeNonAsciiCharacters} from 'src/utils/removeNonAsciiCharacters'
import styled from 'styled-components'
import {CircleImage, NoImage} from './styles'

const Image = styled.img`
  object-fit: auto;
  width: 40px;
  height: auto;
  &.image-astma-og-ofnmisvotta {
    width: 35px;
  }
  &.image-endurunni-plast {
    width: 35px;
  }
  &.image-verndum-regnskga {
    width: 46px;
  }
  &.image-bioware {
    width: 32px;
  }
  &.image-pefc {
    width: 26px;
  }
  &.image-ecologo {
    width: 25px;
  }
`

const Container = styled(BackgroundImageContain)`
  display: grid;
  place-items: center;
`

export const EcoCertImage = ({
  imageSrc,
  alt = '',
  slug,
}: {
  slug?: string
  alt?: string
  imageSrc?: null | string
}): JSX.Element => {
  if (imageSrc == null) {
    return <NoImage />
  }
  return (
    <CircleImage>
      <Container>
        <Image
          alt={alt}
          src={imageSrc}
          className={
            slug ? `image-${removeNonAsciiCharacters(slug)}` : undefined
          }
        />
      </Container>
    </CircleImage>
  )
}
