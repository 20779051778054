export const cutText = (
  text: string,
  {limit}: {limit: number} = {limit: 150},
): string => {
  const words = text.split(/(?=[ ,.!-]+)/)

  return words.reduce<{lastSkipped: boolean; length: number; value: string}>(
    (a, b) => {
      if (a.lastSkipped) {
        return a
      }
      const currentLength = a.length
      const valueLength = b.length
      const newLength = currentLength + valueLength
      const newString = `${a.value}${b}`
      if (newLength >= limit) {
        const oldValue = a.value.replace(/[ ,.!-]+$/, '')
        return {
          ...a,
          value: `${oldValue} …`,
          lastSkipped: true,
        }
      }
      return {
        ...a,
        length: newLength,
        value: newString,
      }
    },
    {
      value: '',
      length: 0,
      lastSkipped: false,
    },
  ).value
}
