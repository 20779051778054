import styled from 'styled-components'
import type {RVVideo} from './interfaces'
import {MainImage, MainSlide} from './styles'

const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
export const ProductMediaVideo = ({
  value,
  width,
  height,
  styledProps = {},
}: {
  value: RVVideo
  width?: string
  height?: string
  styledProps?: {marginTop?: string; marginBottom?: string}
}): JSX.Element | null => (
  <MainSlide
    style={{width: width ?? '100%', height: height ?? '100%', ...styledProps}}
  >
    <MainImage>
      <VideoFrame src={value.embedURL} />
    </MainImage>
  </MainSlide>
)
