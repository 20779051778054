import React from 'react'

import {Wrapper} from './styles'
import {EcoCerts} from './EcoCerts'

export default function Certifications(): JSX.Element {
  return (
    <Wrapper>
      <EcoCerts />
    </Wrapper>
  )
}
