import {useRef} from 'react'
import Image from 'next/image'
import {ProductMediaVideo} from './ProductMediaVideo'
import {MainSlide} from './styles'
import type {RVMedia} from './interfaces'
import {typeIsVideo} from './interfaces'

export const ProductMediaMain = ({value}: {value: RVMedia}): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  if (typeIsVideo(value)) {
    return <ProductMediaVideo value={value} />
  }
  const {url} = value
  return (
    <MainSlide ref={ref}>
      <Image
        alt=""
        src={url}
        objectFit="contain"
        layout="fill"
        objectPosition="center"
      />
    </MainSlide>
  )
}
