import {useCallback} from 'react'
import type {UnitType} from 'src/api/local'
import {RadioField} from 'src/components/form'
import {useProductCartPickerContext} from './context'

export const ProductCartObject = ({
  item,
}: {
  item: UnitType
}): JSX.Element | null => {
  const {
    type,
    setType,
    product: {slug, isAvailable, pricing},
  } = useProductCartPickerContext()

  const pricingItem = pricing?.find((value) => value.unitType === item.name)

  const handleChange = useCallback(() => {
    setType(item.name)
  }, [item.name, setType])

  if (!pricingItem) {
    return null
  }

  return (
    <RadioField
      onChange={handleChange}
      aria-checked={type === item.name}
      checked={type === item.name}
      label={item.displayNameSingular}
      name={slug}
      value={item.name}
      color="primary"
      numberOfUnits={
        item.quantityPerUnit > 1 ? item.quantityPerUnit : undefined
      }
      originalPrice={pricingItem.grossOriginalPriceFormatted}
      price={pricingItem.grossFormatted}
      unitPrice={`${pricingItem.grossUnitPriceFormatted}/stk.`}
      discount={pricingItem.discountFormatted ?? undefined}
      withGutter
      disabled={!isAvailable}
    />
  )
}
