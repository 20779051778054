import {gridTheme} from 'src/themes'
import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

export const BreadcrumbWrapper = styled(Container)`
  padding: 5px 10px;
  border-bottom: 1px solid ${palette('primary')};
  width: 100%;
  margin: 0 45px;
  @media (max-width: ${gridTheme.breakpoints.sm}px) {
    margin: 0 10px;
  }
`
