import React from 'react'

import styled from 'styled-components'
import type {RvLabel} from 'src/api/local'
import type {ProductLabelVariant} from '.'
import {ProductTag, ProductBadgeVariant} from '.'

interface Props {
  isAvailable?: boolean
  labels?: RvLabel[]
  badges?: {
    slug: string
    id: string
  }[]
}

const ProductBadges = styled.div`
  display: flex;
`
const ProductLabels = styled.div`
  display: flex;
  margin-left: 12px;
`

export default function ProductTagsWrapper({
  isAvailable,
  labels = [],
  badges = [],
}: Props): JSX.Element {
  return (
    <>
      <ProductBadges>
        {!isAvailable && (
          <ProductTag badgeVariant={ProductBadgeVariant.ComingSoon} />
        )}
        {badges?.map((badge) => (
          <ProductTag
            slug={badge.slug}
            key={badge.id}
            badgeVariant={badge.slug as ProductBadgeVariant}
          />
        ))}
      </ProductBadges>
      <ProductLabels>
        {labels?.map((label) => (
          <ProductTag
            slug={label.slug}
            key={label.id}
            labelVariant={label.slug as ProductLabelVariant}
            labelImage={label.thumbnail?.url}
            title={label.name}
          />
        ))}
      </ProductLabels>
    </>
  )
}
