import React, {useRef, useMemo} from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {palette} from 'styled-tools'
import type {Webpage_header_links_PageCategoryRecord as LinkCategory} from 'src/api/datocms/types/Webpage'
import {usePageContext} from 'src/context/PageContext'
import {useClickAway} from 'react-use'
import shadowStyles from 'src/styles/shadowStyles'
import {NavBarLink} from './Header'
import {Expandable} from '../../common'

const Wrapper = styled.div`
  position: relative;
`

export const DropdownWrapper = styled.div`
  position: absolute;
  left: -14px;
  height: 0;
`

export const LinksWrapper = styled.div`
  ${shadowStyles({
    color: 'red',
    outlined: false,
    length: 8,
    showOnHover: false,
  })};
  border-right: 1px solid ${palette('red')};
  border-bottom: 1px solid ${palette('red')};
  background-color: ${palette('white')};
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-bottom: 8px;
  width: 200px;
  padding-top: 10px;

  a {
    justify-content: flex-start;
  }

  ${media.lg`
    max-height: 50vh;
  `}
`

const DropdownLink = styled.a`
  margin: 5px 10px;
  line-height: 24px;

  &:hover {
    color: ${palette('red')};
    cursor: pointer;
  }
`

interface LinkType {
  path: string
  heading: string
}
interface Props {
  show: boolean
  links: LinkType[]
}

function NavPageDropdown({show, links}: Props): JSX.Element {
  return (
    <DropdownWrapper>
      <Expandable expanded={show}>
        <LinksWrapper>
          {links?.map((link) => (
            <Link href={link.path} key={link.heading} passHref>
              <DropdownLink>{link.heading}</DropdownLink>
            </Link>
          ))}
        </LinksWrapper>
      </Expandable>
    </DropdownWrapper>
  )
}

interface CategoryProps {
  id: string
  name: string
  links: LinkType[]
}

export function NavCategoryLink({id, name, links}: CategoryProps): JSX.Element {
  const {currentOpenDropdown, setOpenDropdown, closeDropdown} = usePageContext()
  const isOpen = currentOpenDropdown === id
  const dropdownRef = useRef(null)

  function close(): void {
    closeDropdown()
  }

  useClickAway(dropdownRef, () => {
    close()
  })

  function toggleDropdown(): void {
    if (isOpen) {
      closeDropdown()
    } else {
      setOpenDropdown(id)
    }
  }
  return (
    <Wrapper>
      <NavBarLink
        onClick={toggleDropdown}
        style={{
          fontWeight: isOpen ? 700 : 400,
        }}
        text={name}
      >
        {name}
      </NavBarLink>
      <NavPageDropdown links={links} show={isOpen} />
    </Wrapper>
  )
}

interface LinkProps {
  category: LinkCategory
}

export default function NavDatoCategoryLink({
  category,
}: LinkProps): JSX.Element | null {
  const {
    getPagesByCategory,
    getLinksBySlug,
    currentOpenDropdown,
    setOpenDropdown,
    closeDropdown,
  } = usePageContext()
  const isOpen = currentOpenDropdown === category.id
  const dropdownRef = useRef(null)

  function close(): void {
    closeDropdown()
  }

  useClickAway(dropdownRef, () => {
    close()
  })

  function toggleDropdown(): void {
    if (isOpen) {
      closeDropdown()
    } else {
      setOpenDropdown(category.id)
    }
  }

  const links = useMemo(() => {
    const id = category?.id
    if (!getPagesByCategory || !id) {
      return null
    }

    const pages = (getPagesByCategory(id) || [])
      .map((page) => ({
        path: page.path,
        heading: page.heading,
      }))
      .filter(
        (e): e is {path: string; heading: string} =>
          e.path != null && e.heading != null,
      )
    if (category?.slug) {
      const newLinks = getLinksBySlug(category?.slug).map((page) => ({
        path: page.path,
        heading: page.heading,
      }))
      if (!pages.length) {
        return newLinks
      }
    }
    if (pages) {
      return pages
    }
    return null
  }, [category?.id, category?.slug, getLinksBySlug, getPagesByCategory])

  if (!links) {
    return null
  }
  return (
    <Wrapper>
      <NavBarLink
        onClick={toggleDropdown}
        style={{
          fontWeight: isOpen ? 700 : 400,
        }}
        text={category.name ?? ''}
      >
        {category.name}
      </NavBarLink>
      <NavPageDropdown links={links} show={isOpen} />
    </Wrapper>
  )
}
