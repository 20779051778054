import React, {useCallback} from 'react'
import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'
import type {FieldConfig} from 'formik'
import {useField} from 'formik'
import shadowStyles from '../../styles/shadowStyles'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  color?: 'primary' | 'secondary'
  withGutter?: boolean
  onToggle?: (checked: boolean) => void
}

interface LabelProps {
  disabled?: boolean
  withGutter?: boolean
}

interface SwitchProps {
  checked: boolean
  color: 'primary' | 'secondary'
}

const Text = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: ${palette('secondary')};
  line-height: 1;
`

const Switch = styled.div<SwitchProps>`
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: ${ifProp(
    {checked: true},
    (p) => palette(p.color),
    palette('white'),
  )};
  border: 1px solid ${(p) => palette(p.color)};
  transition: background-color 0.15s;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: ${ifProp({checked: true}, '22px', '2px')};
    height: 14px;
    width: 14px;
    border-radius: 8px;
    background-color: ${ifProp({checked: true}, palette('white'), (p) =>
      palette(p.color),
    )};
    transition: left 0.15s ease-out;
  }
`

const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: ${ifProp({disabled: true}, 'not-allowed', 'pointer')};
  margin-bottom: ${ifProp('withGutter', '24px', '0')};
`

const CheckboxInput = styled.input`
  visibility: hidden;
  position: absolute;
  border-radius: 0;

  &:disabled {
    ~ ${Switch} {
      border-color: ${palette('black40')};

      &:after {
        background-color: ${palette('black40')};
      }

      &:hover {
        transform: none;

        &:before {
          transform: translateZ(-1em);
        }
      }
    }

    &:checked {
      ~ ${Switch} {
        background-color: ${palette('black40')};

        &:after {
          background-color: ${palette('black20')};
        }
      }
    }
  }
`
const InputContainer = styled.div<SwitchProps>`
  width: fit-content;
  height: fit-content;
  border: 1px solid transparent;
  border-radius: 10px;
  margin-right: 12px;
  ${(p) =>
    shadowStyles({
      color: p.color,
      outlined: p.checked,
      length: 4,
      showOnHover: true,
      borderRadius: 10,
    })}
`

export default function Toggle({
  label,
  checked = false,
  onToggle,
  disabled,
  color = 'secondary',
  withGutter = false,
  ...props
}: Props): JSX.Element {
  const handleChange = useCallback(() => {
    if (!onToggle) {
      return
    }
    onToggle(!checked)
  }, [checked, onToggle])
  return (
    <Label disabled={disabled} withGutter={withGutter}>
      <Text>{label}</Text>
      <InputContainer color={color} checked={checked}>
        <CheckboxInput
          name={label}
          type="checkbox"
          disabled={disabled}
          onChange={handleChange}
          checked={checked}
          {...props}
        />
        <Switch checked={checked} color={color} />
      </InputContainer>
    </Label>
  )
}

export function FormikToggle<T>(
  props: Props & React.InputHTMLAttributes<HTMLInputElement> & FieldConfig<T>,
): JSX.Element {
  const [field] = useField(props)
  return <Toggle {...field} {...props} />
}
