import React from 'react'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'

import {Title6} from '../typography'
import {theme} from '../../themes'

interface Props {
  isAvailable: boolean
}

const Wrapper = styled.div`
  display: block;
`

const TitleWrapper = styled.div`
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${palette('black')};
`

const StockItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
`

const StockStatus = styled.div`
  color: ${palette('black')};
  padding-left: 6px;
`

export default function ProductStock({isAvailable}: Props): JSX.Element {
  return (
    <Wrapper>
      <TitleWrapper>
        <Title6>Lagerstaða</Title6>
      </TitleWrapper>

      <StockItem>
        {isAvailable ? (
          <>
            <CheckLineIcon color={theme.palette.success} />
            <StockStatus>Til á lager</StockStatus>
          </>
        ) : (
          <>
            <CloseLineIcon color={theme.palette.error} />
            <StockStatus>Uppselt</StockStatus>
          </>
        )}
      </StockItem>
    </Wrapper>
  )
}
