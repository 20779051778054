import {ApolloError, useQuery} from '@apollo/client'
import { Maybe } from 'src/utils/Maybe'
import {PageQueryFn} from 'src/utils/serverRender/serverPropsRender'
import {ProductBySlugDocument, ProductBySlugQuery} from '.'
import {
  ProductDocument,
  ProductQuery,
  ProductQueryVariables,
} from '../saleor/generated'
import {useProductBySlugQuery} from './generated/hooks'

// TODO: For server
interface UseLocalProductValue {
  loading: boolean
  error: ApolloError | undefined
  data: ProductBySlugQuery | undefined
}

export const getLocalProductServerSide: PageQueryFn<any> = async ({
  throwNotFound,
  query,
  context,
  getProp,
}) => {
  const slug = getProp(context, {key: 'slug'})
  const data = await query({
    query: ProductDocument,
    variables: {
      slug,
    },
  })
  const local = await query({
    query: ProductBySlugDocument,
    variables: {
      slug,
    },
  })
  if (!data) {
    return throwNotFound()
  }
  return data
}

export const useLocalProduct = ({
  slug: _slug,
  injected
}: {
  slug: string | string[] | undefined
  injected?: Maybe<ProductBySlugQuery>
}): UseLocalProductValue => {
  if (!_slug || typeof _slug !== 'string') {
    throw new Error('Slug incorrectly defined')
  }
  const slug = Array.isArray(_slug) ? _slug[0] : _slug

  // Since it is anti pattern to fetch from Apollo in TypePolicy
  // we fetch Product here first
  const {
    data: productData,
    error: productError,
    loading: productLoading,
  } = useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      nonAuthorized: true,
    },
    variables: {
      slug,
    },
  })
  const {data, error, loading} = useProductBySlugQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    skip: !productData?.product,
    variables: {
      slug,
    },
    returnPartialData: true,
  })
  
  return {
    loading: loading || productLoading,
    error: error || productError,
    data: injected ?? data,
  }
}
