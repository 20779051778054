import React from 'react'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {palette} from 'styled-tools'
import _ShoppingBasketLineIcon from 'remixicon-react/ShoppingBasket2LineIcon'

import {useCheckout} from 'src/context/CheckoutContext'
import {theme} from '../../themes'

const ShoppingBasketLineIcon = styled(_ShoppingBasketLineIcon)`
  margin-top: 3px;
`

const MiniCartWrapper = styled.div`
  display: flex;
  align-items: center;
`

const MiniCartIcon = styled.div`
  position: relative;
`

const NavBarIconText = styled.span`
  display: none;
  margin: 0 15px 0 10px;
  font-size: 0.875rem;

  ${media.md`
    display: flex;
  `}

  @media screen and (max-width:1450px) {
    display: none;
  }
`

const CartStatusIcon = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
`

const CartQuantity = styled.div`
  position: 'relative';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  z-index: 1;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${palette('black')};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    opacity: 0.9;
    z-index: -1;
    border-radius: 50%;
    background-color: ${palette('background')};
  }
`

export default function HeaderCart(): JSX.Element {
  const {checkout} = useCheckout()
  const cartQty = checkout?.quantity

  return (
    <MiniCartWrapper>
      <MiniCartIcon>
        <ShoppingBasketLineIcon color={theme.palette.red} size={28} />
        {!!cartQty && (
          <CartStatusIcon>
            <CartQuantity>{cartQty}</CartQuantity>
          </CartStatusIcon>
        )}
      </MiniCartIcon>
      <NavBarIconText>Karfan mín</NavBarIconText>
    </MiniCartWrapper>
  )
}
