import styled from 'styled-components'
import {prop} from 'styled-tools'
import {spacing} from '../../utils/styles'

interface FluidSpacingProps {
  from: number
  to: number
}

interface SpacingProps {
  spacing: number
}

export const FluidSpacing = styled.div<FluidSpacingProps>`
  ${(props) => spacing(props.from, props.to)}
`

export const SpacingWrapper = styled.div<SpacingProps>`
  padding: ${prop('spacing')}px 0;
`

export const SpacingBottom = styled.div<SpacingProps>`
  padding-bottom: ${prop('spacing')}px;
`
